import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import RegisterDemoComponent from "../../components/RegisterDemoComponent"

const RegisterDemo = (prop) => {

    return (
       
        <HeaderandFooter >
          <RegisterDemoComponent />
        </HeaderandFooter>
      
    );
};

export default RegisterDemo;