import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';



import Spinner from "../Spinner";
import * as XLSX from 'xlsx';

class HomeCertOnceComponent extends Component {

    
    constructor(props) {
        super(props);
        this.state={
            con: true, 
                  
        }        
        
        
    }

    
   
  componentDidMount(){
       
   }
  componentDidUpdate(prevProps, prevState) {
        
   }

    render() {
        
        return (                
                    <div className="container-fluid" style={{"marginBottom":"13.7%","paddingLeft":"135px"}}>
                        <div className="row text-center ">
                        <div className="container">
                            
                            <div className="row Buttons">
                            <div className="firstButton">                                
                                <Link to="/CertOnceDemo" className="primary-btn">Try CertOnce Today</Link><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <div className="secondButton">
                                <Link to="/RegisterDemo" className="primary-btn">&nbsp;&nbsp;Request a Demo&nbsp;&nbsp;</Link>					
                            </div>
                            </div>
                        </div>
                        
                        </div>
                    </div>  
                    
               )                
        

        }
        


}
function mapStateToProps(state, actions) {
    {
        return {};

    }
}
export default connect(mapStateToProps)(withRouter(HomeCertOnceComponent));

// export default MyProfileView;