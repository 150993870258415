import React from 'react'
import "./button.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendcertificateGS,getpublickey } from "../../utils/constants";
import { SendInvitationGS,GetPublicKey} from '../../redux/GetStarted/actions';
import Spinner from "../Spinner";
import {Alert,Button, Modal} from 'react-bootstrap';
var parse = require('html-react-parser');
export class StepFive extends React.Component {
  signedcertificateurl='';
  onetimecode='';
  sendbuttonflag=false;
  constructor () {
    super()
    this.state = {  
      con: false,    
      alertData: {
          status: false,
          className: '',
          message: ''
      },                  
    }
    this.nextFn = this.nextFn.bind(this);    
    this.prevFn = this.prevFn.bind(this);     
  }
  
  nextFn =e=> {
    e.preventDefault();
    if(this.sendbuttonflag==true)
    {
      this.props.nextStep();
    }
    else
    {
      alert("You should send mail before press next button.");
      
    }
    /*
    this.setState({con:true});  
    let payload = {
            onetimecode : this.onetimecode,                        
            emailaddress: this.props.values.emailAddress
        };
     this.props.dispatch(GetPublicKey(getpublickey, payload));
     */
    
  }
  prevFn =e=> {
    e.preventDefault();    
    this.props.prevStep();
  }
  sendInvitation=e=>{
    this.sendbuttonflag=true;
    this.setState({con:true});
    //console.log(this.introduction_email_body)
    let payload = {
            onetimecode : this.onetimecode,            
            emailbody : this.cert_email_body,            
            emailaddress: this.props.values.emailAddress
        };
     this.props.dispatch(SendInvitationGS(sendcertificateGS, payload));
  }
componentDidMount(){
  
}
componentDidUpdate(prevProps, prevState) {
  
  if ( prevProps.Students ) {
    if(prevProps.Students.Status==200)
    {      
       this.setState({con:false});     
       this.setState({alertData:{status:'Success',className: 'success', message: prevProps.Students.message}});
       window.scrollTo(0,0);   
    }
    else
    {
      this.setState({con:false});
      this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.Students.message}});
      window.scrollTo(0,0);
    }
  }
  
}
closeAlert() {
      var alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData});
    }
  render () {
    const {values}=this.props;
      const email=values.emailAddress;
      const first_name = values.firstName+values.lastName;
      this.signedcertificateurl = values.signedcertificateurl;
      this.onetimecode = values.onetimecode;
      var alertData = this.state.alertData;
      if(this.signedcertificateurl=="") 
      {        
        console.log("Didn't received signedcertificateurl");
      }
      else
      {             
      const cert_url = this.signedcertificateurl.split("/").join("%2F");
      const cert_deepurl = "https://wallet.blockcerts.org/#/import-certificate/"+cert_url;
      const cert_deepurlqr = encodeURIComponent("https://wallet.blockcerts.org/#/import-certificate/"+cert_url)
      const qrcode = "<img src='https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl="+cert_deepurlqr+"' title='Introduction url' />"
      const cert_email_subject = "We have issued you a Blockcert";
      //this.cert_email_body = "<p>Dear "+first_name+"</p><p>In order to issue you a blockchain certificate you need to register your wallet with our issuer. Here's how to do that:</p><p><b>Step 1: Install the app</b></p><p>Install the 'Blockcerts Wallet' app on your phone (available on the iOS or Android app stores).</p><br><p><b>Step 2: Add issuer</b></p><p>There are two ways to add us as an issuer:</p><ul><li>If you're reading this email on your mobile device, <a clicktracking='off' href='"+cert_deepurl+"'>Click this link to add us as an issuer</a>.</li><li>If you're reading this email on your desktop, scan the QR code below using your mobile device.</li></ul>"+qrcode+"<p>Please note: it's important to complete these steps in this order. After you have added us as an issuer, you will be notified when your credential is ready.</p>";
      this.cert_email_body = "<p>Dear "+first_name+"</p><p>We would like to recognize your achievement with a Blockcerts digital credential. You can import your Blockcert in two ways:</p><ul><li>If you're reading this email on your mobile device, <a clicktracking='off' href='"+cert_deepurl+"'>Click this link to add your credential to your Blockcerts wallet</a>.</li><li>If you're reading this email on your desktop, scan the QR code below using your mobile device.</li></ul>"+qrcode
      }
    return (
      <div>
      {this.state.con && (<Spinner></Spinner>)}
      { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
      <div className='row' style={{"justifyContent": "center"}}>
          <div className='six columns'>
            {parse(this.cert_email_body)}
          </div>
        </div>
        
        <br/>
        <div  style={{"justifyContent": "center","display": "flex"}}>
          <div style={{"width":"30%"}}>
            <button className="back"  onClick={this.prevFn}>Previous</button>            
          </div>
          <div style={{"width":"40%"}}>
            <button className="site"  onClick={this.sendInvitation}>Send</button>            
          </div>
          <div style={{"width":"30%"}}>           
            <button className="forward" onClick={this.nextFn}>Next</button>
          </div>
        </div>
        				
      </div>
    )
  }
}
function mapStateToProps(state, actions) {
    if (state.fetchInviteStudents && state.fetchInviteStudents.Data && state.fetchInviteStudents.Data.Status) {
        return { Students: state.fetchInviteStudents.Data}
    }     
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(StepFive));
//export default StepOne