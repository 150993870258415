import React,{ Component } from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import DashboardComponent from "../../components/DashboardComponent";

/*
export class  Dashboard extends Component {
    constructor(props)
    {
        super(props)
    }

    render(){
           return (
        <HeaderandFooter >
           <DashboardComponent />
        </HeaderandFooter>
    ); 
    }

};
*/
const Dashboard = (prop) => {

    return (
       
        <HeaderandFooter >
 <DashboardComponent />
    </HeaderandFooter>
      
    );
};

export default Dashboard;
