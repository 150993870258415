import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserloginFetchData } from '../../redux/Login/actions';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Link, withRouter } from 'react-router-dom';
import { USER_LOGIN } from "../../utils/constants";

import "./login.css";
import Spinner from "../../components/Spinner";
import axios from 'axios';


class Login extends Component {
    ipAddress = "";
    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        var payload = {
            "email": event.target.username.value,
            "password": event.target.pswd.value,
            "ipaddress": this.ipAddress
        }

        this.setState({ loader: true })
        let Url = USER_LOGIN;
        this.props.dispatch(UserloginFetchData(Url, payload));
    }




    render() {
        const { Status, message, Data} = this.props
        console.log(Data);
        axios.get('https://jsonip.com/')
        .then(response => {
            console.log(response.data.ip);
            this.ipAddress = response.data.ip;
        })
        .catch(error => console.log(error));
        if (Status == 200) {
            this.state.loader = false;            
            
            localStorage.setItem('Token', Data.token);
            localStorage.setItem('user_id', Data.user_id);
            localStorage.setItem('organizationverifyuri', Data.organization_verify_uri);
            localStorage.setItem('organizationname', Data.organization_name);
            localStorage.setItem('fname', Data.fname);
            localStorage.setItem('lname', Data.lname);
            localStorage.setItem('email', Data.email);
            
            localStorage.setItem('issued', Data.issued);
            localStorage.setItem('inprogress', Data.inprogress);
            /////////////// Tyleek Dashboard issued ///////////////
            localStorage.setItem('tyleek_dashboard_issued', Data.tyleek_dashboard_issued);
            localStorage.setItem('tyleek_dashboard_sent', Data.tyleek_dashboard_sent);
            localStorage.setItem('current_year', Data.current_year);
            // console.log(localStorage.getItem('tyleek_dashboard_sent'));
            ////////////////// Tyleek End ///////////////////////
            if(Data.ispasswordchanged==true)
            {
                this.props.history.push('/ChangePassword');
            }
            else
            {
                ToastsStore.success(message);
                this.props.history.push('/Dashboard');
            }
        }
        else if (Status == 400) {
            this.state.loader = false;
            ToastsStore.error(message);
        }

        return (

            // <HeaderandFooter> 
                                <div className="wrapper fadeInDown">
                                  {this.state.loader && (  <Spinner />)}
                    <div id="formContent">

                        <div className="fadeIn first">
                            <img src="img/certonce_logo.png" className="log" alt="User Icon" />
                        </div>


                        <form onSubmit={this.handleSubmit}>
                            <input type="text" id="login" className="fadeIn second" placeholder="Email" name="username" />
                            <input type="password" id="password" className="fadeIn third" placeholder="Password" name="pswd" />
                            <input type="submit" className="fadeIn fourth" value="Log In" />
                        </form>


                        <div id="formFooter">
                            <div className="d-flex justify-content-center links" >
                                Don't have an account ? &nbsp; <Link to="/register" ><b> Sign up</b></Link>
                            </div>
                            
                            <Link className="underlineHover"  to="/ForgotPassword" ><b> Forgot Password?</b></Link>
                        </div>

                    </div>
                    <ToastsContainer store={ToastsStore} />
                    
                </div>

            // </HeaderandFooter>
            

        )
    }
}

function mapStateToProps(state, actions) {

    if (state.fetchlogin && state.fetchlogin.Data && state.fetchlogin.Data.Status) {       
        return state.fetchlogin.Data
    }
    else {
        return {}

    }
}

export default connect(mapStateToProps)(withRouter(Login));