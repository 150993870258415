import React, { Component } from 'react';


class Plan extends Component {
    selectedData()
    {
       
        this.props.selectplan(this.props.plan);
    }


    render()
    {

        const { plan } = this.props;
        console.log("plan:",plan)
        return (
            <div className="col-lg-4">
            <div className="single-price no-padding">
                <div className="price-top">
                    <h4>{plan.plan_name}</h4>
                </div>
                <ul className="lists">
                {plan.List.map((p, i) => <li key = {i} >  
                       {p.count}  {p.name}</li>)}
                      </ul>
                {/* <ul className="lists">
                    <li>2.5 GB Space</li>
                    <li>Secure Online Transfer</li>
                    <li>Unlimited Styles</li>
                    <li>Customer Service</li>
                </ul> */}
                <div className="price-bottom">
                    <div className="price-wrap d-flex flex-row justify-content-center">
                        <span className="price">$</span><h1>{plan.price} </h1><span className="time">Per <br /> Month</span>
                    </div>
                    <a   onClick={this.selectedData.bind(this)}  className="primary-btn header-btn">Get Started</a>
                </div>
                
            </div>
        </div>
        
            )
    }
}

export default Plan;