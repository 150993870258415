import {UPDATE_CERTIFICATE_TEMPLATE_SUCCESS,UPDATE_CERTIFICATE_TEMPLATE_LOADING,UPDATE_CERTIFICATE_TEMPLATE_FAILURE,UPDATE_CERTIFICATE_TEMPLATE_COMPLETE,
        GET_TEMPLATE_SUCCESS,GET_TEMPLATE_LOADING,GET_TEMPLATE_FAILURE,GET_TEMPLATE_COMPLETE,
        SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE, SEND_GENERATEPREVIEWCERTIFICATE_FAILURE, SEND_GENERATEPREVIEWCERTIFICATE_LOADING, SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS,
        SEND_BAKEPREVIEWOPENBADGES_COMPLETE, SEND_BAKEPREVIEWOPENBADGES_FAILURE, SEND_BAKEPREVIEWOPENBADGES_LOADING, SEND_BAKEPREVIEWOPENBADGES_SUCCESS,
        SEND_GETLOGOIMAGE_COMPLETE, SEND_GETLOGOIMAGE_FAILURE, SEND_GETLOGOIMAGE_LOADING, SEND_GETLOGOIMAGE_SUCCESS,
        SEND_GETSIGNATUREIMAGE_COMPLETE, SEND_GETSIGNATUREIMAGE_FAILURE, SEND_GETSIGNATUREIMAGE_LOADING, SEND_GETSIGNATUREIMAGE_SUCCESS,
        SEND_GETPARTNER_COMPLETE, SEND_GETPARTNER_FAILURE, SEND_GETPARTNER_LOADING, SEND_GETPARTNER_SUCCESS,
    } from './types';

const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};


export const updateCertificateTemplate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case UPDATE_CERTIFICATE_TEMPLATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case UPDATE_CERTIFICATE_TEMPLATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case UPDATE_CERTIFICATE_TEMPLATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case UPDATE_CERTIFICATE_TEMPLATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}

export const fetchCertificateTemplate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case GET_TEMPLATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case GET_TEMPLATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case GET_TEMPLATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case GET_TEMPLATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}


//Generate PreviewCertificate
export const GeneratePreviewCertificate = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_GENERATEPREVIEWCERTIFICATE_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_GENERATEPREVIEWCERTIFICATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}

//Bake PreviewOpenBadges
export const BakePreviewOpenBadges = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_BAKEPREVIEWOPENBADGES_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_BAKEPREVIEWOPENBADGES_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_BAKEPREVIEWOPENBADGES_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_BAKEPREVIEWOPENBADGES_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}

//Get Logo Image
export const GetLogoImage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_GETLOGOIMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_GETLOGOIMAGE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_GETLOGOIMAGE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_GETLOGOIMAGE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}

//Get Signature Image
export const GetSignatureImage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_GETSIGNATUREIMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_GETSIGNATUREIMAGE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_GETSIGNATUREIMAGE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_GETSIGNATUREIMAGE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}

//Get Partner Ddata
export const GetPartner = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_GETPARTNER_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_GETPARTNER_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_GETPARTNER_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_GETPARTNER_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}