import {SEND_DASHBOARD_COMPLETE, SEND_DASHBOARD_FAILURE, SEND_DASHBOARD_LOADING, SEND_DASHBOARD_SUCCESS,
} from './types';

const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};

//Dashboard Data
export const getDashboardData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_DASHBOARD_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_DASHBOARD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_DASHBOARD_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}