import React from 'react';
import {
  HashRouter as Router,
  // Link.
  Route,
  Switch
} from 'react-router-dom';
import history from './utils/history';
import Home from "./pages/Home"
import Dashboard from './pages/Dashboard';
import Plans from "./pages/Plans";
import Payment from "./pages/Payment";
import Plainloader from "./components/Plainloader";
import Login from "./pages/Login";
import Register from "./pages/Register";
import MyProfile from "./pages/MyProfile"
import ForgotPassword from "./pages/ForgotPassword";
import MyCertificates from "./pages/MyCertificates";
import ManageStudents from "./pages/ManageStudents";
import DesignCertificate from "./pages/DesignCertificate";
import ManageCertificate from "./pages/ManageCertificate";
import Verification from './pages/Verification';
import CertOnceDemo from "./pages/CertOnceDemo";
import HomeCertOnceDemo from "./pages/HomeCertOnceDemo";
import RegisterDemo from "./pages/RegisterDemo";
import ChangePassword from "./pages/ChangePassword";

export const RootConfig = () => (
  <Router history={history}>
    <div>
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route  path="/Dashboard" component={Dashboard} />
          <Route  path="/Plans" component={Plans} />
          <Route  path="/payment" component={Payment} />
          <Route path="/paymentstatus" component={Plainloader} />
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/MyProfile" component={MyProfile} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/MyCertificates" component={MyCertificates} />
          <Route path="/ManageStudents" component={ManageStudents} />
          <Route path="/Verification" component={Verification} />
          <Route path="/DesignCertificate" component={DesignCertificate} />
          <Route path="/ManageCertificate" component={ManageCertificate} />
          <Route path="/CertOnceDemo" component={CertOnceDemo} />
          <Route path="/HomeCertOnceDemo" component={HomeCertOnceDemo} />
          <Route path="/RegisterDemo" component={RegisterDemo} />
          <Route path="/ChangePassword" component={ChangePassword} />
        </Switch>
      </main>
    </div>
  </Router>
);
