import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserloginFetchData } from '../../redux/Login/actions';
import {FetchData} from '../../redux/Profile/actions';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Link, withRouter } from 'react-router-dom';
import { USER_LOGIN ,CHANGEPASSWORD} from "../../utils/constants";

import "./login.css";
import Spinner from "../../components/Spinner";



class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        
        if(event.target.newpassword.value=="")
        {
            ToastsStore.error("Please input new password");
            return;
        }
        if(event.target.confirmpassword.value=="")
        {
            ToastsStore.error("Please input confirm password");
            return;
        }
        if(event.target.newpassword.value!=event.target.confirmpassword.value)
        {
            ToastsStore.error("new password and confirm did not matched");
            return;
        }

        var user_id=localStorage.getItem("user_id");
        var payload = {
            newpassword: event.target.newpassword.value,
            confirmpassword: event.target.confirmpassword.value,
            loginstep:"yes",            
        }
        
        this.setState({ loader: true })
        this.setState({ loadercheck: true })
        let url = CHANGEPASSWORD;        
        this.props.dispatch(FetchData(url,payload));

    }




    render() {


        
                
           
         if(this.props.Changepassword && this.state.loadercheck){           
            this.setState({ loadercheck: false })
            if(this.props.Changepassword.Status == 200)
            {
               this.setState({ loader: false })
                ToastsStore.success(this.props.Changepassword.message); 
                this.props.history.push('/Dashboard');
                
            }
            else if(this.props.Changepassword.Status == 400)
            {
                this.setState({ loader: false })
                ToastsStore.error(this.props.Changepassword.message);
            }
         }

        return (

            // <HeaderandFooter> 
                                <div className="wrapper fadeInDown">
                                  {this.state.loader && (  <Spinner />)}
                    <div id="formContent">

                        <div className="fadeIn first">
                            <img src="img/certonce_logo.png" className="log" alt="User Icon" />
                        </div>


                        <form onSubmit={this.handleSubmit}>
                            <input type="password" id="newpassword" className="fadeIn second" placeholder="New Password" name="newpassword" />
                            <input type="password" id="confirmpassword" className="fadeIn third" placeholder="Confirm Password" name="confirmpassword" />
                            <input type="submit" className="fadeIn fourth" value="Change" />
                        </form>                        

                    </div>
                    <ToastsContainer store={ToastsStore} />
                    
                </div>

            // </HeaderandFooter>
            

        )
    }
}

function mapStateToProps(state, actions) {

    if (state.ChangepasswordData && state.ChangepasswordData.Data && state.ChangepasswordData.Data.Status) {
        return { Changepassword: state.ChangepasswordData.Data }
    }
    else {
        return {}

    }
}

export default connect(mapStateToProps)(withRouter(ChangePassword));