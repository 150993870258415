import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import "./style.css";
import Spinner from "../../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import {Alert,Button, Modal} from 'react-bootstrap';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';



class InvitationEmailTemplateComponent extends Component {
    initrightsidedata="";
    constructor(props) {
        super(props); 
        this.template_content_gl=this.props.value;       
        this.state={
            con: false,            
            alertData: {
                status: false,
                className: '',
                message: ''
            },            

            idl1:"ORGANIZATION_NAME wants to issue you a digital credentials",
            idl2:`<p>Hello FIRST_NAME</p>
                          <p>ORGANIZATION_NAME would like to issue you a blockchain secured digital credentials.</p>
                          <p>This is the future of tamper proof and instantly verifiable digital credentialing system.<a href="BASE_URL">Read More</a></p>
                          <p>If this is the first time you are receiving any blockchain verified credentials, please follow the following steps. Go to step 2 if you already have the app.</p>
                          <p>In order to issue you a blockchain certificate you need to register your wallet with our issuer. Here is how to do that:</p>
                          <p><b>Step 1(For first time users): Install the app</b></p>
                          <p>Install the opensource "Blockcerts Wallet" app on your phone (available on the iOS or Android app stores). </p>
                          <a href="https://apps.apple.com/us/app/blockcerts-wallet/id1146921514"
                          target="_blank">
                          <img src="https://wallet.blockcerts.org/app_store_badge.png" class="inline sm-marg-l-2">
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.learningmachine.android.app"
                          target="_blank">
                          <img src="https://wallet.blockcerts.org/play_store_badge.png" class="inline sm-marg-l-2">
                          </a>
                          <p>
                          This is where you will receive and store the digital certificates.</p><br>
                                                      <p><b>Step 2(for all users): Add ORGANIZATION_NAME as certificate issuer</b></p>
                                                      <p  >In order for us to issue you the digital credentials, you need to add ORGANIZATION_NAME as an issuer in your "Blockcerts Wallet"</p>
                                                      <p>There are two ways to add us as an issuer:</p>
                                                      <ul><li>If you are reading this email on your mobile device, <a clicktracking="off" href="INTRODUCTION_URL">Click this link to add us as an issuer</a>.</li>
                                                      <li>If you are reading this email on your desktop, scan the QR code below using your mobile device.</li></ul>QR_CODE
                          <p>Identification URL: ISSUER_URL</p>
                          <p>One-Time Code: ONETIMECODE</p><br>
                          <p>IMPORTANT: Please note: it is important to complete these steps in this order. After you have added us as an issuer, you will be notified when your credential is ready.</p>`
            
             
        }
        
        
    }
 

 
nextFn =e=> {
    e.preventDefault();     
    var payload={"ismailtemplate":"true","mail_type":"invitation","subject":document.getElementById("idl1").value,"mail_content":document.getElementById("idl2").value};
    //console.log(payload)
    this.props.setTemplateContent(JSON.stringify(payload));    
    this.props.nextStep();
  }
prevFn =e=> {
e.preventDefault();    
this.props.prevStep();
}

preview=()=>{    
    document.getElementById("templatediv").innerHTML=this.initrightsidedata;
    

    var array1=(document.getElementById("idl1").value).split("\n");    
    document.getElementById("idr1").innerHTML=array1.join("<br>"); 

    var array2=(document.getElementById("idl2").value).split("\n");    
    document.getElementById("idr2").innerHTML=array2.join("<br>");  

    
}

componentDidMount()
{
    if(this.initrightsidedata=="")
    {
     this.initrightsidedata =document.getElementById("templatediv").innerHTML;  
    }

    if(this.props.value_fromdb!=""&&this.props.value_fromdb!=null&&this.props.value=="")
    {
      // document.getElementById("templatediv").innerHTML=this.props.value_fromdb;
    }
    
    if(this.props.value!="")
    {
       // document.getElementById("templatediv").innerHTML=this.props.value;
    }
}
componentDidUpdate(prevProps, prevState) {
    
}

closeAlert() {
    let alertData = {
        status: false,
        className: '',
        message: ''
    }
    this.setState({alertData:alertData})
}






render() {

    const {placeholder} = this.state;
    var alertData = this.state.alertData;        
    return (
        <section className="project-tab">
        {this.state.con && (<Spinner></Spinner>)}
        { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}         
            <div id="container5">
            <div id="topStrip5"></div>                
            <div id="mainContent5">	
            <div id="progress">
                    <a className="steps" id="step1">
                    <cufon className="cufon cufon-canvas" alt="Select " style={{"width": "62px","height": "19px"}}>
                        <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                        <cufontext>Select design</cufontext>
                    </cufon>                        
                    </a>
                    <a className="steps current" id="step2">
                        <cufon className="cufon cufon-canvas" alt="Personalize" style={{"width": "107px", "height": "19px"}}>
                            <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                            <cufontext>Personalize</cufontext>
                        </cufon>
                    </a>
                    <a className="steps" id="step3">
                        <cufon className="cufon cufon-canvas" alt="Download" style={{"width": "96px", "height": "19px"}}>
                            <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                            <cufontext>Save</cufontext>
                        </cufon>
                    </a>
                    
                        
                    
                    </div>
                    <div className="row_margin row">
                        <div className="column" style={{"width":"30%"}}> 
                            <center>
                            <textarea id="idl1" style={{"border":"1px solid black","height":"10px","fontSize":"13px","padding":"10px","maxHeight":"90px","minHeight":"90px","borderRadius":"7px"}} placeholder="Subject" className="input2" onChange={e=>this.setState({idl1:e.target.value})} defaultValue={this.state.idl1}/>
                            <br/>
                            <textarea id="idl2" style={{"border":"1px solid black","height":"800px","fontSize":"13px","padding":"10px","minHeight":"90px","borderRadius":"7px"}} placeholder="Email Content" className="input2" onChange={e=>this.setState({idl2:e.target.value})} defaultValue={this.state.idl2}/>
                            </center>
                        </div>
                        <div className="column-r" id="templatediv" style={{"width":"68%","padding":"23px"}}>                                                            
                            <span style={{"fontSize": "19px","color": "black","fontFamily": "Arial","margin":"0px","marginTop":"7px"}}>Subject: </span><span id="idr1" ></span>
                            <br/>
                            <hr style={{"border": "1px solid black"}}/>
                            
                            <span id="idr2" ></span>
                        </div>
                    </div> 
                </div>		
                <div id="bottomStrip5"></div>
                <div  className="container-contact100-form-btn">
                            <button  className="contact100-form-btn preview" style={{"width": "111px"}} onClick={this.preview}>
                                <span>
                                    Preview
                                </span>
                            </button>
                        </div>

                        <div style={{"justifyContent": "left"}} className="container-contact100-form-btn">
                            
                            <button className="contact100-form-btn prev" onClick={this.prevFn}>
                                <span>
                                    Prev
                                </span>
                            </button>&nbsp;&nbsp;&nbsp;
                                <button className="contact100-form-btn next" onClick={this.nextFn}>
                                    <span>
                                        Next
                                    </span>
                            </button>
                        </div>
            </div>     
        </section>
    )}
    
}
function mapStateToProps(state, actions) {
    if (state.fetchStudentsData && state.fetchStudentsData.Data && state.fetchStudentsData.Data.Status) {
        return { Students: state.fetchStudentsData.Data}
    } /*else if (state.fetchCohortsData && state.fetchCohortsData.Data && state.fetchCohortsData.Data.Status) {
        return { Cohorts: state.fetchCohortsData.Data }
    }*/ else if(state.fetchSendInvitation && state.fetchSendInvitation.Data && state.fetchSendInvitation.Data.Status) {
        return { InvitationStatus: state.fetchSendInvitation.Data}
    }
    else if(state.fetchUploadStudents && state.fetchUploadStudents.Data && state.fetchUploadStudents.Data.Status) {
        return { UploadStudents: state.fetchUploadStudents.Data}
    }
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(InvitationEmailTemplateComponent));

// export default MyProfileView;