import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserRegisterFetchData } from '../../redux/Register/actions';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Link, withRouter } from 'react-router-dom';
import { USER_REGISTER } from "../../utils/constants";
import "../Login/login.css";
import "./load.css";
import Spinner from "../../components/Spinner";
import axios from 'axios';


class Register extends Component {
    ipAddress = "";
    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        var payload = {
            "fname": event.target.Fname.value,
            "lname": event.target.lname.value,
            "Organization_Name": event.target.Organization_Name.value,
            "email": event.target.Email.value,
            "Organization_URL": event.target.Organization_URL.value,
            "password": event.target.pswd.value,
            "Graduates_Members": event.target.Graduates_Members.value,
            "Job_Title": event.target.Job_Title.value,
            "How_did_you_find_us": event.target.How_did_you_find_us.value,
            "ipaddress": this.ipAddress
        }
        //  console.log(payload)



        this.setState({ loader: true })
        let Url = USER_REGISTER;
        this.props.dispatch(UserRegisterFetchData(Url, payload));

    }

    render()
    {
        const { Status, message, Data } = this.props;        
        axios.get('https://jsonip.com/')
        .then(response => {
            console.log(response.data.ip);
            this.ipAddress = response.data.ip;
        })
        .catch(error => console.log(error));
        if (Status == 200) {
            this.state.loader = false;
            ToastsStore.success(message);

            this.props.history.push('/login');

        }
        else if (Status == 400) {
            this.state.loader = false;
            ToastsStore.error(message);
        }


        return (
            // <HeaderandFooter>
                              <div className="wrapper fadeInDown">
                           {this.state.loader && (  <Spinner />)}
                    <div id="formContent2" className="text-center">

                        <div className="fadeIn first">
                            <img src="img/certonce_logo.png" className="log" alt="User Icon" />
                        </div>


                        <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                  
                                    <input type="text" name="Fname" className="form-control own-input" id="email" placeholder="First Name" />
                                </div>
                            </div>
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">                                   
                                    <input type="text" name="lname" className="form-control own-input" id="number" placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                   
                                    <input type="email" name="Email" className="form-control own-input" id="email" placeholder="example@gmail.com" />
                                </div>
                            </div>
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                    {/* <label >New Password</label><span className="drop-block">*</span> */}
                                    <input type="password" name="pswd" className="form-control own-input" id="number" placeholder="Password" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                    {/* <label >Organization Name</label><span className="drop-block">*</span> */}
                                    <input type="text" name="Organization_Name" className="form-control own-input" id="email" placeholder="Organization Name" />
                                </div>
                            </div>
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group">
                                    {/* <label >Organization URL</label><span className="drop-block">*</span> */}
                                    <input type="text" name="Organization_URL" className="form-control own-input" id="number" placeholder="http://" />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group from-group-padding">
                                    <label >Graduates / Members per year</label><span className="drop-block">*</span>
                                    <select id="country" name="Graduates_Members" className="form-control own-input">
                                        <option value="1 - 5,000">1 - 5,000</option>
                                        <option value="5,001 - 10,000">5,001 - 10,000</option>
                                    </select>
                                </div>
                            </div>
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group from-group-padding">
                                    <label >Job Title</label><span className="drop-block">*</span>
                                    <select id="country" name="Job_Title" className="form-control own-input">
                                        <option value="Accounting">Accounting</option>
                                        <option value="Human Resources">Human Resources</option>
                                        <option value="AccInformation Technology (IT) and Digital Mediaounting">Information Technology (IT) and Digital Media</option>
                                        <option value="Real Estate">Real Estate</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className=" col-md-6 col-sm-6 col-xs-6">
                                <div className="form-group from-group-padding">
                                    <label >How did you find us?</label><span className="drop-block">*</span>
                                    <select id="country" name="How_did_you_find_us" className="form-control own-input">
                                        <option value="Google or web search">Google or web search</option>
                                        <option value="Friend refer">Friend refer</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-sm-6">

                            </div> */}
                        </div>
                            {/* <input type="text" id="login" className="fadeIn second" placeholder="Email" name="username" />
                            <input type="text" id="password" className="fadeIn third" placeholder="Password" name="pswd" />
                           */}
                          
                          
                          
                          
                            <input type="submit" className="fadeIn fourth" value="Register" />
                        </form>


                        <div id="formFooter">
                            <div className="d-flex justify-content-center links" >
                            Already have an account? &nbsp; <Link to="/login" ><b> Sign in </b></Link>
                            </div>
                        </div>

                    </div>
                    <ToastsContainer store={ToastsStore} />
                </div>
        // </HeaderandFooter>
        )
    }

}

function mapStateToProps(state, actions) {

    if (state.fetchRegister && state.fetchRegister.Data && state.fetchRegister.Data.Status) {
        return state.fetchRegister.Data
    }
    else {
        return {}

    }
}

export default connect(mapStateToProps)(withRouter(Register));