import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import {
  createStore, applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import rootReducer from './redux';
import { RootConfig } from './routes';
import HttpsRedirect from 'react-https-redirect';


const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <HttpsRedirect>
        <RootConfig />
      </HttpsRedirect>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
