import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import DesignCertificateComponent from "../../components/DesignCertificateComponent"

const DesignCertificate = (prop) => {

    return (
       
        <HeaderandFooter >
         <DesignCertificateComponent />
        </HeaderandFooter>
      
    );
};

export default DesignCertificate;