import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import ManageStudentsComponent from "../../components/ManageStudentsComponent"

const ManageStudents = (prop) => {

    return (
       
        <HeaderandFooter >
         <ManageStudentsComponent />
        </HeaderandFooter>
      
    );
};

export default ManageStudents;