import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetProfileFetchData, GetTransactionFetchData, FetchData } from '../../redux/Profile/actions';
import "./style.css";
import "../MyProfileView/custom.css";
import { getProfile, getTransaction, CHANGEPASSWORD } from "../../utils/constants";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';


class MyCertificatesComponent extends Component {

    constructor(props) {
        super(props);
        this.state={
            con: true,
        }


        this.loaderview = this.loaderview.bind(this)


    }

    componentDidMount()
    {
        setTimeout(
            function() {
                this.setState({con: false});
            }
            .bind(this),
            1000
        );
    }


    loaderview()
    {
        this.setState({con: true});
        setTimeout(
            function() {
                this.setState({con: false});
            }
            .bind(this),
            500
        );
    }


    render() {




        return (
            <div>
                <section id="tabs" className="project-tab">
                    {this.state.con && (<Spinner></Spinner>)}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <nav>
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a onClick={this.loaderview} className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#MyCertificates" role="tab" aria-controls="nav-home" aria-selected="true">MyCertificates</a>
                                        <a  onClick={this.loaderview}  className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#sharedCertificates" role="tab" aria-controls="nav-profile" aria-selected="false">Shared Certificates</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div style={{ marginTop: "17px" }} className="tab-pane fade show active" id="MyCertificates" role="tabpanel" aria-labelledby="nav-home-tab">

                                        <div className="row">
                                            <table className="table table-hover transaction-table table-responsive-md" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Block Certificates</th>
                                                        <th>Reamaining &nbsp;Certificates</th>
                                                        <th>Shared Certificates</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>100</td>
                                                        <td>50</td>
                                                        <td>50</td>
                                                    </tr>
                                              
                                        
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="sharedCertificates" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <table className="table table-hover transaction-table table-responsive-md text-center" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>No of &nbsp;Certificates</th>
                                                        {/* <th> Certificates</th> */}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>client 1</td>
                                                        <td>10</td>
                                                        {/* <td>dddd</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td>client 2</td>
                                                        <td>5</td>
                                                        {/* <td>dddd</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td>client 3</td>
                                                        <td>15</td>
                                                        {/* <td>dddd</td> */}
                                                    </tr>
                                                    <tr>
                                                        <td>client 3</td>
                                                        <td>20</td>
                                                        {/* <td>dddd</td> */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastsContainer store={ToastsStore} />
            </div>
        )
    }
}

function mapStateToProps(state, actions) {


    return {}


}

export default connect(mapStateToProps)(withRouter(MyCertificatesComponent));

// export default MyProfileView;