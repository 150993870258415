import React from 'react'
import "./button.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendinvitationGS } from "../../utils/constants";
import { SendInvitationGS} from '../../redux/GetStarted/actions';
import Spinner from "../Spinner";
import {Alert,Button, Modal} from 'react-bootstrap';

var parse = require('html-react-parser');

export class StepThree extends React.Component {
  publickey='';
  onetimecode='';
  constructor () {
    super()
    this.state = {  
      con: false,  
      alertData: {
          status: false,
          className: '',
          message: ''
      },            
    }
    this.nextFn = this.nextFn.bind(this);    
    this.prevFn = this.prevFn.bind(this);     
  }
  
  nextFn =e=> {
    e.preventDefault();
     if(!this.publickey) 
     {
       alert("You can not perform next step til receive publickey.");
     }
     else
     {
       this.props.nextStep();
     }
    
    
  }
  prevFn =e=> {
    e.preventDefault();    
    this.props.prevStep();
  }
  
componentDidMount(){
  
}
componentDidUpdate(prevProps, prevState) {
  
  if ( prevProps.Students ) {
    if(prevProps.Students.Status==200)
    {      
       this.setState({con:false});     
       this.setState({alertData:{status:'Success',className: 'success', message: prevProps.Students.message}});
       window.scrollTo(0,0);  
    }
    else
    {
      this.setState({con:false});
      this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.Students.message}});
      window.scrollTo(0,0);
    }
  }
}
closeAlert() {
      var alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData});
    }
  render () {
      const {values}=this.props;
      const email=values.emailAddress;
      const first_name = values.firstName+values.lastName;
      this.onetimecode = values.onetimecode;
      this.publickey = values.publickey;
      var alertData = this.state.alertData;      
      if(!this.publickey) 
      {
        return (
            <div>
            {this.state.con && (<Spinner></Spinner>)}
            { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
            <div className='row' style={{"justifyContent": "center"}}>
                <div className='six columns'>
                  <p className="certone-1-p" >Hmmm !! It Seems you haven't followed your email to download the APP..Please complete that step before proceeding.</p>
                </div>
              </div>
              
              <br/>
              <div  style={{"justifyContent": "center","display": "flex"}}>
                <div style={{"width":"50%"}}>
                  <button className="back"  onClick={this.prevFn}>Previous</button>            
                </div>          
                <div style={{"width":"50%"}}>           
                  <button className="forward" onClick={this.nextFn}>Next</button>
                </div>
              </div>
                      
            </div>
          )
      }
      else
      {
        return (
            <div>
            {this.state.con && (<Spinner></Spinner>)}
            { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
            <div className='row' style={{"justifyContent": "center"}}>
                <div className='six columns'>
                  <p className="certone-1-p" >Great !! Thanks {values.firstName} {values.lastName} for accepting the invitation - Your Public Key is {values.publickey}</p>
                </div>
              </div>
              
              <br/>
              <div  style={{"justifyContent": "center","display": "flex"}}>
                <div style={{"width":"50%"}}>
                  <button className="back"  onClick={this.prevFn}>Previous</button>            
                </div>          
                <div style={{"width":"50%"}}>           
                  <button className="forward" onClick={this.nextFn}>Next</button>
                </div>
              </div>
                      
            </div>
          )
      }
      
      
      
    
  }
}
function mapStateToProps(state, actions) {
    if (state.fetchInviteStudents && state.fetchInviteStudents.Data && state.fetchInviteStudents.Data.Status) {
        return { Students: state.fetchInviteStudents.Data}
    } 
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(StepThree));
//export default StepOne