import {SEND_GENERATECERTIFICATE_SUCCESS,SEND_GENERATECERTIFICATE_LOADING,SEND_GENERATECERTIFICATE_FAILURE,SEND_GENERATECERTIFICATE_COMPLETE,
    SEND_BAKEOPENBADGES_SUCCESS,SEND_BAKEOPENBADGES_LOADING,SEND_BAKEOPENBADGES_FAILURE,SEND_BAKEOPENBADGES_COMPLETE,
    SEND_ISSUECERTIFICATE_SUCCESS,SEND_ISSUECERTIFICATE_LOADING,SEND_ISSUECERTIFICATE_FAILURE,SEND_ISSUECERTIFICATE_COMPLETE,
    SEND_PREVIEWCERTIFICATE_SUCCESS,SEND_PREVIEWCERTIFICATE_LOADING,SEND_PREVIEWCERTIFICATE_FAILURE,SEND_PREVIEWCERTIFICATE_COMPLETE,
    SEND_REVOKE_SUCCESS,SEND_REVOKE_LOADING,SEND_REVOKE_FAILURE,SEND_REVOKE_COMPLETE,
    SEND_CERTIFICATE_SUCCESS,SEND_CERTIFICATE_LOADING,SEND_CERTIFICATE_FAILURE,SEND_CERTIFICATE_COMPLETE,
    SEND_CERTIFICATE_FORREVIEW_SUCCESS,SEND_CERTIFICATE_FORREVIEW_LOADING,SEND_CERTIFICATE_FORREVIEW_FAILURE,SEND_CERTIFICATE_FORREVIEW_COMPLETE,
    SEND_ISSUECERTIFICATEDAEMON_SUCCESS,SEND_ISSUECERTIFICATEDAEMON_LOADING,SEND_ISSUECERTIFICATEDAEMON_FAILURE,SEND_ISSUECERTIFICATEDAEMON_COMPLETE,
    SEND_ISSUECONFIRM_SUCCESS,SEND_ISSUECONFIRM_LOADING,SEND_ISSUECONFIRM_FAILURE,SEND_ISSUECONFIRM_COMPLETE,
    SEND_VALIDATE_SUCCESS,SEND_VALIDATE_LOADING,SEND_VALIDATE_FAILURE,SEND_VALIDATE_COMPLETE,
    SEND_BATCH_SUCCESS,SEND_BATCH_LOADING,SEND_BATCH_FAILURE,SEND_BATCH_COMPLETE,
    SEND_RESETCERTIFICATE_SUCCESS,SEND_RESETCERTIFICATE_LOADING,SEND_RESETCERTIFICATE_FAILURE,SEND_RESETCERTIFICATE_COMPLETE,
    SEND_UNHOLD_COMPLETE, SEND_UNHOLD_FAILURE, SEND_UNHOLD_LOADING, SEND_UNHOLD_SUCCESS,
    SEND_PREVIEWSTUDENTPHOTO_SUCCESS,SEND_PREVIEWSTUDENTPHOTO_LOADING,SEND_PREVIEWSTUDENTPHOTO_FAILURE,SEND_PREVIEWSTUDENTPHOTO_COMPLETE,
    SEND_DOWNLOADPREVIEWIMAGE_SUCCESS,SEND_DOWNLOADPREVIEWIMAGE_LOADING,SEND_DOWNLOADPREVIEWIMAGE_FAILURE,SEND_DOWNLOADPREVIEWIMAGE_COMPLETE
    } from './types';



const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};

//Unhold Students
export const UnholdStudents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_UNHOLD_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_UNHOLD_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_UNHOLD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_UNHOLD_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Reset
export const ResetCertificate = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_RESETCERTIFICATE_FAILURE:
            return {
                ...state,
                loading: false,
                isCompleted:false,
                error: action.hasErrored,
            };
        case SEND_RESETCERTIFICATE_LOADING:
            return {
                ...state,
                loading: true,
                isCompleted:false,
                error: null
            };
        case SEND_RESETCERTIFICATE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
            };
        case SEND_RESETCERTIFICATE_COMPLETE:
            return {
                ...state,
                loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
            };
        default:
            return state;

        }
}

//validate
export const BatchProcess = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_BATCH_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
    case SEND_BATCH_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case SEND_BATCH_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case SEND_BATCH_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}

//bake openbadges
export const BakeOpenBadges = (state = INITIAL_STATE, action) => {
        switch (action.type) {
            case SEND_BAKEOPENBADGES_FAILURE:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: action.hasErrored,
        };
    case SEND_BAKEOPENBADGES_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case SEND_BAKEOPENBADGES_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case SEND_BAKEOPENBADGES_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}
//validate
export const ValidateReducer = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_VALIDATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_VALIDATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_VALIDATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_VALIDATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}

//issueconfirm
export const IssueConfirm = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_ISSUECONFIRM_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_ISSUECONFIRM_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_ISSUECONFIRM_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_ISSUECONFIRM_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Issue Certificate Daemon
export const IssueCertificateDaemon = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_ISSUECERTIFICATEDAEMON_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_ISSUECERTIFICATEDAEMON_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_ISSUECERTIFICATEDAEMON_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_ISSUECERTIFICATEDAEMON_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}

//Send Certificate
export const SendCertificate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_CERTIFICATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_CERTIFICATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_CERTIFICATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_CERTIFICATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}

//Send Certificate
export const SendCertificateforReview = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_CERTIFICATE_FORREVIEW_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
    case SEND_CERTIFICATE_FORREVIEW_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case SEND_CERTIFICATE_FORREVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case SEND_CERTIFICATE_FORREVIEW_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}

//Revoke Certificate
export const RevokeCertificate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_REVOKE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_REVOKE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_REVOKE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_REVOKE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Generate Certificate
export const GenerateCertificate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_GENERATECERTIFICATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_GENERATECERTIFICATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_GENERATECERTIFICATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_GENERATECERTIFICATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Previews Certificate
export const PreviewCertificate = (state = INITIAL_STATE, action) => { 
            switch (action.type) {
                case SEND_PREVIEWCERTIFICATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_PREVIEWCERTIFICATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_PREVIEWCERTIFICATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_PREVIEWCERTIFICATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Issue Certificate
export const IssueCertificate = (state = INITIAL_STATE, action) => {
            switch (action.type) {
                case SEND_ISSUECERTIFICATE_FAILURE:
                    return {
                        ...state,
                        loading: false,
                    isCompleted:false,
                    error: action.hasErrored,
            };
        case SEND_ISSUECERTIFICATE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_ISSUECERTIFICATE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_ISSUECERTIFICATE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Preview Student Photo
export const PreviewStudentPhoto = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case SEND_PREVIEWSTUDENTPHOTO_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_PREVIEWSTUDENTPHOTO_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_PREVIEWSTUDENTPHOTO_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_PREVIEWSTUDENTPHOTO_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}

// Download Preview Image
export const DownloadPreviewImage = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case SEND_DOWNLOADPREVIEWIMAGE_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
            };
        case SEND_DOWNLOADPREVIEWIMAGE_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case SEND_DOWNLOADPREVIEWIMAGE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case SEND_DOWNLOADPREVIEWIMAGE_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

    }
}