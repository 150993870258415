import React,{ Component } from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import HomeComponent from "../../components/HomeComponent"

export class  Home extends Component {
    constructor(props)
    {
        super(props)
    }

    render(){
           return (
        <HeaderandFooter >
            <HomeComponent />
        </HeaderandFooter>
    ); 
    }

};

export default Home;
