import { REGISTER_FAILURE,REGISTER_LOADING,REGISTER_SUCCESS,REGISTER_COMPLETE  } from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');


//User Login
export function fetchUserRegisterFailed(msg) {
    return {
        type: REGISTER_FAILURE,
        hasErrored: msg
    };
}
export function fetchUserRegisterLoading(bool) {
    return {
        type: REGISTER_LOADING,
        isLoading: bool
    };
}
export function fetchUserRegisterSuccess(items) {
    return {
        type: REGISTER_SUCCESS,
        Data:items
    };
}
export function fetchUserRegisterComplete(status) {
    return {
        type: REGISTER_COMPLETE,
        isCompleted: status
    };
}

export function UserRegisterFetchData(url, payload) {
        return (dispatch) => {
        dispatch(fetchUserRegisterLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchUserRegisterLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchUserRegisterSuccess(eventData));
                dispatch(fetchUserRegisterComplete(true))
            })
            .catch((err) => {
                dispatch(fetchUserRegisterFailed(err.message))
            }
            );
    };
  
}
