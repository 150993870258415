import {
    FAILURE, LOADING, SUCCESS, COMPLETE,
    SEND_GENERATECERTIFICATE_SUCCESS, SEND_GENERATECERTIFICATE_LOADING, SEND_GENERATECERTIFICATE_FAILURE, SEND_GENERATECERTIFICATE_COMPLETE,
    SEND_BAKEOPENBADGES_SUCCESS, SEND_BAKEOPENBADGES_LOADING, SEND_BAKEOPENBADGES_FAILURE, SEND_BAKEOPENBADGES_COMPLETE,
    SEND_ISSUECERTIFICATE_SUCCESS, SEND_ISSUECERTIFICATE_LOADING, SEND_ISSUECERTIFICATE_FAILURE, SEND_ISSUECERTIFICATE_COMPLETE,
    SEND_PREVIEWCERTIFICATE_SUCCESS, SEND_PREVIEWCERTIFICATE_LOADING, SEND_PREVIEWCERTIFICATE_FAILURE, SEND_PREVIEWCERTIFICATE_COMPLETE,
    SEND_REVOKE_SUCCESS, SEND_REVOKE_LOADING, SEND_REVOKE_FAILURE, SEND_REVOKE_COMPLETE,
    SEND_CERTIFICATE_SUCCESS, SEND_CERTIFICATE_LOADING, SEND_CERTIFICATE_FAILURE, SEND_CERTIFICATE_COMPLETE,
    SEND_CERTIFICATE_FORREVIEW_SUCCESS, SEND_CERTIFICATE_FORREVIEW_LOADING, SEND_CERTIFICATE_FORREVIEW_FAILURE, SEND_CERTIFICATE_FORREVIEW_COMPLETE,
    SEND_ISSUECERTIFICATEDAEMON_SUCCESS, SEND_ISSUECERTIFICATEDAEMON_LOADING, SEND_ISSUECERTIFICATEDAEMON_FAILURE, SEND_ISSUECERTIFICATEDAEMON_COMPLETE,
    SEND_ISSUECONFIRM_SUCCESS, SEND_ISSUECONFIRM_LOADING, SEND_ISSUECONFIRM_FAILURE, SEND_ISSUECONFIRM_COMPLETE,
    SEND_VALIDATE_SUCCESS, SEND_VALIDATE_LOADING, SEND_VALIDATE_FAILURE, SEND_VALIDATE_COMPLETE,
    SEND_BATCH_SUCCESS, SEND_BATCH_LOADING, SEND_BATCH_FAILURE, SEND_BATCH_COMPLETE,
    SEND_RESETCERTIFICATE_SUCCESS, SEND_RESETCERTIFICATE_LOADING, SEND_RESETCERTIFICATE_FAILURE, SEND_RESETCERTIFICATE_COMPLETE,
    SEND_UNHOLD_COMPLETE, SEND_UNHOLD_FAILURE, SEND_UNHOLD_LOADING, SEND_UNHOLD_SUCCESS,
    SEND_PREVIEWSTUDENTPHOTO_SUCCESS, SEND_PREVIEWSTUDENTPHOTO_LOADING, SEND_PREVIEWSTUDENTPHOTO_FAILURE, SEND_PREVIEWSTUDENTPHOTO_COMPLETE,
    SEND_DOWNLOADPREVIEWIMAGE_SUCCESS, SEND_DOWNLOADPREVIEWIMAGE_LOADING, SEND_DOWNLOADPREVIEWIMAGE_FAILURE, SEND_DOWNLOADPREVIEWIMAGE_COMPLETE,
} from './types';



require('es6-promise').polyfill();
require('isomorphic-fetch');
var fetchTimeout = require('fetch-timeout');

//Unhold --- Tyleesak
export function unholdfetchFailed(msg) {
    return {
        type: SEND_UNHOLD_FAILURE,
        hasErrored: msg
    };
}
export function unholdfetchLoading(bool) {
    return {
        type: SEND_UNHOLD_LOADING,
        isLoading: bool
    };
}
export function unholdfetchSuccess(items) {
    return {
        type: SEND_UNHOLD_SUCCESS,
        Data: items
    };
}

export function unholdfetchComplete(status) {
    return {
        type: SEND_UNHOLD_COMPLETE,
        isCompleted: status
    };
}

export function UnholdStudents(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(unholdfetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(unholdfetchSuccess(eventData));
                dispatch(unholdfetchComplete(true));
            })
            .catch((err) => {
                dispatch(unholdfetchFailed(err.message))
            }
            );
    };
}
//bake openbadges
export function bakeOpenBadgesFailed(msg) {
    return {
        type: SEND_BAKEOPENBADGES_FAILURE,
        hasErrored: msg
    };
}
export function bakeOpenBadgesLoading(bool) {
    return {
        type: SEND_BAKEOPENBADGES_LOADING,
        isLoading: bool
    };
}
export function bakeOpenBadgesSuccess(items) {
    return {
        type: SEND_BAKEOPENBADGES_SUCCESS,
        Data: items
    };
}

export function bakeOpenBadgesComplete(status) {
    return {
        type: SEND_BAKEOPENBADGES_COMPLETE,
        isCompleted: status
    };
}


export function BakeOpenBadges(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }, 60 * 60 * 1000)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(bakeOpenBadgesLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(bakeOpenBadgesSuccess(eventData));
                dispatch(bakeOpenBadgesComplete(true));
            })
            .catch((err) => {
                dispatch(bakeOpenBadgesFailed(err.message))
            }
            );
    };
}


//validate
export function validateFailed(msg) {
    return {
        type: SEND_VALIDATE_FAILURE,
        hasErrored: msg
    };
}
export function validateLoading(bool) {

    return {
        type: SEND_VALIDATE_LOADING,
        isLoading: bool
    };
}
export function validateSuccess(items) {
    return {
        type: SEND_VALIDATE_SUCCESS,
        Data: items
    };
}

export function validateComplete(status) {
    return {
        type: SEND_VALIDATE_COMPLETE,
        isCompleted: status
    };
}

export function ValidateAction(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(validateLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(validateSuccess(eventData));
                dispatch(validateComplete(true));
            })
            .catch((err) => {
                dispatch(validateFailed(err.message))
            }
            );
    };
}

//issueconfirm
export function issueconfirmFailed(msg) {
    return {
        type: SEND_ISSUECONFIRM_FAILURE,
        hasErrored: msg
    };
}
export function issueconfirmLoading(bool) {

    return {
        type: SEND_ISSUECONFIRM_LOADING,
        isLoading: bool
    };
}
export function issueconfirmSuccess(items) {
    return {
        type: SEND_ISSUECONFIRM_SUCCESS,
        Data: items
    };
}

export function issueconfirmComplete(status) {
    return {
        type: SEND_ISSUECONFIRM_COMPLETE,
        isCompleted: status
    };
}

export function IssueConfirm(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(issueconfirmLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(issueconfirmSuccess(eventData));
                dispatch(issueconfirmComplete(true));
            })
            .catch((err) => {
                dispatch(issueconfirmFailed(err.message))
            }
            );
    };
}
//Issue certificate daemon
export function issuecertificatedaemonfetchFailed(msg) {
    return {
        type: SEND_ISSUECERTIFICATEDAEMON_FAILURE,
        hasErrored: msg
    };
}
export function issuecertificatedaemonfetchLoading(bool) {

    return {
        type: SEND_ISSUECERTIFICATEDAEMON_LOADING,
        isLoading: bool
    };
}
export function issuecertificatedaemonfetchSuccess(items) {
    return {
        type: SEND_ISSUECERTIFICATEDAEMON_SUCCESS,
        Data: items
    };
}

export function issuecertificatedaemonfetchComplete(status) {
    return {
        type: SEND_ISSUECERTIFICATEDAEMON_COMPLETE,
        isCompleted: status
    };
}

export function IssueCertificateDaemon(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(issuecertificatedaemonfetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(issuecertificatedaemonfetchSuccess(eventData));
                dispatch(issuecertificatedaemonfetchComplete(true));
            })
            .catch((err) => {
                dispatch(issuecertificatedaemonfetchFailed(err.message))
            }
            );
    };
}

//Send certificate
export function sendcertificatefetchFailed(msg) {
    return {
        type: SEND_CERTIFICATE_FAILURE,
        hasErrored: msg
    };
}
export function sendcertificatefetchLoading(bool) {
    return {
        type: SEND_CERTIFICATE_LOADING,
        isLoading: bool
    };
}
export function sendcertificatefetchSuccess(items) {
    return {
        type: SEND_CERTIFICATE_SUCCESS,
        Data: items
    };
}

export function sendcertificatefetchComplete(status) {
    return {
        type: SEND_CERTIFICATE_COMPLETE,
        isCompleted: status
    };
}

export function SendCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(sendcertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(sendcertificatefetchSuccess(eventData));
                dispatch(sendcertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(sendcertificatefetchFailed(err.message))
            }
            );
    };
}


//Send certificate
export function sendcertificateforreviewfetchFailed(msg) {
    return {
        type: SEND_CERTIFICATE_FORREVIEW_FAILURE,
        hasErrored: msg
    };
}
export function sendcertificateforreviewfetchLoading(bool) {
    return {
        type: SEND_CERTIFICATE_FORREVIEW_LOADING,
        isLoading: bool
    };
}
export function sendcertificateforreviewfetchSuccess(items) {
    return {
        type: SEND_CERTIFICATE_FORREVIEW_SUCCESS,
        Data: items
    };
}

export function sendcertificateforreviewfetchComplete(status) {
    return {
        type: SEND_CERTIFICATE_FORREVIEW_COMPLETE,
        isCompleted: status
    };
}

export function SendCertificateforReview(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(sendcertificateforreviewfetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(sendcertificateforreviewfetchSuccess(eventData));
                dispatch(sendcertificateforreviewfetchComplete(true));
            })
            .catch((err) => {
                dispatch(sendcertificateforreviewfetchFailed(err.message))
            }
            );
    };
}


//Revoke certificate
export function revokecertificatefetchFailed(msg) {
    return {
        type: SEND_REVOKE_FAILURE,
        hasErrored: msg
    };
}
export function revokecertificatefetchLoading(bool) {
    return {
        type: SEND_REVOKE_LOADING,
        isLoading: bool
    };
}
export function revokecertificatefetchSuccess(items) {
    return {
        type: SEND_REVOKE_SUCCESS,
        Data: items
    };
}

export function revokecertificatefetchComplete(status) {
    return {
        type: SEND_REVOKE_COMPLETE,
        isCompleted: status
    };
}

export function RevokeCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(revokecertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(revokecertificatefetchSuccess(eventData));
                dispatch(revokecertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(revokecertificatefetchFailed(err.message))
            }
            );
    };
}

//Generate certificate
export function generatecertificatefetchFailed(msg) {
    return {
        type: SEND_GENERATECERTIFICATE_FAILURE,
        hasErrored: msg
    };
}
export function generatecertificatefetchLoading(bool) {
    return {
        type: SEND_GENERATECERTIFICATE_LOADING,
        isLoading: bool
    };
}
export function generatecertificatefetchSuccess(items) {
    return {
        type: SEND_GENERATECERTIFICATE_SUCCESS,
        Data: items
    };
}

export function generatecertificatefetchComplete(status) {
    return {
        type: SEND_GENERATECERTIFICATE_COMPLETE,
        isCompleted: status
    };
}


export function GenerateCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }, 60 * 60 * 1000)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(generatecertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(generatecertificatefetchSuccess(eventData));
                dispatch(generatecertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(generatecertificatefetchFailed(err.message))
            }
        );
    };
}

//////preview certificate///////
export function previewcertificatefetchFailed(msg) {
    return {
        type: SEND_PREVIEWCERTIFICATE_FAILURE,
        hasErrored: msg
    };
}
export function previewcertificatefetchLoading(bool) {
    return {
        type: SEND_PREVIEWCERTIFICATE_LOADING,
        isLoading: bool
    };
}
export function previewcertificatefetchSuccess(items) {
    return {
        type: SEND_PREVIEWCERTIFICATE_SUCCESS,
        Data: items
    };
}

export function previewcertificatefetchComplete(status) {
    return {
        type: SEND_PREVIEWCERTIFICATE_COMPLETE,
        isCompleted: status
    };
}

//Preview certificate
export function PreviewCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(previewcertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(previewcertificatefetchSuccess(eventData));
                dispatch(previewcertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(previewcertificatefetchFailed(err.message))
            }
            );
    };
}
////////////////////////////////
export function issuecertificatefetchFailed(msg) {
    return {
        type: SEND_ISSUECERTIFICATE_FAILURE,
        hasErrored: msg
    };
}
export function issuecertificatefetchLoading(bool) {
    return {
        type: SEND_ISSUECERTIFICATE_LOADING,
        isLoading: bool
    };
}
export function issuecertificatefetchSuccess(items) {
    return {
        type: SEND_ISSUECERTIFICATE_SUCCESS,
        Data: items
    };
}

export function issuecertificatefetchComplete(status) {
    return {
        type: SEND_ISSUECERTIFICATE_COMPLETE,
        isCompleted: status
    };
}

//Issue certificate
export function IssueCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(issuecertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(issuecertificatefetchSuccess(eventData));
                dispatch(issuecertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(issuecertificatefetchFailed(err.message))
            }
            );
    };
}



export function fetchFailed(msg) {
    return {
        type: FAILURE,
        hasErrored: msg
    };
}
export function fetchLoading(bool) {
    return {
        type: LOADING,
        isLoading: bool
    };
}
export function fetchSuccess(items) {
    return {
        type: SUCCESS,
        Data: items
    };
}

export function fetchComplete(status) {
    return {
        type: COMPLETE,
        isCompleted: status
    };
}

export function FetchData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        dispatch(fetchLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchSuccess(eventData));
                dispatch(fetchComplete(true));
            })
            .catch((err) => {
                dispatch(fetchFailed(err.message))
            }
            );
    };

}


//batch certificate
export function batchcertificatefetchFailed(msg) {
    return {
        type: SEND_BATCH_FAILURE,
        hasErrored: msg
    };
}
export function batchcertificatefetchLoading(bool) {
    return {
        type: SEND_BATCH_LOADING,
        isLoading: bool
    };
}
export function batchcertificatefetchSuccess(items) {
    return {
        type: SEND_BATCH_SUCCESS,
        Data: items
    };
}

export function batchcertificatefetchComplete(status) {
    return {
        type: SEND_BATCH_COMPLETE,
        isCompleted: status
    };
}


export function BatchCertificate(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }, 60 * 60 * 1000)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(batchcertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(batchcertificatefetchSuccess(eventData));
                dispatch(batchcertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(batchcertificatefetchFailed(err.message))
            }
            );
    };
}


//RESET certificate
export function resetcertificatefetchFailed(msg) {
    return {
        type: SEND_RESETCERTIFICATE_FAILURE,
        hasErrored: msg
    };
}
export function resetcertificatefetchLoading(bool) {
    return {
        type: SEND_RESETCERTIFICATE_LOADING,
        isLoading: bool
    };
}
export function resetcertificatefetchSuccess(items) {
    return {
        type: SEND_RESETCERTIFICATE_SUCCESS,
        Data: items
    };
}

export function resetcertificatefetchComplete(status) {
    return {
        type: SEND_RESETCERTIFICATE_COMPLETE,
        isCompleted: status
    };
}


export function ResetCertificateAction(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        }, 60 * 60 * 1000)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(resetcertificatefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(resetcertificatefetchSuccess(eventData));
                dispatch(resetcertificatefetchComplete(true));
            })
            .catch((err) => {
                dispatch(resetcertificatefetchFailed(err.message))
            }
            );
    };
}

// Preview Student Photo
export function previewstudentphotofetchFailed(msg) {
    return {
        type: SEND_PREVIEWSTUDENTPHOTO_FAILURE,
        hasErrored: msg
    };
}
export function previewstudentphotofetchLoading(bool) {
    return {
        type: SEND_PREVIEWSTUDENTPHOTO_LOADING,
        isLoading: bool
    };
}
export function previewstudentphotofetchSuccess(items) {
    return {
        type: SEND_PREVIEWSTUDENTPHOTO_SUCCESS,
        Data: items
    };
}

export function previewstudentphotofetchComplete(status) {
    return {
        type: SEND_PREVIEWSTUDENTPHOTO_COMPLETE,
        isCompleted: status
    };
}

//Preview Student Photo
export function PreviewStudentPhoto(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(previewstudentphotofetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(previewstudentphotofetchSuccess(eventData));
                dispatch(previewstudentphotofetchComplete(true));
            })
            .catch((err) => {
                dispatch(previewstudentphotofetchFailed(err.message))
            }
            );
    };
}

// Download Preview Image
export function downloadpreviewimagefetchFailed(msg) {
    return {
        type: SEND_DOWNLOADPREVIEWIMAGE_FAILURE,
        hasErrored: msg
    };
}
export function downloadpreviewimagefetchLoading(bool) {
    return {
        type: SEND_DOWNLOADPREVIEWIMAGE_LOADING,
        isLoading: bool
    };
}
export function downloadpreviewimagefetchSuccess(items) {
    return {
        type: SEND_DOWNLOADPREVIEWIMAGE_SUCCESS,
        Data: items
    };
}

export function downloadpreviewimagefetchComplete(status) {
    return {
        type: SEND_DOWNLOADPREVIEWIMAGE_COMPLETE,
        isCompleted: status
    };
}

//Download Preview Image
export function DownloadPreviewImage(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(downloadpreviewimagefetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(downloadpreviewimagefetchSuccess(eventData));
                dispatch(downloadpreviewimagefetchComplete(true));
            })
            .catch((err) => {
                dispatch(downloadpreviewimagefetchFailed(err.message))
            }
            );
    };
}

