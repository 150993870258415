import { INSERT_STUDENTS_FAILURE,INSERT_STUDENTS_LOADING,INSERT_STUDENTS_SUCCESS,INSERT_STUDENTS_COMPLETE,
         INVITE_STUDENTS_FAILURE,INVITE_STUDENTS_LOADING,INVITE_STUDENTS_SUCCESS,INVITE_STUDENTS_COMPLETE,
         GET_PUBLICKEY_FAILURE,GET_PUBLICKEY_LOADING,GET_PUBLICKEY_SUCCESS,GET_PUBLICKEY_COMPLETE,
         ISSUE_FAILURE,ISSUE_LOADING,ISSUE_SUCCESS,ISSUE_COMPLETE, 
         INSERT_SCHEDULEDEMO_FAILURE,INSERT_SCHEDULEDEMO_LOADING,INSERT_SCHEDULEDEMO_SUCCESS,INSERT_SCHEDULEDEMO_COMPLETE, 
} from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');



//Insert Students
export function insertScheduleDemoFailed(msg) {
    return {
        type: INSERT_SCHEDULEDEMO_FAILURE,
        hasErrored: msg
    };
}
export function insertScheduleDemoLoading(bool) {
    return {
        type: INSERT_SCHEDULEDEMO_LOADING,
        isLoading: bool
    };
}
export function insertScheduleDemoSuccess(items) {
    return {
        type: INSERT_SCHEDULEDEMO_SUCCESS,
        Data:items
    };
}

export function insertScheduleDemoComplete(status) {
    return {
        type: INSERT_SCHEDULEDEMO_COMPLETE,
        isCompleted: status
    };
}

export function InsertScheduleDemoData(url, payload) {    
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {                         
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(insertScheduleDemoLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
        dispatch(insertScheduleDemoSuccess(eventData));
        dispatch(insertScheduleDemoComplete(true));
    })
    .catch((err) => {
        dispatch(insertScheduleDemoFailed(err.message));
    })
    }

}
//Insert Students
export function insertStudentsFailed(msg) {
    return {
        type: INSERT_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function insertStudentsLoading(bool) {
    return {
        type: INSERT_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function insertStudentsSuccess(items) {
    return {
        type: INSERT_STUDENTS_SUCCESS,
        Data:items
    };
}

export function insertStudentsComplete(status) {
    return {
        type: INSERT_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function InsertStudentsData(url, payload) {    
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {                         
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(insertStudentsLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
        dispatch(insertStudentsSuccess(eventData));
        dispatch(insertStudentsComplete(true));
    })
    .catch((err) => {
        dispatch(insertStudentsFailed(err.message));
    })
    }

}


//Invite Students
export function inviteStudentsFailed(msg) {
    return {
        type: INVITE_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function inviteStudentsLoading(bool) {
    return {
        type: INVITE_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function inviteStudentsSuccess(items) {
    return {
        type: INVITE_STUDENTS_SUCCESS,
        Data:items
    };
}

export function inviteStudentsComplete(status) {
    return {
        type: INVITE_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function SendInvitationGS(url, payload) {    
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {                         
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(inviteStudentsLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
        dispatch(inviteStudentsSuccess(eventData));
        dispatch(inviteStudentsComplete(true));
    })
    .catch((err) => {
        dispatch(inviteStudentsFailed(err.message));
    })
    }

}
//Get public key
export function getPublicKeyFailed(msg) {
    return {
        type: GET_PUBLICKEY_FAILURE,
        hasErrored: msg
    };
}
export function getPublicKeyLoading(bool) {
    return {
        type: GET_PUBLICKEY_LOADING,
        isLoading: bool
    };
}
export function getPublicKeySuccess(items) {
    return {
        type: GET_PUBLICKEY_SUCCESS,
        Data:items
    };
}

export function getPublicKeyComplete(status) {
    return {
        type: GET_PUBLICKEY_COMPLETE,
        isCompleted: status
    };
}

export function GetPublicKey(url, payload) {    
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {                         
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(getPublicKeyLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
        dispatch(getPublicKeySuccess(eventData));
        dispatch(getPublicKeyComplete(true));
    })
    .catch((err) => {
        dispatch(getPublicKeyFailed(err.message));
    })
    }

}

//issue
export function issueFailed(msg) {
    return {
        type: ISSUE_FAILURE,
        hasErrored: msg
    };
}
export function issueLoading(bool) {
    return {
        type: ISSUE_LOADING,
        isLoading: bool
    };
}
export function issueSuccess(items) {
    return {
        type: ISSUE_SUCCESS,
        Data:items
    };
}

export function issueComplete(status) {
    return {
        type: ISSUE_COMPLETE,
        isCompleted: status
    };
}

export function IssueAction(url, payload) {    
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {                         
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch(issueLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
        dispatch(issueSuccess(eventData));
        dispatch(issueComplete(true));
    })
    .catch((err) => {
        dispatch(issueFailed(err.message));
    })
    }

}


