//update certificate template
export const UPDATE_CERTIFICATE_TEMPLATE_SUCCESS = 'UPDATE_CERTIFICATE_TEMPLATE_SUCCESS';
export const UPDATE_CERTIFICATE_TEMPLATE_LOADING = 'UPDATE_CERTIFICATE_TEMPLATE_LOADING';
export const UPDATE_CERTIFICATE_TEMPLATE_FAILURE = 'UPDATE_CERTIFICATE_TEMPLATE_FAILURE';
export const UPDATE_CERTIFICATE_TEMPLATE_COMPLETE = 'UPDATE_CERTIFICATE_TEMPLATE_COMPLETE';

export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_LOADING = 'GET_TEMPLATE_LOADING';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';
export const GET_TEMPLATE_COMPLETE = 'GET_TEMPLATE_COMPLETE';

//Generate PreviewCertificate
export const SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS = 'SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS';
export const SEND_GENERATEPREVIEWCERTIFICATE_LOADING = 'SEND_GENERATEPREVIEWCERTIFICATE_LOADING';
export const SEND_GENERATEPREVIEWCERTIFICATE_FAILURE = 'SEND_GENERATEPREVIEWCERTIFICATE_FAILURE';
export const SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE = 'SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE';

//Bake PreviewOpenBadges
export const SEND_BAKEPREVIEWOPENBADGES_SUCCESS = 'SEND_BAKEPREVIEWOPENBADGES_SUCCESS';
export const SEND_BAKEPREVIEWOPENBADGES_LOADING = 'SEND_BAKEPREVIEWOPENBADGES_LOADING';
export const SEND_BAKEPREVIEWOPENBADGES_FAILURE = 'SEND_BAKEPREVIEWOPENBADGES_FAILURE';
export const SEND_BAKEPREVIEWOPENBADGES_COMPLETE = 'SEND_BAKEPREVIEWOPENBADGES_COMPLETE';

//Get Logo Image
export const SEND_GETLOGOIMAGE_SUCCESS = 'SEND_GETLOGOIMAGE_SUCCESS';
export const SEND_GETLOGOIMAGE_LOADING = 'SEND_GETLOGOIMAGE_LOADING';
export const SEND_GETLOGOIMAGE_FAILURE = 'SEND_GETLOGOIMAGE_FAILURE';
export const SEND_GETLOGOIMAGE_COMPLETE = 'SEND_GETLOGOIMAGE_COMPLETE';

//Get Signature Image
export const SEND_GETSIGNATUREIMAGE_SUCCESS = 'SEND_GETSIGNATUREIMAGE_SUCCESS';
export const SEND_GETSIGNATUREIMAGE_LOADING = 'SEND_GETSIGNATUREIMAGE_LOADING';
export const SEND_GETSIGNATUREIMAGE_FAILURE = 'SEND_GETSIGNATUREIMAGE_FAILURE';
export const SEND_GETSIGNATUREIMAGE_COMPLETE = 'SEND_GETSIGNATUREIMAGE_COMPLETE';

//Get Partner Image
export const SEND_GETPARTNER_SUCCESS = 'SEND_GETPARTNER_SUCCESS';
export const SEND_GETPARTNER_LOADING = 'SEND_GETPARTNER_LOADING';
export const SEND_GETPARTNER_FAILURE = 'SEND_GETPARTNER_FAILURE';
export const SEND_GETPARTNER_COMPLETE = 'SEND_GETPARTNER_COMPLETE';