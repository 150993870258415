import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter } from 'react-router-dom';



class HomeComponent extends Component {

    componentDidMount()
       {
        	
       }

    componentWillReceiveProps(nextProps) {


    }

    render() {
          /*       <p className="text-white certone-2-p">Certify - Once and for all</p>
				   <p className="certone-1-p">CertOnce provides a true blockchain based SaaS solution to issue any kind of credentials using EDTech standard based format. These digital certificates are Self-soverign, temperproof and instantly verifiable.</p>
		*/

        return (<div>
            <div className="container-fluid img-back  ">
			  <div className="row text-center trans">
				<div className="container">
					<div className="row text-center justify-content-center text-white">					
					<p className="text-white certone-2-p">CertOnce Digital certificate platform</p>
					<p className="certone-1-p" >Blockchain-anchored records maximize the value of your programs and create benefits for everyone involved. Issue trustless, secured and temper-proof certificates your organization issues to make your brand more visible, trusted and respected.</p>                            
					</div>                            
				</div>                        
			  </div>
			</div> 
			<div className="container-fluid">
			    <div className="row">
				  <img src="img/laptop.jpg" className="img-fluid lappy-img"/>
				</div>
			</div>
			
					  <div className="container trust-chain-block">
					  <div className="row justify-content-center">
					  <p className="trust-chain-block-p">
					  TrustChain between Reciepient, Issuer and Verifier
					  </p>
					  <p className="text-center">
					  Blockchain-anchored records maximize the value of your programs and create benefits for everyone involved. CertOnce can be used for academic credentials, professional certifications, workforce development and much more !
					  </p>
					  </div>
					  </div>
				

 {/* start banner Area */}
		
			 {/* End banner Area */}	

			 {/* Start Product Area */}
			<section className="feature-area pb-100" id="product" ref="product">
				<div className="container">
					
					<div className="row">
						<div className="col-lg-4">
							<div className="single-feature">
								<span className="lnr lnr-screen"></span>
								<h4>
									Self-Soverign
								</h4>
								<p>
									Here, I focus on a range of items and features that we use in life without giving them a second thought such as Coca Cola.
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="single-feature">
								<span className="lnr lnr-code"></span>
								<h4>
									Trustless
								</h4>
								<p>
									Here, I focus on a range of items and features that we use in life without giving them a second thought such as Coca Cola.
								</p>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="single-feature">
								<span className="lnr lnr-clock"></span>
								<h4>
									Instant Verification
								</h4>
								<p>
									Here, I focus on a range of items and features that we use in life without giving them a second thought such as Coca Cola.
								</p>
							</div>
						</div>												
					</div>
				</div>	
			</section>
			 {/* End feature Area */}
			
			 {/* Start about Area */}
			<section className="about-area section-gap">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-10">
							<div className="title text-center">
								<h1 className="mb-10">21st century credentialing infrastructure</h1>
								<p>The web, mobile, and blockchain are being brought together. By 2020, most companies and governments will be using the blockchain in some way for verification of claims. </p>
							</div>
						</div>
					</div>						
					<div className="row align-items-center">
						<div className="col-lg-6 about-left">
							<h6> A New Trustless Infrastructure  </h6>
							<h1>
								that is digital, portable, and verifiable.
							</h1>
							<p>
								<span>
									The Time for Self-Sovereign Identity is Now !!Embrace the open standard for creating, issuing, viewing, and verifying blockchain-based certificates.
								</span>
							</p>
							<p>
								The blockchain acts as the provider of trust, and credentials are tamper-resistant and verifiable.
							</p>
							<a className="primary-btn" href="#">Get Started now</a>
						</div>
						<div className="col-lg-6 about-right">
							<div className="active-about-carusel">
								<div className="single-carusel item">
									<img className="img-fluid" src="img/about-img.png" alt="" />
								</div>
								<div className="single-carusel item">
									<img className="img-fluid" src="img/about-img.png" alt="" />
								</div>
								<div className="single-carusel item">
									<img className="img-fluid" src="img/about-img.png" alt="" />
								</div>																
							</div>
						</div>
					</div>
				</div>	
			</section>
			 {/* End about Area */}
			
			 {/* Start service Area */}
			<section className="service-area section-gap" id="feature">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-10">
							<div className="title text-center">
								<h1 className="mb-10">Features That make us Unique</h1>
								<p>Who are in extremely love with eco friendly system.</p>
							</div>
						</div>
					</div>					
					<div className="row">
						<div className="col-lg-6 secvice-left">
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>01</h1>
								</div>
								<div className="desc">
									<h4>Recipient Owned</h4>
									<p>
										It means that recipients (students, alumni, workers, citizens) own and keep all the records they have received, even if if their government, company, or University switches to different records providers later.
									</p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>03</h1>
								</div>
								<div className="desc">
									<h4>Temper Proof</h4>
									<p>
										Blockchain is an immutable and distributed store of transactions. When a certificate is issued, its data is compressed into a hash and logged on the blockchain.
										This generates a “receipt” that can always be checked at a later date. The verification service validates the signature of the issuer and the certificate data; it also ensures that the certificate status has not expired or been revoked.
									</p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>05</h1>
								</div>
								<div className="desc">
									<h4>Cost Effective</h4>
									<p>

										The only costs associated are for registering records on a blockchain, and each blockchain has their own fee structure that fluctuates.
										To mitigate these costs, Blockcerts can issue records in batches of 2000 at at time, through a merkle tree structure, and this is registered
										as a single transaction.
										On the BTC chain today 4, this fee ranges from $.05 to $0.25 depending on how fast it needs to be mined.
									</p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>07</h1>
								</div>
								<div className="desc">
									<h4>Instant Verification</h4>
									<p>
										Decentralized verification is instant, free, and extremely detailed when using the Blockcerts Open Standard.
										In addition to checking for evidence of tampering, the Blockcerts verification process also checks issuer signatures,
										recipient ownership, date of expiry, and revocation.
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6 secvice-right">
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>02</h1>
								</div>
								<div className="desc">
									<h4>Vendor Independent</h4>
									<p>
										Verification of Blockcerts is vendor- and issuer-independent,  meaning that if vendors are changed or issuers go out of business, your certificates remain verifiable. .
									</p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>04</h1>
								</div>
								<div className="desc">
									<h4>Easily Shareable</h4>
									<p>
										With the blockchain, your official records are now yours forever.
										Receive them once, share and verify them for a lifetime. All with just one button click on your Blockcerts App on your mobile.
									</p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>06</h1>
								</div>
								<div className="desc">
									<h4>Full Interoperable</h4>
									<p>
										The Open standard ensures the longevity and interoperability of digital records.CertOnce is based on blockcerts which is aligned with (and contributing to) the following standards:
										IMS Open Badges, W3C Verifiable Claims, W3C Linked Data Signatures, W3C / Rebooting Web of Trust Decentralized Identifiers.
                                        </p>
								</div>
							</div>
							<div className="single-service d-flex flex-row pb-30">
								<div className="icon">
									<h1>08</h1>
								</div>
								<div className="desc">
									<h4>MetaData Rich</h4>
									<p>
										Blockcerts issuer schema is extensible. Rich metadata can be added to enhance the record and certificates with more contextual data.
									</p>
								</div>
							</div>
						</div>						
					</div>
				</div>	
			</section>
			 {/* End service Area */}
				

			 {/* Start callto-action Area */}
			<section className="callto-action-area relative section-gap">
				<div className="overlay overlay-bg"></div>	
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content col-lg-9">
							<div className="title text-center">
								<h1 className="mb-10 text-white">Sounds worth giving a try?</h1>
								<p className="text-white">Although there is many skeptics around using blockchain products and harnessing its true power. Give it a try to make an educated decision</p>
								<a className="primary-btn" href="requestDemo.html">Request Free Demo</a>
							</div>
						</div>
					</div>	
				</div>	
			</section>
			 {/* End calto-action Area */}
			

			 {/* Start home-video Area */}
			<section className="home-video-area">
				<div className="container-fluid">
					<div className="row justify-content-end align-items-center">
						<div className="col-lg-4 no-padding video-right">
							<p className="top-title">Intro Video</p>
							<h1>Watch tutorial <br /> video of CertOnce to start</h1>
							<p><span>We are here to introduce this excellent product</span></p>
							<p>
								This is very simple 6 steps process from start to end.
							</p>
						</div>
						<section className="video-area col-lg-6">
							<div className="overlay overlay-bg"></div>
							<div className="container">
								<div className="video-content">
									<a href="http://www.youtube.com/watch?v=0O2aH4XLbto" className="play-btn"><img src="img/play-btn.png" alt="" /></a>
								</div>
							</div>
						</section>											
					</div>
				</div>	
			</section>
			 {/* End home-aboutus Area */}


			 {/* Start home-aboutus Area */}
			<section className="home-aboutus-area">
				<div className="container-fluid">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-8 no-padding about-left relative">
							<div className="overlay overlay-bg"></div>	
							<img className="img-fluid" src="img/f2.jpg" alt="" />
						</div>
						<div className="col-lg-4 no-padding about-right">
							<p className="top-title">24/7 Support system</p>
							<h1>A truly <br />
							tranformative blockchain solution</h1>
							<p><span>We are here to listen from you to deliver exellence</span></p>
							<p>
								Choose the mode you prefer - 24*7.
							</p>
						</div>
					</div>
				</div>	
			</section>
			 {/* End home-aboutus Area */}			
		
			 {/* Start price Area */}
			<section className="price-area section-gap" id="price" ref="price">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-8">
							<div className="title text-center">
								<h1 className="mb-10">Choose the best pricing for you</h1>
								<p>Who are in extremely love with customer friendly system.</p>
							</div>
						</div>
					</div>						
					<div className="row">
						<div className="col-lg-4">
							<div className="single-price no-padding single-price-mr">
								<div className="price-top">
									<h4>Real basic</h4>
								</div>
								<ul className="lists">
									<li>2.5 GB Space</li>
									<li>Secure Online Transfer</li>
									<li>Unlimited Styles</li>
									<li>Customer Service</li>
								</ul>
								<div className="price-bottom">
									<div className="price-wrap d-flex flex-row justify-content-center">
										<span className="price">$</span><h1> 39 </h1><span className="time">Per <br /> Month</span>
									</div>
									<a href="#" className="primary-btn header-btn">Get Started</a>
								</div>
								
							</div>
						</div>
						<div className="col-lg-4">
							<div className="single-price no-padding single-price-mr">
								<div className="price-top">
									<h4>Real Standred</h4>
								</div>
								<ul className="lists">
									<li>2.5 GB Space</li>
									<li>Secure Online Transfer</li>
									<li>Unlimited Styles</li>
									<li>Customer Service</li>
								</ul>
								<div className="price-bottom">
									<div className="price-wrap d-flex flex-row justify-content-center">
										<span className="price">$</span><h1> 69 </h1><span className="time">Per <br /> Month</span>
									</div>
									<a href="#" className="primary-btn header-btn">Get Started</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="single-price no-padding single-price-mr">
								<div className="price-top">
									<h4>Real Ultimate</h4>
								</div>
								<ul className="lists">
									<li>2.5 GB Space</li>
									<li>Secure Online Transfer</li>
									<li>Unlimited Styles</li>
									<li>Customer Service</li>
								</ul>
								<div className="price-bottom">
									<div className="price-wrap d-flex flex-row justify-content-center">
										<span className="price">$</span><h1> 99 </h1><span className="time">Per <br /> Month</span>
									</div>
									<a href="#" className="primary-btn header-btn">Get Started</a>
								</div>
							</div>				
						</div>
						<div className="col-lg-4">
							<div className="single-price no-padding single-price-mr">
								<div className="price-top">
									<h4>Real Ultimate</h4>
								</div>
								<ul className="lists">
									<li>2.5 GB Space</li>
									<li>Secure Online Transfer</li>
									<li>Unlimited Styles</li>
									<li>Customer Service</li>
								</ul>
								<div className="price-bottom">
									<div className="price-wrap d-flex flex-row justify-content-center">
										<span className="price">$</span><h1> 99 </h1><span className="time">Per <br /> Month</span>
									</div>
									<a href="#" className="primary-btn header-btn">Get Started</a>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</section>
			 {/* End price Area */}

			 {/* Start testimonial Area */}
			<section className="testimonial-area relative section-gap" id="testimonial" ref="testimonial"> 
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-8">
							<div className="title text-center">
								<h1 className="mb-10">Testimonial from our Clients</h1>
								<p>Who are in extremely love with customer focused system.</p>
							</div>
						</div>
					</div>						
					<div className="row">
						<div className="active-testimonial">
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t1.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star"></span>
										<span className="fa fa-star"></span>
									</div>
								</div>
							</div>
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t2.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
									</div>
								</div>
							</div>	
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t1.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star"></span>
										<span className="fa fa-star"></span>
									</div>
								</div>
							</div>
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t2.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
									</div>
								</div>
							</div>									
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t1.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star"></span>
										<span className="fa fa-star"></span>
									</div>
								</div>
							</div>
							<div className="single-testimonial item d-flex flex-row">
								<div className="thumb">
									<img className="img-fluid" src="img/t2.png" alt="" />
								</div>
								<div className="desc">
									<p>
										Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker, projector, hardware.
									</p>
									<h4 mt-30="true">Mark Alviro Wiens</h4>
									<div className="star">
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
										<span className="fa fa-star checked"></span>
									</div>
								</div>
							</div>								
						</div>					
					</div>
				</div>	
			</section>
			 {/* End testimonial Area */}

			
			 {/* Start latest-blog Area */}
			<section className="latest-blog-area section-gap" id="blog">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-8">
							<div className="title text-center">
								<h1 className="mb-10">Latest News from our Blog</h1>
								<p>Who are in extremely love with eco friendly system.</p>
							</div>
						</div>
					</div>					
					<div className="row">
						<div className="col-lg-6 single-blog">
							<img className="img-fluid" src="img/b1.png" alt="" />
							<ul className="tags">
								<li><a href="#">Travel</a></li>
								<li><a href="#">Life style</a></li>
							</ul>
							<a href="#"><h4>Portable latest Fashion for young women</h4></a>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore.
							</p>
							<p className="post-date">31st January, 2018</p>
						</div>
						<div className="col-lg-6 single-blog">
							<img className="img-fluid" src="img/b2.png" alt="" />
							<ul className="tags">
								<li><a href="#">Travel</a></li>
								<li><a href="#">Life style</a></li>
							</ul>
							<a href="#"><h4>Portable latest Fashion for young women</h4></a>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore  et dolore.
							</p>
							<p className="post-date">31st January, 2018</p>
						</div>						
					</div>
				</div>	
			</section>
			 {/* End latest-blog Area */}
        </div>
        )

    }
}


function mapStateToProps(state, actions) {
    return {}
}

export default connect(mapStateToProps)(withRouter(HomeComponent));