import { PLAN_GET_FAILURE,PLAN_GET_LOADING,PLAN_GET_SUCCESS,PLAN_GET_COMPLETE,
    BUY_FAILURE,BUY_LOADING,BUY_SUCCESS,BUY_COMPLETE
 } from './types';
const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};
    export const fetchprices = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLAN_GET_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case PLAN_GET_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case PLAN_GET_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case PLAN_GET_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}

export function fetchbuy(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BUY_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case BUY_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case BUY_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case BUY_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}
