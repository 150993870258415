
import { FAILURE,LOADING,SUCCESS,COMPLETE } from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');


//User Login
export function fetchFailed(msg) {
    return {
        type: FAILURE,
        hasErrored: msg
    };
}
export function fetchLoading(bool) {
    return {
        type: LOADING,
        isLoading: bool
    };
}
export function fetchSuccess(items) {
    return {
        type: SUCCESS,
        Data:items
    };
}

export function fetchComplete(status) {
    return {
        type: COMPLETE,
        isCompleted: status
    };
}

export function FetchData(url, payload) {
        return (dispatch) => {
        dispatch(fetchLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchSuccess(eventData));
                dispatch(fetchComplete(true));
            })
            .catch((err) => {
                dispatch(fetchFailed(err.message))
            }
            );
    };
  
}
