
import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import PlanDetails from "../../components/PlanDetails";

const Plans = (prop) => {

    return (
       
        <HeaderandFooter >
        <PlanDetails>
           
        </PlanDetails>
    </HeaderandFooter>
      
    );
};

export default Plans;