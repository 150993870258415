import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import PaymentComponent from "../../components/PaymentComponent";

const Payment = () => {


    return (
        <HeaderandFooter >
         <PaymentComponent  />
        </HeaderandFooter>
    );
};

export default Payment;