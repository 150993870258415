import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { FetchData } from '../../redux/ForgetPassword/actions';
import { Link, withRouter } from 'react-router-dom';
import { forget } from "../../utils/constants";

import "../Login/login.css";
import Spinner from "../../components/Spinner";



class ForgotPassword extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        var payload = {
            "email": event.target.email.value,
        }

        this.setState({ loader: true })
        let Url = forget;
        this.props.dispatch(FetchData(Url, payload));

    }



    render() {
        const { Status, message, Data } = this.props

        if (Status == 200) {
            this.state.loader = false;
            ToastsStore.success(message);
            setTimeout(
                function () {
                    this.props.history.push('/login')
                }
                    .bind(this),
                4000
            );
        }
        else if (Status == 400) {
            this.state.loader = false;
            ToastsStore.error(message);
        }

        return (

            <div className="wrapper fadeInDown">
                {this.state.loader && (<Spinner />)}

                <div id="formContent">

                    <div className="fadeIn first">
                        <img src="img/certonce_logo.png" className="log" alt="User Icon" />
                    </div>


                    <form onSubmit={this.handleSubmit}>
                        <input type="text" id="OTP" className="fadeIn second" placeholder="Enter Register Email" name="email" />
                        {/* <input type="text" id="OTP" className="fadeIn second" placeholder="Please Enter Your OTP" name="OTPN" /> */}
                        {/* <input type="text" id="newpassword" className="fadeIn third" placeholder="New Password" name="pswd" /> */}
                        {/* <input type="text" id="Conformpassword" className="fadeIn third" placeholder="Conform Password" name="pswd2" /> */}
                        <input type="submit" className="fadeIn fourth" value="Submit" />
                    </form>
                    <div id="formFooter">
                            <div className="d-flex justify-content-center links" >
                           <Link to="/login" ><b> Sign in </b></Link>
                            </div>
                        </div>
                </div>
                <ToastsContainer store={ToastsStore} />

            </div>




        )
    }
}
function mapStateToProps(state, actions) {



    if (state.fetchApiData && state.fetchApiData.Data && state.fetchApiData.Data.Status) {
        return state.fetchApiData.Data
    }
    else {
        return {}

    }
}

export default connect(mapStateToProps)(withRouter(ForgotPassword));


// export default ForgotPassword;