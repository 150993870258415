import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

import { MultiSelect } from "react-multi-select-component";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import './apidialog.css';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {uploadstudentbyapi, getbatchurlbyapi} from "../../utils/constants"
import axios from 'axios';


const ApiDialog = ({ open, onClose }) => {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [listData, setListData] = useState([]);
  const [undergraduate, setUndergraduate] = useState(false);
  const [postgraduate, setPostgraduate] = useState(false);
  const [paid, setPaid] = useState(true);
  const [con, setCon] = useState(false);
  
  useEffect(() => {
    fetchData().then((response) => {
      //setData(response);
    });
    fetchListData().then((response) => {
      //setListData(response);
    });
  }, []);

  const fetchData = () => {
    // Replace this with your actual fetch call
    return Promise.resolve([
      {value: 'option1', label: 'Option 1' },
      {value: 'option2', label: 'Option 2' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      // Add more options as needed
    ]);
  };

  const fetchListData = () => {
    // Replace this with your actual fetch call
    return Promise.resolve([
      {value: 'option1', label: 'Option 1' },
      {value: 'option2', label: 'Option 2' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
      {value: 'option3', label: 'Option 3' },
    ]);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.name === 'undergraduate') {
      setUndergraduate(event.target.checked);
      getBatchList(event.target.checked, postgraduate, selected, paid, 1);
      
    } else if (event.target.name === 'postgraduate') {
      setPostgraduate(event.target.checked);
      getBatchList(undergraduate, event.target.checked, selected, paid, 1);
    } else if (event.target.name === 'paid') {
      setPaid(event.target.checked);
      getBatchList(undergraduate, postgraduate, selected, event.target.checked, 3);
    }
  };
  const getBatchList =(undergraduate, postgraduate, selected, paid, mode) =>
  {
    setCon(true);
    let payload = {
      undergraduate: undergraduate,
      postgraduate: postgraduate,
      selected:JSON.stringify(selected),
      paid:paid,
      mode:mode
    };
    var Token = 'bearer ' +localStorage.getItem('Token');
    const headers = {
    'Authorization':  Token,                
    }
    axios.post(getbatchurlbyapi, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
    }).then(response => {             
      if(response.status == "200"){                
        //ToastsStore.success(response.data.message); 
        setCon(false);
        if(response.data.Status=="200")
        {
          if(mode==1)
          {
            if(response.data.batchdata.length==0)
            {            
              setSelected(response.data.batchdata);
              setData(response.data.batchdata);
            }
            else
            {
              setData(response.data.batchdata);
            }

            setListData(response.data.paiddata);
          }
          else if(mode==2 || mode==3)
          {
            setListData(response.data.paiddata);
          }
          else
          {
            onClose();
          }
        }
        else
        {
          ToastsStore.error("Error");  
        }
      }
      else
      {
        ToastsStore.error(response.data.message); 
        
      }        
    }).catch((error) => {
        ToastsStore.error("Error"); 
        setCon(false);
    }); 
    
  } 
  const uploadStudentByApi =(undergraduate, postgraduate, selected, paid) =>
  {
    if(undergraduate==false && postgraduate==false)
    {
      ToastsStore.error("You must choose at least one of your undergraduate and postgraduate.");
      return;
    }
    setCon(true);
    let payload = {
      undergraduate: undergraduate,
      postgraduate: postgraduate,
      selected:JSON.stringify(selected),
      paid:paid
    };
    var Token = 'bearer ' +localStorage.getItem('Token');
    const headers = {
    'Authorization':  Token,                
    }
    axios.post(uploadstudentbyapi, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
    }).then(response => {   
      setCon(false);          
      if(response.status == "200"){
        onClose();
        if(response.data.Status=="200")
        {          
          ToastsStore.success(response.data.message); 
        }
        else
        {
          ToastsStore.error(response.data.message);
        }
      }
      else
      {
        ToastsStore.error("Error");
      }        
    }).catch((error) => {
      setCon(false);
      ToastsStore.error("Error:",error);         
    }); 
    
  } 
  const handleImport = (event) =>
  {
    let text = "Are you sure?";
    if (window.confirm(text) == true) {
      uploadStudentByApi(undergraduate, postgraduate, selected, paid);
    } else {
      
    }
    
  }
  const handleSelectChange = (selectedOptions) => {
    // Custom logic here
    console.log("Selection changed:", selectedOptions);
    setSelected(selectedOptions);
    getBatchList(undergraduate, postgraduate, selectedOptions, paid, 2);
 };
  return (
    <>
    {con && (<Spinner></Spinner>)}
    
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle><h1 style={{color:'rgba(0, 0, 0, 0.87)',fontWeight:'bold'}}>Please select programme</h1></DialogTitle>
      <DialogContent sx={{ width: '700px', height:'550px'}}>
        <FormControlLabel
          control={<Checkbox name="undergraduate" checked={undergraduate} onChange={handleCheckboxChange} />}
          label="Undergraduate"
        />
        <FormControlLabel
          control={<Checkbox name="postgraduate" checked={postgraduate} onChange={handleCheckboxChange} />}
          label="Postgraduate"
        />
        <br />
        <div>
          {/* <h1>Select Fruits</h1>
          <pre>{JSON.stringify(selected)}</pre> */}
          <MultiSelect
            options={data}
            value={selected}
            onChange={handleSelectChange}
            labelledBy="Select"
            className="my-multi-select" // Add a unique class name here
          />
        </div>
        <br />
        <FormControlLabel
          control={<Checkbox name="paid" checked={paid} onChange={handleCheckboxChange} />}
          label="Paid"
        />
        <br />
        <div>
          <List
            sx={{
              width: '100%',              
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
              '& ul': { padding: 0 },
            }}
          >
            {
              listData.map((item, index) => (
                <ListItem 
                   key={index} 
                   sx={{
                     border: '1px solid #ccc',
                     borderBottom: index === listData.length - 1 ? '1px solid #ccc' : 'none'
                   }}
                >
                   <ListItemText primary={item.label}  />
                </ListItem>
               ))
            }
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="btn btn-info" onClick={handleImport}>Import</Button>
        <Button className="btn btn-info" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default ApiDialog;
