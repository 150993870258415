import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { UserPriceFetchData } from '../../redux/Plan/actions';
import { GET_PLANS } from "../../utils/constants";
import Plan from "./Plan";

class PlanDetails extends Component {

    constructor (props){
        super(props)
        this.state = { features: [], plans: [],topayment:false,plan:null,bind :true }
        this.selectplan = this.selectplan.bind(this)
	}
	
	componentDidMount() {
		var url = GET_PLANS;
		this.props.dispatch(UserPriceFetchData(url));
	
	
	  }

    selectplan(p) {
        //  console.log("click:",p)
        this.setState({plan:p.plane_id})
		this.props.history.push("/payment",{PlanID:p.plane_id,Amount:p.price,count:p.List[0].count,plan:p});
        // this.props.history.push("/payment",{PlanID:1,Amount:100});
      }

    render(){
		const { Status ,message ,Data } = this.props;
	

		if(Status == 200 && this.state.bind)
		{
			this.setState({plans:Data})
			this.setState({bind:false})
		
		}


        return (
            <div>
	 {/* Start price Area */}
     <section className="price-area section" id="price">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="menu-content pb-60 col-lg-8">
							<div className="title text-center">
								<h1 className="mb-10">Choose the best pricing for you</h1>
								<p>Who are in extremely love with customer friendly system.</p>
							</div>
						</div>
					</div>						
					<div className="row">
				
					{this.state.plans.map((p, i) => <Plan selectplan={this.selectplan.bind(this)} key = {i} 
					 plan = {p} />)}				 
					
		
					</div>
				</div>	
			</section>
			 {/* End price Area */}
                </div>
        )
    }
}
function mapStateToProps(state, actions) {
	if (state.fetchprices && state.fetchprices.Data && state.fetchprices.Data.Status ) {
	  return state.fetchprices.Data
	}
	else {
	  return {}
  
	}
  }


export default connect(mapStateToProps)(withRouter(PlanDetails));
