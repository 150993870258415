import React, { Component } from 'react';
// import { StripeMain } from "../stripe/StripeMain";
import { withRouter } from 'react-router-dom';
import StripeBtn from "../stripe/stripeBtn";
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import HeaderandFooter from "../HeaderandFooter";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


class Plainloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }


  render() {
    const { Status, message, Data } = this.props;


    if (Status == 200) {

      ToastsStore.success(message);
      setTimeout(
        function () {
          this.props.history.push('/Dashboard');
        }
          .bind(this),
        1000
      );

    }
    else if (Status == 400) {
      ToastsStore.error(message);
      setTimeout(
        function () {
          this.props.history.push('/Dashboard');
        }
          .bind(this),
        1000
      )
    }
    return (
      <HeaderandFooter>

      <div className='sweet-loading container jumbotron-jum jumbotron vertical-center' >
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
        <ToastsContainer store={ToastsStore} />
      </div>
      </HeaderandFooter>
    )
  }
}

function mapStateToProps(state, actions) {
  if (state.fetchbuy && state.fetchbuy.Data && state.fetchbuy.Data.Status) {

    return state.fetchbuy.Data;
  }
  else {
    return {}

  }
}

export default connect(mapStateToProps)(withRouter(Plainloader));
