import React from 'react'
import "./button.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendinvitationGS,getpublickey ,imagebaseurl,server_ip} from "../../utils/constants";
import { SendInvitationGS,GetPublicKey} from '../../redux/GetStarted/actions';
import Spinner from "../Spinner";
import {Alert,Button, Modal} from 'react-bootstrap';
var parse = require('html-react-parser');
export class StepTwo extends React.Component {
  introduction_email_body='';
  onetimecode='';
  constructor () {
    super()
    this.state = {  
      con: false,  
      alertData: {
          status: false,
          className: '',
          message: ''
       },      
    }
    this.nextFn = this.nextFn.bind(this);    
    this.prevFn = this.prevFn.bind(this);     
  }
  
  nextFn =e=> {
    e.preventDefault();
    this.setState({con:true});  
    let payload = {
            onetimecode : this.onetimecode,                        
            emailaddress: this.props.values.emailAddress
        };
     this.props.dispatch(GetPublicKey(getpublickey, payload));
   
    
  }
  prevFn =e=> {
    e.preventDefault();    
    this.props.prevStep();
  }
  sendInvitation=e=>{
    this.setState({con:true});
    //console.log(this.introduction_email_body)
    let payload = {
            onetimecode : this.onetimecode,            
            emailbody : this.introduction_email_body,            
            emailaddress: this.props.values.emailAddress
        };
     this.props.dispatch(SendInvitationGS(sendinvitationGS, payload));
  }
componentDidMount(){
  
}
componentDidUpdate(prevProps, prevState) {
  
  if ( prevProps.Students ) {
    if(prevProps.Students.Status==200)
    {      
       this.setState({con:false});
       this.setState({alertData:{status:'Success',className: 'success', message: prevProps.Students.message}});
       window.scrollTo(0,0);
    }
    else
    {
      this.setState({con:false});
      this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.Students.message}});
      window.scrollTo(0,0);
    }
  }
  else if (prevProps.PublicKey) {
    
    if(prevProps.PublicKey.Status==200)
    {
      
       this.props.setPublicKey(prevProps.PublicKey.Data.publickey);      
       this.setState({con:false});   
       this.props.nextStep();    
    }
    else
    {
      this.setState({con:false});
      this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.PublicKey.message}});
      window.scrollTo(0,0);
    }
  }
}
closeAlert() {
      var alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData});
    }
  render () {
    const {values}=this.props;
      const email=values.emailAddress;
      const first_name = values.firstName+values.lastName;
      this.onetimecode = values.onetimecode;
      var alertData = this.state.alertData;      
      if(this.onetimecode=="") 
      {
        console.log("Didn't received onetimecode");
      }
      else
      {
      //console.log(this.onetimecode)
      const issuer_url=server_ip+"/blockchain/issuergs.json";
      const issuer_url1 = issuer_url.split("/").join("%2F");
      const introduction_url = "https://wallet.blockcerts.org/#/introduce-recipient/"+issuer_url1+"/"+this.onetimecode
      const introduction_urlqr = encodeURIComponent("https://wallet.blockcerts.org/#/introduce-recipient/"+issuer_url+"/"+this.onetimecode)
      const qrcode = "<img src='https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl="+introduction_urlqr+"' title='Introduction url' />"
      const introduction_email_subject = "Add us as a Blockcerts issuer"
      this.introduction_email_body = "<p>Dear "+first_name+"</p><p>In order to issue you a blockchain certificate you need to register your wallet with our issuer. Here's how to do that:</p><p><b>Step 1: Install the app</b></p><p>Install the 'Blockcerts Wallet' app on your phone (available on the iOS or Android app stores).<a href='https://apps.apple.com/us/app/blockcerts-wallet/id1146921514' target='_blank'><img style='width:100px' src='"+imagebaseurl+"app_store_badge.png' class='inline sm-marg-l-2' /></a><a href='https://play.google.com/store/apps/details?id=com.learningmachine.android.app' target='_blank'><img style='width:100px' src='"+imagebaseurl+"play_store_badge.png' class='inline sm-marg-l-2' /></a></p><br><p><b>Step 2: Add issuer</b></p><p>There are two ways to add us as an issuer:</p><ul><li>If you're reading this email on your mobile device, <a clicktracking='off' href='"+introduction_url+"'>Click this link to add us as an issuer</a>.</li><li>If you're reading this email on your desktop, scan the QR code below using your mobile device.</li></ul>"+qrcode+"<p>Please note: it's important to complete these steps in this order. After you have added us as an issuer, you will be notified when your credential is ready.</p>";
      }
    return (
      <div>
      {this.state.con && (<Spinner></Spinner>)}
      { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
      <div className='row' style={{"justifyContent": "center"}}>
          <div className='six columns'>
            {parse(this.introduction_email_body)}
          </div>
        </div>
        
        <br/>
        <div  style={{"justifyContent": "center","display": "flex"}}>
          <div style={{"width":"30%"}}>
            <button className="back"  onClick={this.prevFn}>Previous</button>            
          </div>
          <div style={{"width":"40%"}}>
            <button className="site"  onClick={this.sendInvitation}>Send</button>            
          </div>
          <div style={{"width":"30%"}}>           
            <button className="forward" onClick={this.nextFn}>Next</button>
          </div>
        </div>
        				
      </div>
    )
  }
}
function mapStateToProps(state, actions) {
    if (state.fetchInviteStudents && state.fetchInviteStudents.Data && state.fetchInviteStudents.Data.Status) {
        return { Students: state.fetchInviteStudents.Data}
    } 
    else if (state.fetchGetPublicKey && state.fetchGetPublicKey.Data && state.fetchGetPublicKey.Data.Status) {
        return { PublicKey: state.fetchGetPublicKey.Data}
    } 
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(StepTwo));
//export default StepOne