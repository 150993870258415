//copy email as pk
export const SEND_COPYEMAILASPK_SUCCESS= 'SEND_COPYEMAILASPK_SUCCESS';
export const SEND_COPYEMAILASPK_LOADING = 'SEND_COPYEMAILASPK_LOADING';
export const SEND_COPYEMAILASPK_FAILURE = 'SEND_COPYEMAILASPK_FAILURE';
export const SEND_COPYEMAILASPK_COMPLETE = 'SEND_COPYEMAILASPK_COMPLETE';

//Get Students
export const GET_STUDENTS_SUCCESS= 'GET_STUDENTS_SUCCESS';
export const GET_STUDENTS_LOADING = 'GET_STUDENTS_LOADING';
export const GET_STUDENTS_FAILURE = 'GET_STUDENTS_FAILURE';
export const GET_STUDENTS_COMPLETE = 'GET_STUDENTS_COMPLETE';


export const SUCCESS = 'SUCCESS';
export const LOADING = 'LOADING';
export const FAILURE = 'FAILURE';
export const COMPLETE = 'COMPLETE';


//Get Cohorts
export const GET_COHORTS_SUCCESS= 'GET_COHORTS_SUCCESS';
export const GET_COHORTS_LOADING = 'GET_COHORTS_LOADING';
export const GET_COHORTS_FAILURE = 'GET_COHORTS_FAILURE';
export const GET_COHORTS_COMPLETE = 'GET_COHORTS_COMPLETE';

//Send Invitation
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_LOADING = 'SEND_INVITATION_LOADING';
export const SEND_INVITATION_FAILURE = 'SEND_INVITATION_FAILURE';
export const SEND_INVITATION_COMPLETE = 'SEND_INVITATION_COMPLETE';


//Upload Students
export const UPLOAD_STUDENTS_FAILURE = 'UPLOAD_STUDENTS_FAILURE';
export const UPLOAD_STUDENTS_LOADING = 'UPLOAD_STUDENTS_LOADING';
export const UPLOAD_STUDENTS_SUCCESS = 'UPLOAD_STUDENTS_SUCCESS';
export const UPLOAD_STUDENTS_COMPLETE = 'UPLOAD_STUDENTS_COMPLETE';

//Update Students
export const UPDATE_STUDENTS_FAILURE = 'UPDATE_STUDENTS_FAILURE';
export const UPDATE_STUDENTS_LOADING = 'UPDATE_STUDENTS_LOADING';
export const UPDATE_STUDENTS_SUCCESS = 'UPDATE_STUDENTS_SUCCESS';
export const UPDATE_STUDENTS_COMPLETE = 'UPDATE_STUDENTS_COMPLETE';

//Delete Students
export const DELETE_STUDENTS_FAILURE = 'DELETE_STUDENTS_FAILURE';
export const DELETE_STUDENTS_LOADING = 'DELETE_STUDENTS_LOADING';
export const DELETE_STUDENTS_SUCCESS = 'DELETE_STUDENTS_SUCCESS';
export const DELETE_STUDENTS_COMPLETE = 'DELETE_STUDENTS_COMPLETE';