import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import MyCertificatesComponent from "../../components/MyceritificatesComponent"

const MyCertificates = (prop) => {

    return (
       
        <HeaderandFooter >
 <MyCertificatesComponent />
    </HeaderandFooter>
      
    );
};

export default MyCertificates;