import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import "./style.css";
import Spinner from "../../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import {Alert,Button, Modal} from 'react-bootstrap';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';
import { UpdateCertificateTemplate} from '../../../redux/DesignCertificate/actions';
import {updatecertificatetemplateurl } from "../../../utils/constants";


class PreviewTemplateComponent extends Component {
    
    constructor(props) {
        super(props);
        
        this.state={
            con: false,           
            alertData: {
                status: false,
                className: '',
                message: ''
            },   
                   
            
        }       
    }

    saveTemplate=e=>{
        e.preventDefault();  
        this.setState({ con: true });  
    
        let payload = {        
            template: this.props.value,
            openbadge_template: this.props.value_openbadges,
            cohortid: this.props.cohortid,
            isopenbadge:"false",
            ismailtemplate:"false",
            type: "save",
            selectedCohortJson:localStorage.getItem('selectedCohortJson')  ,
            certtype:localStorage.getItem('selectedCertType')
        };     
        try {
            var validjson=JSON.parse(this.props.value);
            
            if(validjson.isopenbadge=="true")
            {
                payload = {        
                    template: this.props.value,
                    openbadge_template: this.props.value_openbadges,
                    cohortid: this.props.cohortid,
                    isopenbadge:"true",
                    ismailtemplate:"false",
                    type: "save",
                    selectedCohortJson:localStorage.getItem('selectedCohortJson')  ,
                    certtype:localStorage.getItem('selectedCertType')
                    };

            }
            else if(validjson.ismailtemplate=="true")
            {
                payload = {        
                    template: this.props.value,
                    openbadge_template: this.props.value_openbadges,
                    cohortid: this.props.cohortid,
                    isopenbadge:"false",
                    ismailtemplate:"true",
                    type: "save",
                    selectedCohortJson:localStorage.getItem('selectedCohortJson')  ,
                    certtype:localStorage.getItem('selectedCertType')
                    };

            }            
            
        } catch (e) {
            try {
                var validjson1=JSON.parse(this.props.value);            
                if(validjson1.ismailtemplate=="true")
                {
                    payload = {        
                        template: this.props.value,
                        openbadge_template: this.props.value_openbadges,
                        cohortid: this.props.cohortid,
                        isopenbadge:"false",
                        ismailtemplate:"true",
                        type: "save",
                        selectedCohortJson:localStorage.getItem('selectedCohortJson')  ,
                        certtype:localStorage.getItem('selectedCertType')
                        };

                }            
            } catch (e1) {
                console.log(e1)
            }
        } 
    console.log(payload)
    //return;
    this.props.dispatch(UpdateCertificateTemplate(updatecertificatetemplateurl,payload));
    }

    // previewTemplate=e=>{
    // e.preventDefault();  
    // this.setState({ con: true });  
    
    // let payload = {        
    //         template: this.props.value,
    //         openbadge_template: this.props.value_openbadges,
    //         cohortid: this.props.cohortid,
    //         isopenbadge:"false",
    //         ismailtemplate:"false"
    //     };     
    // try {
    //         var validjson=JSON.parse(this.props.value);
            
    //         if(validjson.isopenbadge=="true")
    //         {
    //             payload = {        
    //                 template: this.props.value,
    //                 openbadge_template: this.props.value_openbadges,
    //                 cohortid: this.props.cohortid,
    //                 isopenbadge:"true",
    //                 ismailtemplate:"false"
    //                 };

    //         }
    //         else if(validjson.ismailtemplate=="true")
    //         {
    //             payload = {        
    //                 template: this.props.value,
    //                 openbadge_template: this.props.value_openbadges,
    //                 cohortid: this.props.cohortid,
    //                 isopenbadge:"false",
    //                 ismailtemplate:"true"
    //                 };

    //         }            
            
    //     } catch (e) {
    //         try {
    //             var validjson1=JSON.parse(this.props.value);            
    //             if(validjson1.ismailtemplate=="true")
    //             {
    //                 payload = {        
    //                     template: this.props.value,
    //                     openbadge_template: this.props.value_openbadges,
    //                     cohortid: this.props.cohortid,
    //                     isopenbadge:"false",
    //                     ismailtemplate:"true"
    //                     };

    //             }            
    //         } catch (e1) {
    //             console.log(e1)
    //         }
    //     } 
    // console.log(payload)
    // //return;
    // this.props.dispatch(UpdateCertificateTemplate(updatecertificatetemplateurl,payload));
    // }
    nextFn =e=> {
        e.preventDefault();    
        this.props.nextStep();
    }
    prevFn =e=> {
        e.preventDefault();    
        this.props.prevStep();
    }

    componentDidMount()
    {
        console.log("enter in preview1:", this.props);
        //console.log("enter in preview2: ", this.previewContent);
        document.getElementById("previewResult").innerHTML=this.props.previewContent;

    }
    componentDidUpdate(prevProps, prevState) {
        //console.log(prevProps);
        
        if(prevProps.updatecertificatestatus)
        {
        this.setState({con:false});
        if (prevProps.updatecertificatestatus.Status === 200)
            {               
            this.setState({alertData:{status:'Success',className: 'success', message: prevProps.updatecertificatestatus.message}});
            }
            else
            {
            this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.updatecertificatestatus.message}});
            }
        }
    }
        
        
    closeAlert() {
        let alertData = {
            status: false,
            className: '',
            message: ''
        }
        this.setState({alertData:alertData})
    }

    render() {
        require("./style.css");
        const {placeholder} = this.state;
        var alertData = this.state.alertData;        
        return (
            <section className="project-tab">
            {this.state.con && (<Spinner></Spinner>)}
            { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}         
              	<div id="previewContainer">
                {/* <div id="topStrip"></div>                 */}
                <div id="previewContent" style={{"height":"800px"}}>	
                <div id="previewProgress">
                      <a className="steps" id="step1">
                        <cufon className="cufon cufon-canvas" alt="Select " style={{"width": "62px","height": "19px"}}>
                            <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                            <cufontext>Select design</cufontext>
                        </cufon>                        
                       </a>
                        <a className="steps" id="step2">
                            <cufon className="cufon cufon-canvas" alt="Personalize" style={{"width": "107px", "height": "19px"}}>
                                <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                                <cufontext>Personalize</cufontext>
                            </cufon>
                        </a>
                        <a className="steps current" id="step3">
                            <cufon className="cufon cufon-canvas" alt="Download" style={{"width": "96px", "height": "19px"}}>
                                <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                                <cufontext>Preview</cufontext>
                            </cufon>
                        </a>                     
                        </div>
                        <div id="previewResult" style={{"height": "600px", "overflow": "auto", "margin": "30px 15px 50px 15px", "border": "1px solid black"}}>
                        </div>
                        <div  className="container-contact100-form-btn">
                            {/* <button  className="contact100-form-btn preview" style={{"width": "111px"}} onClick={this.preview}>
                                <span>
                                    Preview
                                </span>
                            </button>
                        </div>

                        <div style={{"justifyContent": "left"}} className="container-contact100-form-btn"> */}
                            
                            <button className="contact100-form-btn prev" onClick={this.prevFn}>
                                <span>
                                    Prev
                                </span>
                            </button>&nbsp;&nbsp;&nbsp;
                                <button className="contact100-form-btn save"  style={{"width": "177px"}} onClick={this.saveTemplate}>
                                    <span>
                                        Save
                                    </span>
                            </button>
                        </div>

                        {/* <div className="row_margin3 row"> */}
                        {/* <div className="container-contact100-form-btn">
                            <center>
                                <h1 style={{"color": "#2384c6","fontSize": "25px","marginBottom": "20px"}}><cufon className="cufon cufon-canvas" alt="Select " style={{"width": "84px","height":"27px"}}><cufontext></cufontext></cufon></h1>
                                <button className="contact100-form-btn save" style={{"width": "177px"}} onClick={this.saveTemplate}>
                                    <span>
                                        Save the JSON
                                    </span>
                                </button>
                                <button className="contact100-form-btn prev" onClick={this.prevFn}>
                                    <span>
                                        Prev
                                    </span>
                                </button>
				            </center>                                
                        </div>  */}
                </div>		
                    {/* <div id="bottomStrip"></div> */}
                </div>     
            </section>
        )}
    
}
function mapStateToProps(state, actions) {
    if (state.updateCertificateTemplate && state.updateCertificateTemplate.Data && state.updateCertificateTemplate.Data.Status) {
        return { updatecertificatestatus: state.updateCertificateTemplate.Data }
    } 
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(PreviewTemplateComponent));

// export default MyProfileView;