import React, { Fragment } from "react";
import StripeCheckout from "react-stripe-checkout";
import logo from "../../images/certonce_logo.png";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { buyFetchData } from '../../redux/Plan/actions';
import { stripecharge } from "../../utils/constants";

const stripeBtn = (props) => {
  const publishableKey = "pk_test_DRaWsbRWJG7HpAZTCDU5o6rG";
 


  const onToken = token => {

    var customer = props.customer;
    token.PLANE_ID = customer.PlanID;
    token.USER_ID = localStorage.getItem("user_id")
    token.Amount = customer.Amount;
    token.count = customer.count;
    props.history.push('/paymentstatus');

     props.dispatch(buyFetchData(stripecharge, token));

  };




  return (


    <div>


            
 <StripeCheckout
        email={props.customer.Email}
        name="Ashok Reddy"
        label={"Buy $" + props.customer.Amount / 100} //Component button text
        name="Block Certficates" //Modal Header
        description=""//"Upgrade to a premium account today."
        panelLabel=""//"Go Premium" //Submit button in modal
        amount={props.customer.Amount} //Amount in cents $9.99
        token={onToken}
        stripeKey={publishableKey}
        image={logo} //Pop-in header image
        billingAddress={false}
      />
      <ToastsContainer store={ToastsStore} />
    </div>
  );
};
// export default withRouter(stripeBtn);

function mapStateToProps(state, actions) {
  if (state.fetchbuy && state.fetchbuy.Data && state.fetchbuy.Data.Status) {
   
    return state.fetchbuy.Data;
  }
  else {
    return {}

  }
}

export default connect(mapStateToProps)(withRouter(stripeBtn));