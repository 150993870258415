import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendscheduledemoGS } from "../../utils/constants";
import { InsertScheduleDemoData} from '../../redux/GetStarted/actions';
import Spinner from "../Spinner";

import "./main.css";
import "./util.css";





class RegisterDemoComponent extends Component {
    selectindex=0;
    optionsfindus=[];
    constructor(props) {
        super(props);
        this.state={
            con: false, 
            firstName: '',  
            lastName:'',
            emailAddress:'',
            phoneNumber:'',
            organizationName:'',
            role:'',
            describe:'',
            findUs:'',
            questions:'',
            
        }        
        this.submit = this.submit.bind(this);  
        
        this.eleFirstName = React.createRef();
        this.eleLastName = React.createRef();
        this.eleEmailAddress = React.createRef();
        this.elePhoneNumber = React.createRef();
        this.eleOrganizationName = React.createRef();
        this.eleRole = React.createRef();
        this.eleDescribe = React.createRef();
        this.eleFindUs = React.createRef();
        this.eleQuestions = React.createRef();
    }
validationHandle()
  {
     if(this.state.firstName==="")
     {       
       this.eleFirstName.current.focus();
       return false;
     }
     else if(this.state.lastName==="")
     {        
       this.eleLastName.current.focus();      
       return false;
     }
     else if(this.state.emailAddress==="")
     {        
       this.eleEmailAddress.current.focus();      
       return false;
     }
     else if(this.state.phoneNumber==="")
     {        
       this.elePhoneNumber.current.focus();      
       return false;
     }
     else if(this.state.organizationName==="")
     {        
       this.organizationName.current.focus();      
       return false;
     }
     else if(this.state.eleRole==="")
     {        
       this.eleRole.current.focus();      
       return false;
     }
     
     
     return true;

  }
 
   submit =e=> {    
    e.preventDefault(); 
    this.setState({focusindex:0});    
    if(this.validationHandle())
    {    
    this.setState({con:true});
    let payload = {
            firstName : this.state.firstName,
            lastName: this.state.lastName,
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
            organizationName: this.state.organizationName,
            role: this.state.role,
            describe: this.state.describe,
            findUs: this.state.findUs,
            questions: this.state.questions,
        };
     this.props.dispatch(InsertScheduleDemoData(sendscheduledemoGS, payload));
    }
    
  }
   handleChangeFirstName=e=>{
       this.setState({firstName:e.target.value})
   }
   handleChangeLastName=e=>{
       this.setState({lastName:e.target.value})
   }
   handleChangeEmailAddress=e=>{
       this.setState({emailAddress:e.target.value})
   }
   handleChangePhoneNumber=e=>{
       this.setState({phoneNumber:e.target.value})
   }
   handleChangeOrganizationName=e=>{
       this.setState({organizationName:e.target.value})
   }
   handleChangeRole=e=>{
       this.setState({role:e.target.value})
   }
   handleChangeDescribe=e=>{
       this.setState({describe:e.target.value})
   }
   handleChangeFindUs=e=>{       
       let idx = e.target.selectedIndex;
       this.selectindex=idx;
	   let value = e.target.options[idx].value;
       this.setState({findUs:value});
       //console.log(value)
   }
   handleChangeQuestions=e=>{       
       this.setState({questions:e.target.value})
   }
  componentDidMount(){
       
   }
  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.DemoStatus ) {
        if(prevProps.DemoStatus.Status==200)
        {
        //this.props.setOneTimeCode(prevProps.Students.Data.onetimecode);
        this.setState({con:false});
        //console.log(this.props.values.onetimecode);
        //this.props.nextStep();
        }
    }
   }

    render() {
        this.optionsfindus = [{'label' : '-Please Select-', 'value' : ''},{'label' : 'Google', 'value' : 'Google'},{'label' : 'Web search', 'value' : 'Web search'}];
        let optionslist = this.optionsfindus.length > 0
    	&& this.optionsfindus.map((item, i) => {
            if(this.selectindex==i)
                {
                    return (
                        <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this);
        

        return ( 
                <div className="maincontainer">   
                    {this.state.con && (<Spinner></Spinner>)}            
                    <div className="limiter">
                        <div className="container-login100">
                            <div className="login100-more" >
                                    <div style={{"padding":"50px"}}>
                                    <br/>
                                    <p style={{"fontSize":"40px","color":"white"}}>SCHEDULE A DEMO</p>
                                    <br/>
                                    <p style={{"fontSize":"15px","color":"white"}}>Thank you for your interest in Credly. Contact us to see how our industry-leading digital credentialing platform will help you:</p>
                                    
                                    <br/>
                                    <br/>
                                    <p style={{"fontSize":"15px","color":"white"}}><span>&#10003;</span> Retain and engage your employees</p>
                                    <p style={{"fontSize":"15px","color":"white"}}><span>&#10003;</span> Allow your learners to benefit from their verified skill set</p>
                                    <p style={{"fontSize":"15px","color":"white"}}><span>&#10003;</span> Attract and retain members</p>
                                    
                                    <br/>
                                    <br/>
                                    <p style={{"fontSize":"16px","color":"white"}}>We'll contact you for a quick chat so we can optimize your demo experience, and schedule a time to see Credly in action.</p>
                                    <br/>
                                    <br/>
                                    <br/>                              
                                    <p style={{"fontSize":"20px","color":"white"}}>If you're looking for support help, or would like to reach a member of our Customer Success Team, please click <a style={{"fontSize":"20px","color":"yellow"}} href="#">here.</a></p>
                                    </div>
                            </div>

                            <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                                <form className="login100-form validate-form">
                                    <span className="login100-form-title">
                                        PLEASE FILL OUT THE FORM BELOW
                                    </span>
                                    <span className="login100-form-title1">
                                        Have a support question?Need help?<span style={{"color":"#d5007d"}}>admin@certonce.com</span>
                                    </span>
                                    <div className="wrap-input100 validate-input" data-validate="First Name is required">
                                        <input ref={this.eleFirstName} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="firstname" placeholder="First Name" value={this.state.firstName} onChange={this.handleChangeFirstName}  />                                     
                                        <span className="focus-input100"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="Last Name is required">
                                        
                                        <input ref={this.eleLastName} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="lastname" placeholder="Last Name" value={this.state.lastName} onChange={this.handleChangeLastName} />
                                        <span className="focus-input100"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">                                        
                                        <input ref={this.eleEmailAddress} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="email" placeholder="Email" value={this.state.emailAddress} onChange={this.handleChangeEmailAddress} />
                                        <span className="focus-input100"></span>
                                    </div>

                                    <div className="wrap-input100 validate-input" data-validate="Phone Number is required">                                        
                                        <input ref={this.elePhoneNumber} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="phonenumber" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.handleChangePhoneNumber} />
                                        <span className="focus-input100"></span>
                                    </div>

                                    <div className="wrap-input100 validate-input" style={{"marginTop": "45px"}} data-validate="Organization Name is required">
                                        <span className="label-input100">Organization Name</span>
                                        <input ref={this.eleOrganizationName} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="organizationname" placeholder="Organization Name" value={this.state.organizationName} onChange={this.handleChangeOrganizationName} />
                                        <span className="focus-input100"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="">                                        
                                        <input ref={this.eleRole} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="role" placeholder="Role" value={this.state.role} onChange={this.handleChangeRole} />
                                        <span className="focus-input100"></span>
                                    </div>
                                    <div className="wrap-input100 validate-input" data-validate="This field is required">                                        
                                        <input ref={this.eleDescribe} className="input100" style={{"textAlign": "left","padding":"0px"}} type="text" name="describe" title="Which best describe your organization?" placeholder="Which best describe your organization?" value={this.state.describe} onChange={this.handleChangeDescribe} />
                                        <span className="focus-input100"></span>
                                    </div>
                                    <div style={{"marginTop": "45px"}} className="wrap-input1001" >
                                        <span className="label-input100">How did you find us?</span>                                        
                                        <select className="input1001" style={{"height":"40px"}} onChange={this.handleChangeFindUs} >
                                          {optionslist}
                                        </select>                                      
                                    </div>
                                    <div className="wrap-input1002 validate-input" data-validate="">                                                                                
                                        <textarea ref={this.eleQuestions} className="input1002" name="questions"                                            
                                             placeholder="Please share any specific questions for us." value={this.state.questions} onChange={this.handleChangeQuestions} />
                                        
                                    </div>
                                    <span className="login100-form-title2" >
                                        <p>Credly uses the information you provide to contact you about our products and services.You may unsubscribe from communications at anytime.Read more about our<span style={{"color":"#d5007d"}}> data security and privacy practices.</span></p>
                                    </span>
                                    

                                    <div className="container-login100-form-btn">
                                        <div className="wrap-login100-form-btn">
                                            <div className="login100-form-bgbtn"></div>
                                            <button className="login100-form-btn" onClick={this.submit}>
                                                SUBMIT
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                 </div>   
               )                
        

        }
        


}
function mapStateToProps(state, actions) {
    if (state.fetchInsertScheduleDemo && state.fetchInsertScheduleDemo.Data && state.fetchInsertScheduleDemo.Data.Status) {
        return { DemoStatus: state.fetchInsertScheduleDemo.Data}
    } 
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(RegisterDemoComponent));

// export default MyProfileView;