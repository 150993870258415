import {
    GET_STUDENTS_FAILURE, GET_STUDENTS_LOADING, GET_STUDENTS_SUCCESS, GET_STUDENTS_COMPLETE,
    SEND_COPYEMAILASPK_FAILURE, SEND_COPYEMAILASPK_LOADING, SEND_COPYEMAILASPK_SUCCESS, SEND_COPYEMAILASPK_COMPLETE,
    FAILURE, LOADING, SUCCESS, COMPLETE, GET_COHORTS_FAILURE, GET_COHORTS_LOADING, GET_COHORTS_SUCCESS, GET_COHORTS_COMPLETE,
    SEND_INVITATION_SUCCESS, SEND_INVITATION_LOADING, SEND_INVITATION_FAILURE, SEND_INVITATION_COMPLETE,
    UPLOAD_STUDENTS_FAILURE, UPLOAD_STUDENTS_LOADING, UPLOAD_STUDENTS_SUCCESS, UPLOAD_STUDENTS_COMPLETE,
    UPDATE_STUDENTS_FAILURE, UPDATE_STUDENTS_LOADING, UPDATE_STUDENTS_SUCCESS, UPDATE_STUDENTS_COMPLETE,
    DELETE_STUDENTS_FAILURE, DELETE_STUDENTS_LOADING, DELETE_STUDENTS_SUCCESS, DELETE_STUDENTS_COMPLETE
} from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');


//Copy email as pk
export function updateStudentFailed(msg) {

    return {
        type: UPDATE_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function updateStudentLoading(bool) {
    return {
        type: UPDATE_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function updateStudentSuccess(items) {
    return {
        type: UPDATE_STUDENTS_SUCCESS,
        Data: items
    };
}

export function updateStudentComplete(status) {
    return {
        type: UPDATE_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function reduxUpdateInformation(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: payload,
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
                //'Content-Type': 'text/plain'
            }
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(updateStudentLoading(false));
            return response;
        })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                console.log(eventData);
                dispatch(updateStudentSuccess(eventData));
                dispatch(updateStudentComplete(true));
            })
            .catch((err) => {
                //alert(err)
                dispatch(updateStudentFailed(err.message));
            })
    }

}
//Copy email as pk
export function copyEmailAsPkFailed(msg) {
    return {
        type: SEND_COPYEMAILASPK_FAILURE,
        hasErrored: msg
    };
}
export function copyEmailAsPkLoading(bool) {
    return {
        type: SEND_COPYEMAILASPK_LOADING,
        isLoading: bool
    };
}
export function copyEmailAsPkSuccess(items) {
    return {
        type: SEND_COPYEMAILASPK_SUCCESS,
        Data: items
    };
}

export function copyEmailAsPkComplete(status) {
    return {
        type: SEND_COPYEMAILASPK_COMPLETE,
        isCompleted: status
    };
}

export function CopyEmailAsPkData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: payload,
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
                //'Content-Type': 'text/plain'
            }
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(copyEmailAsPkLoading(false));
            return response;
        })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(copyEmailAsPkSuccess(eventData));
                dispatch(copyEmailAsPkComplete(true));
            })
            .catch((err) => {
                dispatch(copyEmailAsPkFailed(err.message));
            })
    }

}


//Upload Students
export function uploadStudentsfetchFailed(msg) {
    return {
        type: UPLOAD_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function uploadStudentsFetchLoading(bool) {
    return {
        type: UPLOAD_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function uploadStudentsFetchSuccess(items) {
    return {
        type: UPLOAD_STUDENTS_SUCCESS,
        Data: items
    };
}

export function uploadStudentsFetchComplete(status) {
    return {
        type: UPLOAD_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function UploadStudentsFetchData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: payload,
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
                //'Content-Type': 'text/plain'
            }
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(uploadStudentsFetchLoading(false));
            return response;
        })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(uploadStudentsFetchSuccess(eventData));
                dispatch(uploadStudentsFetchComplete(true));
            })
            .catch((err) => {
                dispatch(uploadStudentsfetchFailed(err.message));
            })
    }

}




export function fetchGetStudentsFailed(msg) {
    return {
        type: GET_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function fetchGetStudentsLoading(bool) {
    return {
        type: GET_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function fetchGetStudentsSuccess(items) {
    return {
        type: GET_STUDENTS_SUCCESS,
        Data: items
    };
}

export function fetchGetStudentsComplete(status) {
    return {
        type: GET_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function GetStudentsFetchData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');

    /*
    return (dispatch) => {
    dispatch(fetchGetStudentsLoading(true));
 
    var requestUrl = ( payload['value'] === '' ) ? url + '/?' + payload['key'] + '=all' : url + '/?' + payload['key'] + '=' + encodeURIComponent(payload['value']) ;
    requestUrl += '&certtype=' + payload['certtypevalue'];
    requestUrl += '&pageOffset=' + payload['pageOffset'];
    requestUrl += '&maxitemsperpage=' + payload['maxitemsperpage'];
    requestUrl += '&searchValue=' + payload['searchValue'];
    requestUrl += '&step=' + payload['step'];
    requestUrl += '&status=' + payload['status'];

   
    //alert(requestUrl);
    fetch(requestUrl, {
        method: "GET",
        credentials: "same-origin",
        headers: {
            'Authorization':  Token,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(fetchGetStudentsLoading(false));
            return response;
        })
        .then(response => {
            return response.json();
        })
        .then((eventData) => {
            dispatch(fetchGetStudentsSuccess(eventData));
            dispatch(fetchGetStudentsComplete(true));
        })
        .catch((err) => {
            dispatch(fetchGetStudentsFailed(err.message))
        });
};
*/
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchGetStudentsLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchGetStudentsSuccess(eventData));
                dispatch(fetchGetStudentsComplete(true));
            })
            .catch((err) => {
                dispatch(fetchGetStudentsFailed(err.message))
            }
            );
    };

}

export function fetchGetCohortsFailed(msg) {
    return {
        type: GET_COHORTS_FAILURE,
        hasErrored: msg
    };
}
export function fetchGetCohortsLoading(bool) {
    return {
        type: GET_COHORTS_LOADING,
        isLoading: bool
    };
}
export function fetchGetCohortsSuccess(items) {
    return {
        type: GET_COHORTS_SUCCESS,
        Data: items
    };
}

export function fetchGetCohortsComplete(status) {
    return {
        type: GET_COHORTS_COMPLETE,
        isCompleted: status
    };
}
export function GetCohortsFetchData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        dispatch(fetchGetCohortsLoading(true));

        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(fetchGetCohortsLoading(false));
            return response;
        })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchGetCohortsSuccess(eventData));
                dispatch(fetchGetCohortsComplete(true));
            })
            .catch((err) => {
                dispatch(fetchGetCohortsFailed(err.message))
            }
            );
    };
}

export function sendinvitationfetchFailed(msg) {
    return {
        type: SEND_INVITATION_FAILURE,
        hasErrored: msg
    };
}
export function sendinvitationfetchLoading(bool) {
    return {
        type: SEND_INVITATION_LOADING,
        isLoading: bool
    };
}
export function sendinvitationfetchSuccess(items) {
    return {
        type: SEND_INVITATION_SUCCESS,
        Data: items
    };
}

export function sendinvitationfetchComplete(status) {
    return {
        type: SEND_INVITATION_COMPLETE,
        isCompleted: status
    };
}

//Send Invitation
export function SendInvitation(url, payload, ipflag, certtype = "degree") {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'ipflag': ipflag,
                'certtype': certtype,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(sendinvitationfetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(sendinvitationfetchSuccess(eventData));
                dispatch(sendinvitationfetchComplete(true));
            })
            .catch((err) => {
                dispatch(sendinvitationfetchFailed(err.message))
            }
            );
    };
}






export function fetchFailed(msg) {
    return {
        type: FAILURE,
        hasErrored: msg
    };
}
export function fetchLoading(bool) {
    return {
        type: LOADING,
        isLoading: bool
    };
}
export function fetchSuccess(items) {
    return {
        type: SUCCESS,
        Data: items
    };
}

export function fetchComplete(status) {
    return {
        type: COMPLETE,
        isCompleted: status
    };
}

export function FetchData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        dispatch(fetchLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchSuccess(eventData));
                dispatch(fetchComplete(true));
            })
            .catch((err) => {
                dispatch(fetchFailed(err.message))
            }
            );
    };

}

export function deleteStudentsFailed(msg) {
    return {
        type: DELETE_STUDENTS_FAILURE,
        hasErrored: msg
    };
}
export function deleteStudentsLoading(bool) {
    return {
        type: DELETE_STUDENTS_LOADING,
        isLoading: bool
    };
}
export function deleteStudentsSuccess(items) {
    return {
        type: DELETE_STUDENTS_SUCCESS,
        Data: items
    };
}

export function deleteStudentsComplete(status) {
    return {
        type: DELETE_STUDENTS_COMPLETE,
        isCompleted: status
    };
}

export function DeleteStudentsData(url, payload) {
    var Token = 'bearer ' + localStorage.getItem('Token');
    return (dispatch) => {
        dispatch(deleteStudentsLoading(true));

        //alert(requestUrl);
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization': Token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch(deleteStudentsLoading(false));
            return response;
        })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(deleteStudentsSuccess(eventData));
                dispatch(deleteStudentsComplete(true));
            })
            .catch((err) => {
                dispatch(deleteStudentsFailed(err.message))
            });
    };

}

