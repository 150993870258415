import { combineReducers } from 'redux';
import {fetchlogin} from './Login/reducer';
import {fetchRegister} from "./Register/reducer";
import {fetchprices,fetchbuy} from "./Plan/reducer";
import {updateProfileData,fetchProfileData,fetchGetTransaction,ChangepasswordData,generateAddressData,uploadLogoImageData,registerTeamUserData} from "./Profile/reducer";
import {fetchApiData} from './ForgetPassword/reducer';
import {fetchStudentsData,fetchCohortsData,fetchSendInvitation,fetchUploadStudents,copyemailaspk, reducerupdatestudentinformation,DeleteStudentsData} from './ManageStudents/reducer';
import {GenerateCertificate,PreviewCertificate,IssueCertificate,RevokeCertificate,SendCertificate, SendCertificateforReview,IssueCertificateDaemon,IssueConfirm,ValidateReducer,BakeOpenBadges,
     BatchProcess, ResetCertificate, UnholdStudents, PreviewStudentPhoto, DownloadPreviewImage} from './ManageCertificate/reducer';
import {fetchInsertStudents,fetchInviteStudents,fetchGetPublicKey,fetchIssue,fetchInsertScheduleDemo} from './GetStarted/reducer';
import {updateCertificateTemplate,fetchCertificateTemplate, GeneratePreviewCertificate, BakePreviewOpenBadges, GetLogoImage, GetSignatureImage, GetPartner} from './DesignCertificate/reducer';
import { getDashboardData } from './Dashboard/reducer';

export default combineReducers({
    generateAddressData,
    updateProfileData,
    fetchlogin,
    fetchRegister,
    fetchprices,
    fetchbuy,
    fetchProfileData,
    fetchGetTransaction,
    fetchApiData,
    ChangepasswordData,
    fetchStudentsData,
    fetchCohortsData,
    fetchSendInvitation,
    fetchUploadStudents,
    GenerateCertificate,
    IssueCertificate,
    PreviewCertificate,
    fetchInsertStudents,
    fetchInviteStudents,
    fetchGetPublicKey,
    fetchIssue,
    fetchInsertScheduleDemo,
    RevokeCertificate,
    SendCertificate,
    SendCertificateforReview,
    IssueCertificateDaemon,
    updateCertificateTemplate,
    fetchCertificateTemplate,
    uploadLogoImageData,
    IssueConfirm,
    registerTeamUserData,
    ValidateReducer,
    BakeOpenBadges,
    copyemailaspk,
    BatchProcess,
    ResetCertificate,
    reducerupdatestudentinformation,
    UnholdStudents,
    GeneratePreviewCertificate,
    BakePreviewOpenBadges,
    GetLogoImage,
    GetSignatureImage,
    GetPartner,
    getDashboardData,
    PreviewStudentPhoto,
    DeleteStudentsData,
    DownloadPreviewImage
});