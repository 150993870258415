import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UpdateProfileFetchData,GetProfileFetchData, GetTransactionFetchData,FetchData,GenerateAddressFetchData,UploadLogoData,RegisterTeamUserData} from '../../redux/Profile/actions';
import "./custom.css";
import { updateProfile,getProfile, getTransaction,CHANGEPASSWORD,generateaddress,uploadlogoimageurl,registerteamuserurl,updatepermission } from "../../utils/constants";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import {Alert,Button, Modal} from 'react-bootstrap';
import axios from 'axios';

class ApiExcelSelectionBox extends React.Component {      
    render() {    
      if(this.props.apiexcelselectindex==0) 
      {
          return (
          <div>
              
              <select                
                  onChange={this.props.handleApiExcel}
              >
                  <option value="excelmode" selected={"selected"}>Excel Mode</option><option value="apimode">Api Mode</option>
              </select>
              
          </div>
          );
      }    
      else 
      {
          return (
          <div>
              
              <select                
                  onChange={this.props.handleApiExcel}
              >                  
                  <option value="excelmode" >Excel Mode</option><option value="apimode" selected={"selected"}>Api Mode</option>
              </select>
              
          </div>
          );
      }
      
    }
  }

class SelectionBox extends React.Component {      
  render() {    
    if(this.props.certificateformatselectindex==0) 
    {
        return (
        <div>
            
            <select                
                onChange={this.props.handleOnHtmlOrPng}
            >
                <option value="html" selected={"selected"}>Html-based</option><option value="png">Png-based</option>
            </select>
            
        </div>
        );
    }    
    else 
    {
        return (
        <div>
            
            <select                
                onChange={this.props.handleOnHtmlOrPng}
            >
                <option value="html">Html-based</option><option value="png" selected={"selected"}>Png-based</option>
            </select>
            
        </div>
        );
    }
    
  }
}
class DownloadTypeSelectionBox extends React.Component {      
    render() {    
      if(this.props.downloadtypeselectindex==0) 
      {
          return (
          <div>
              
              <select                
                  onChange={this.props.handleOnDownloadType}
              >
                  <option value="individualpdf" selected={"selected"}>Individual pdf</option><option value="consolidatedpdf">Consolidated pdf</option>
              </select>
              
          </div>
          );
      }    
      else 
      {
          return (
          <div>
              
              <select                
                  onChange={this.props.handleOnDownloadType}
              >                
                <option value="individualpdf">Individual pdf</option><option value="consolidatedpdf" selected={"selected"}>Consolidated pdf</option>
              </select>
              
          </div>
          );
      }
      
    }
  }
class S3PdfSelectionBox extends React.Component {      
  render() {    
    if(this.props.s3bucketselectindex==0) 
    {
        return (
        <div>            
            <select                
                onChange={this.props.handleOnS3OrPdf}
            >
                <option value="s3" selected={"selected"}>Save to S3</option><option value="pdf">Advanced pdf format</option>
            </select>
            
        </div>
        );
    }    
    else 
    {
        return (
        <div>
            
            <select                
                onChange={this.props.handleOnS3OrPdf}
            >
                <option value="s3">Save to S3</option><option value="pdf" selected={"selected"}>Advanced pdf format</option>
            </select>
            
        </div>
        );
    }
    
  }
}
class S3BucketSelectionBox extends React.Component {      
  render() {    
    if(this.props.certonces3infoselectindex==0) 
    {
        return (
        <div>
            
            <select                
                onChange={this.props.handleOnCertOnceS3OrCustomerS3}
            >
                <option selected={"selected"}>CertOnce S3</option><option>Customer S3</option>
            </select>
            
        </div>
        );
    }    
    else 
    {
        return (
        <div>
            
            <select                
                onChange={this.props.handleOnCertOnceS3OrCustomerS3}
            >
                <option >CertOnce S3</option><option selected={"selected"}>Customer S3</option>
            </select>
            
        </div>
        );
    }
    
  }
}
class MyProfileView extends Component {
    BlockchainList = [{'label' : '-Select-', 'value' : 'none'},{'label' : 'Bitcoin', 'value' : 'bitcoin'},{'label' : 'Ethereum', 'value' : 'ethereum'}];
    StorageType = [{'label' : 'S3 Bucket', 'value' : 's3bucket'}, {'label' : 'Azure', 'value' : 'azure'}, {'label' : 'GDrive', 'value' : 'gdrive'}, {'label' : 'FTP', 'value' : 'ftp'}, {'label': 'Local', 'value': 'local'}];
    smtpServiceType = [{'label' : 'GSMTP', 'value' : '0'}, {'label' : 'Office 365 SMTP', 'value' : '1'}];
    certonceVerson = [{'label' : 'Version 2', 'value' : 'v2'}, {'label' : 'Version 3', 'value' : 'v3'}];
    blockchainselectindex=0;
    blockchainselectedversion="v2";
    apiexcelselectindex=0;
    certificateformatselectindex=0;
    downloadtypeselectindex=0;
    s3bucketselectindex=0;
    certonces3infoselectindex=0;
    publickey="";
    storagetypeindex = 1;
    isoffice365index = 0;
    ipAddress = "";
    
    buttonstate="disabled";
    constructor(props)
    {
        super(props);
        this.state = {
            oldpassword:"",
            newpassword:"",
            confirmpassword:"",
            con: true,
            Profile: {
                created_at: "",
                email: "",
                fname: "",
                graduates_members: "",
                how_did_you_find_us: "",
                isverified: null,
                job_title: "",
                lname: "",
                organization_name: "",
                organization_url: ""
            },
            host:"",
            port:"",
            username:"",
            password:"",
            clientid:"",
            clientsecret:"",
            refreshtoken:"",
            from:"",
            cc:"",
            
            permissionChecked:{
                email:false,
                isadmin:false,
                issuer:false,
                viewonly:false,
                managemember:false,
                uploadmember:false,
                sendinvite:false,
                previewinviteemail:false,
                designcertificate:false,
                managecertificate:false,
                generatecertificate:false,
                previewcertificate:false,
                issuecertificate:false,
                sendcertificate:false,
                changelogo:false,
                changetemplatecontent:false,
                validate:false,
            },
            Transaction: [],
            is_parent:false,
            showAddUserModal: false,
            showPermissionModal: false,
            smtpOAuth: true,
            smtpPWD: false,           
        }
        
    this.GetProfile = this.GetProfile.bind(this);
    this.GenerateAddress = this.GenerateAddress.bind(this);
    this.GetTransaction = this.GetTransaction.bind(this);
    this.GetRecepit = this.GetRecepit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onHandleChange1 = this.onHandleChange1.bind(this);
    this.onHandleChange2 = this.onHandleChange2.bind(this);
    this.onHandleChange3 = this.onHandleChange3.bind(this);
    this.selectBlockchain = this.selectBlockchain.bind(this);
    this.selectVersion = this.selectVersion.bind(this);
    this.printAddress = this.printAddress.bind(this);
    this.closeAddUserModal = this.closeAddUserModal.bind(this);
    this.closePermissionModal = this.closePermissionModal.bind(this);
    this.addUserAction=this.addUserAction.bind(this);
    this.updatePermission=this.updatePermission.bind(this);
    this.GetProfile();
}

selectBlockchain = (e) => {
    let idx = e.target.selectedIndex;    
    if(idx==0) return;
    this.setState({ con: true });
    this.blockchainselectindex=idx;
    let value = e.target.options[idx].value;       
    let payload = {
        key : "blockchain",
        value: value,
        step: "update",
        ipaddress: this.ipAddress
    };
    
    //this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
    //alert("Please signin again.");
    //localStorage.clear();
    //this.props.history.push('/');

}

selectVersion = (e) => {
    let idx = e.target.selectedIndex;
    this.setState({ con: true });
    this.blockchainselectedversion=idx;
    let value = e.target.options[idx].value;       
    
    let payload = {
        key : "certonceversion",
        value: value        
    };
    
    
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
   
}

handleApiExcel = (e) =>{
    let idx = e.target.selectedIndex;    
    let isapimode=false;
    if(idx!==0) isapimode=true;
    this.setState({ con: true });
    let payload = {
        key : "isapimode",
        isapimode:isapimode,
        ipaddress: this.ipAddress
    };
    console.log(payload);
    //this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
handleOnHtmlOrPng = (e) =>{
    let idx = e.target.selectedIndex;    
    let ishtml=true;
    if(idx!==0) ishtml=false;
    this.setState({ con: true });
    let payload = {
        key : "ishtml",
        ishtml:ishtml,
        ipaddress: this.ipAddress
    };
    console.log(payload);
    //this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
handleOnDownloadType = (e) =>{
    let idx = e.target.selectedIndex;       
    this.setState({ con: true });
    let payload = {
        key : "downloadtype",
        downloadtype:idx,
        ipaddress: this.ipAddress
    };
    console.log(payload);
    //this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
handleOnS3OrPdf = (e) =>{
    let idx = e.target.selectedIndex;    
    let iss3=true;
    if(idx!==0) iss3=false;
    this.setState({ con: true });
    let payload = {
        key : "iss3",
        iss3:iss3,
        ipaddress: this.ipAddress
    };
    console.log(payload);
    //this.props.dispatch(GetStudentsFetchData(getStudentsForStep, payload));
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
handleOnCertOnceS3OrCustomerS3 = (e) =>{
    let idx = e.target.selectedIndex;       
    if(idx==0)
    {
        document.getElementById("awsaccesskeyid").value="";
        document.getElementById("awsaccesskeyid").disabled=true;

        document.getElementById("awssecretkeyid").value="";
        document.getElementById("awssecretkeyid").disabled=true;

        document.getElementById("bucketnameid").value="";
        document.getElementById("bucketnameid").disabled=true;

        document.getElementById("regionid").value="";
        document.getElementById("regionid").disabled=true;

        this.certonces3infoselectindex=0;
    }
    else
    {            
        document.getElementById("awsaccesskeyid").disabled=false;            
        document.getElementById("awssecretkeyid").disabled=false;            
        document.getElementById("bucketnameid").disabled=false;            
        document.getElementById("regionid").disabled=false;
        this.certonces3infoselectindex=1;
    }
    
    console.log("idx="+idx+", certonces3infoselectindex="+this.certonces3infoselectindex);
}

onHandleChange1(event)
{
    //console.log(event)
    this.setState({ oldpassword:event.target.value})
}
onHandleChange2(event)
{
    //console.log(event)
    this.setState({ newpassword:event.target.value})
}
onHandleChange3(event)
{
    //console.log(event)
    this.setState({ confirmpassword:event.target.value})
}

changeSmtpServiceType = (e) => {
    let idx = e.target.selectedIndex;
    if (idx == 0) {
        document.getElementById("gsmtpid").style.display = "";
        document.getElementById("office365id").style.display = "none";        
        this.setState({isoffice365: idx});
    }
    else if (idx == 1){
        document.getElementById("gsmtpid").style.display = "none";
        document.getElementById("office365id").style.display = "";     
        this.setState({isoffice365: idx});
    }
    
}

saveSmtp(event)
{   
    this.setState({ con: true });
    if(this.state.isoffice365==0)
    {
        if(this.state.smtpOAuth){
            let name = document.getElementById("smtpusername").value;
            let clientid = document.getElementById("smtpclientid").value;
            let clientsecret = document.getElementById("smtpclientsecret").value;
            let refreshtoken = document.getElementById("smtprefreshtoken").value;
            if(name=="" || clientid == "" || clientsecret =="" || refreshtoken ==""){
                var payload = {
                    key : "smtp",
                    type : "oauth",
                    smtphost:this.state.host,
                    smtpport:this.state.port,
                    smtpclientid:"",
                    smtpclientsecret:"",
                    smtprefreshtoken:"",
                    smtpusername:"",
                    smtppassword:this.state.password,
                    smtpfrom:document.getElementById("smtpfrom").value,
                    smtpcc:document.getElementById("smtpcc").value,
                    ipaddress: this.ipAddress
                };
                this.props.dispatch(UpdateProfileFetchData(updateProfile, payload)); 
            }
            // else if(name=="" || clientid == "" || clientsecret =="" || refreshtoken ==""){
            //     ToastsStore.error("Please fill the SMTP Informations correctly.");                                     
            //     this.setState({ con: false }); 
            // }
            else{
                var payload = {
                    key : "smtp",
                    type : "oauth",
                    smtphost:this.state.host,
                    smtpport:this.state.port,
                    smtpclientid:clientid,
                    smtpclientsecret:clientsecret,
                    smtprefreshtoken:refreshtoken,
                    smtpusername:name,
                    smtppassword:this.state.password,
                    smtpfrom:document.getElementById("smtpfrom").value,
                    smtpcc:document.getElementById("smtpcc").value,
                    ipaddress: this.ipAddress
                }; 
                this.props.dispatch(UpdateProfileFetchData(updateProfile, payload)); 
            }               
        }
        else if(this.state.smtpPWD){
            let host = document.getElementById("smtphost").value;
            let port = document.getElementById("smtpport").value;
            let name = document.getElementById("smtpusername").value;
            let pwd = document.getElementById("smtppassword").value;
            if(host=="" || port=="" || name=="" || pwd ==""){
                var payload = {
                    key : "smtp",
                    type : "pwd",
                    smtphost:"",
                    smtpport:"",
                    smtpusername:"",
                    smtppassword:"",
                    smtpclientid:this.state.clientid,
                    smtpclientsecret:this.state.clientsecret,
                    smtprefreshtoken:this.state.refreshtoken,
                    smtpfrom:document.getElementById("smtpfrom").value,
                    smtpcc:document.getElementById("smtpcc").value,
                    ipaddress: this.ipAddress
                };
                this.props.dispatch(UpdateProfileFetchData(updateProfile, payload)); 
            }
            // else if(host=="" || port=="" || name=="" || pwd ==""){
            //     ToastsStore.error("Please fill the SMTP Informations correctly.");                                     
            //     this.setState({ con: false }); 
            // }
            else{
                var payload = {
                    key : "smtp",
                    type : "pwd",
                    smtphost:host,
                    smtpport:port,
                    smtpusername:name,
                    smtppassword:pwd,
                    smtpclientid:this.state.clientid,
                    smtpclientsecret:this.state.clientsecret,
                    smtprefreshtoken:this.state.refreshtoken,
                    smtpfrom:document.getElementById("smtpfrom").value,
                    smtpcc:document.getElementById("smtpcc").value,
                    ipaddress: this.ipAddress
                };
                this.props.dispatch(UpdateProfileFetchData(updateProfile, payload)); 
            }        
        }
    }
    else if(this.state.isoffice365==1)
    {
        let office365clientid = document.getElementById("office365clientid").value;
        let office365clientsecret = document.getElementById("office365clientsecret").value;
        let office365tanentid = document.getElementById("office365tanentid").value;
        let office365accesstoken = document.getElementById("office365accesstoken").value;
        let office365refreshtoken = document.getElementById("office365refreshtoken").value;
        let from = document.getElementById("office365from").value;
        let cc = document.getElementById("office365cc").value;
        var payload = {
            key : "office365",            
            office365clientid:office365clientid,
            office365clientsecret:office365clientsecret,
            office365tanentid:office365tanentid,
            office365accesstoken:office365accesstoken,
            office365refreshtoken:office365refreshtoken,
            isoffice365:this.state.isoffice365,            
            smtpfrom:from,
            smtpcc:cc,
            ipaddress: this.ipAddress
        }; 

        if(office365clientid.trim()=="" || office365clientsecret.trim()=="" || office365tanentid.trim()=="")
        {
            ToastsStore.error("Please fill requred input box");  
            this.setState({ con: false });   
            return;
        }

        // if(office365accesstoken.trim()=="" || office365refreshtoken.trim()=="")
        // {
        //     ToastsStore.error("Please press getToken button and refresh again until get the tokens");     
        //     return;
        // }
                                        
        
        this.props.dispatch(UpdateProfileFetchData(updateProfile, payload)); 
    }          
}
getToken(event)
{
    let text = "Please try this function after press Save button first.\nDid you press save button first?";
  if (window.confirm(text) == true) {
    console.log('document.getElementById("office365clientid").value=',document.getElementById("office365clientid").value)
    let office365clientid = document.getElementById("office365clientid").value;
    let office365clientsecret = document.getElementById("office365clientsecret").value;
    let office365tanentid = document.getElementById("office365tanentid").value;
    let redirectUri = encodeURIComponent('https://www.certonce.com:5000/api/getOffice365Token');
   
    if(this.state.office365gettokenurl!==null && this.state.office365gettokenurl!=="") redirectUri = encodeURIComponent(`${this.state.office365gettokenurl}`);
    //alert(redirectUri);
    let scope = encodeURIComponent('https://graph.microsoft.com/Mail.Send');
    let api_key = encodeURIComponent(this.state.apikey);
    
    if(office365clientid.trim()=="")
    {
        ToastsStore.error("Please fill clientid input box");     
        return;
    }
    if(office365clientsecret.trim()=="")
    {
        ToastsStore.error("Please fill clientsecret input box");     
        return;
    }
    if(office365tanentid.trim()=="")
    {
        ToastsStore.error("Please fill tanentid input box");     
        return;
    }
    let authUrl = `https://login.microsoftonline.com/${office365tanentid}/oauth2/v2.0/authorize?client_id=${office365clientid}&response_type=code&redirect_uri=${redirectUri}&scope=${scope}%20offline_access&state=${api_key}`;
    console.log("authUrl=",authUrl);
    window.open(authUrl, '_blank');

  } else {
    console.log("canceled");
  }
    
}
saveS3Info(event)
{
    let iscertonceorcustomer="certonce";
    if(this.certonces3infoselectindex==1) iscertonceorcustomer="customer";
    
    if(iscertonceorcustomer=="customer")
    {
        if(document.getElementById("awsaccesskeyid").value==="")
        { 
            alert("Aws accesskey is required");
            document.getElementById("awsaccesskeyid").focus();
            return;
        }
        if(document.getElementById("awssecretkeyid").value==="")
        {               
            alert("Aws secretkey is required");
            document.getElementById("awssecretkeyid").focus();
            return;
        }
        if(document.getElementById("bucketnameid").value==="")
        { 
            alert("S3 Bucket name is required");
            document.getElementById("bucketnameid").focus();
            return;                
        }
        if(document.getElementById("regionid").value==="")
        { 
            alert("Aws region is required");
            document.getElementById("regionid").focus();
            return;      
        }
    }

    this.setState({ con: true });
    let payload = {
        key : "s3information",
        iscertonceorcustomer:iscertonceorcustomer,
        awsaccesskeyid:document.getElementById("awsaccesskeyid").value,
        awssecretkeyid:document.getElementById("awssecretkeyid").value,
        s3bucketnameid:document.getElementById("bucketnameid").value,
        awsregionid:document.getElementById("regionid").value,
    };
    if(iscertonceorcustomer=="certonce")
    {
        payload = {
            key : "s3information",
            iscertonceorcustomer:iscertonceorcustomer,
            awsaccesskeyid:"",
            awssecretkeyid:"",
            s3bucketnameid:"",
            awsregionid:"",
            ipaddress: this.ipAddress
        };
    }
    console.log(payload)        
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}

saveAzureInfo(event)
{
    this.setState({ con: true });
    let data = document.getElementById("azureconnectionstring").value;
    if (data == ""){
        ToastsStore.error("Please enter Azure Connection String.");                                     
        this.setState({ con: false });
    }
    else {
        let payload = {
            key : "azureinformation",        
            connectionstring: data,
            ipaddress: this.ipAddress
        };
        
        console.log(payload)        
        this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
    }    
}

saveGDriveInfo(event)
{
    this.setState({ con: true });
    let cred = document.getElementById("gdrivecredential").value;
    let token = document.getElementById("gdrivetoken").value;
    if (cred == "" || token == ""){
        ToastsStore.error("Both crdential and token have to has value.");                                     
        this.setState({ con: false });
    }
    else {
        let payload = {
            key : "gdriveinformation",        
            credential: cred,
            token: token,
            ipaddress: this.ipAddress
        };
        
        console.log(payload)        
        this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
    }
    
}

saveFtpInfo(event)
{
    this.setState({ con: true });
    let host = document.getElementById("ftphost").value;
    let username = document.getElementById("ftpuser").value;
    let pwd = document.getElementById("ftppassword").value;
    if (host == "" || username == "" || pwd == ""){
        ToastsStore.error("Please enter correct FTP account.");                                     
        this.setState({ con: false });
    }
    else {
        let payload = {
            key : "ftpinformation",        
            host: host,
            user: username,
            pwd: pwd,
            ipaddress: this.ipAddress
        };
        
        console.log(payload)        
        this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
    }
    
}

saveLocalInfo(event)
{
    this.setState({ con: true });
    let localpath = document.getElementById("localpath").value;    
    if (localpath == ""){
        ToastsStore.error("Please enter Local path.");                                     
        this.setState({ con: false });
    }
    else {
        let payload = {
            key : "localinformation",        
            path: localpath,
            ipaddress: this.ipAddress
        };
        
        console.log(payload)        
        this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
    }
    
}

getUrlWithoutLastSlash(url)     
{     
    return url.replace(/\/+$/, "");
}

saveIssuerInfo(event)
{
    
    this.setState({ con: true });
    let payload = {
        key : "issuerinformation",            
        baseurlid:this.getUrlWithoutLastSlash(document.getElementById("baseurlid").value),
        issuerurlid:this.getUrlWithoutLastSlash(document.getElementById("issuerurlid").value),
        revocationurlid:this.getUrlWithoutLastSlash(document.getElementById("revocationurlid").value),
        issuernameid:document.getElementById("issuernameid").value,
        issueremailid:document.getElementById("issueremailid").value,
        verifybaseurl:this.getUrlWithoutLastSlash(document.getElementById("verifybaseurlid").value),
        ipaddress: this.ipAddress
    };
    
    console.log(payload)        
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
saveGasInfo(event)
{
    
    this.setState({ con: true });
    let payload = {
        key : "gasinformation",            
        gaspriceid:document.getElementById("gaspriceid").value,
        ipaddress: this.ipAddress
    };
    
    console.log(payload)        
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
saveContactInfo(event)
{
    
    this.setState({ con: true });
    let payload = {
        key : "contactinformation",            
        contactemailid:document.getElementById("contactemailid").value,
        ipaddress: this.ipAddress
    };
    
    console.log(payload)        
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
saveStripeInfo(event)
{
    
    this.setState({ con: true });
    let payload = {
        key : "stripeinformation",            
        stripepublickey:document.getElementById("stripepublickeyid").value,
        stripesecretkey:document.getElementById("stripesecretkeyid").value,
        ipaddress: this.ipAddress
    };
    
    console.log(payload)        
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));
}
handleSubmit(event)
{
    event.preventDefault();

    var payload = {
        OldPassword:event.target.oldpassword.value,
        NewPassword:event.target.newpassword.value,
        ConfirmPassword:event.target.confirmpassword.value,
        loginstep:"no",
        ipaddress: this.ipAddress
    }

    if(!payload.OldPassword || !payload.NewPassword || !payload.ConfirmPassword)
    {

        ToastsStore.error("Please enter all fields");
        return null;
    }
    else{

        if(payload.NewPassword == payload.ConfirmPassword)
        {

        }
        else{
            ToastsStore.error("new password and confirm did not matched");
            return null;
        }
        
    }
    //console.log(payload);
    let url = CHANGEPASSWORD;
    this.setState({ con: true });
    this.setState({ loadercheck: true });
    this.props.dispatch(FetchData(url,payload));
    

}

GetButton() {
    if(this.publickey==""||this.publickey==null)
    {            
        return (            
        <button className="btn btn-info"  onClick={()=>this.GenerateAddress()}>Generate</button>
        )
    }
    return (            
        <button className="btn btn-info" disabled  onClick={()=>this.GenerateAddress()}>Generate</button>
    )
}

GetPublicKeyQr() {
    var src='https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl='+this.publickey;
    if(this.publickey==""||this.publickey==null)
    {
        return "";
    }
    return (
        
        <img src={src} title="" ></img>
    )
}

GetProfile() {
    //console.log("GetProfile")
    this.setState({ con: true });
    this.props.dispatch(GetProfileFetchData(getProfile));
}
GenerateAddress() {
    //console.log(generateaddress);
            
    let value = this.BlockchainList[this.blockchainselectindex].value;
    if(this.blockchainselectindex==0)
    {
        alert("Please select blockchain type");
        return;
    }
    else
    {
        this.setState({ con: true }); 
        let payload = {
            key : "blockchain",
            value: value,
            step: "update"
        };
        this.props.dispatch(GenerateAddressFetchData(generateaddress,payload));
    }
}
GetTransaction() {
    //console.log("GetTransaction")
    this.setState({ con: true });
    this.props.dispatch(GetTransactionFetchData(getTransaction));
}
GetRecepit(url) {

    window.open(url, "blockcertficates Receipt", 'height=600,width=700')

}
printAddress() {
    
    var divToPrint=document.getElementById("bitcoinaddressdiv");        
    var win = window.open('', '');
    win.document.write('<style>@page{size:landscape;}</style><html><head><title></title>');
    win.document.write('</head><body ><center>');
    win.document.write(divToPrint.outerHTML);
    win.document.write('</center></body></html>');
    win.print();
    win.close();
}

uploadLogo =e=> 
{
    e.preventDefault();
    const main_this=this;
    this.setState({ con: true });  
    if(e.target.files[0])
    {
        var filesize=e.target.files[0].size/1024/1024
        /*if(filesize<1||filesize>4)
        {
        alert("Min image file size is 1MB and max file size is 4MB");
        return ;
        }
        else*/
        {
            var reader = new FileReader();
            reader.onload = function (e) {            
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;            
                    var width = this.width;
                    
                    /*if (height < 100 || width < 100||height > 400 || width > 400) {
                        alert("Min image size is 100 width,100 height and maxsize is 400 width,400 height");
                        return ;
                    }
                    else*/
                    {
                        document.getElementById("logoimage").style.width="200px";
                        document.getElementById("logoimage").style.height="100px";
                        document.getElementById("logoimage").src = e.target.result;   
                        
                        let payload = {                                
                            value: e.target.result,                                
                        };
                        main_this.props.dispatch(UploadLogoData(uploadlogoimageurl,payload));
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);        
        
        }
    }  
}
uploadLogoImage =e=> {
    e.preventDefault();
    if(e.target.files[0])
    {
        var filesize=e.target.files[0].size/1024/1024
        
        if(filesize>2)
        {
           alert("Min image file size is 1MB and max file size is 4MB");
           return ;
        }
        else
        {
            var reader = new FileReader();
            reader.onload = function (e) {            
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;            
                    var width = this.width;
                    
                   /* if (height < 100 || width < 100||height > 400 || width > 400) {
                        alert("Min image size is 100 width,100 height and maxsize is 400 width,400 height");
                        return ;
                    }
                    else*/
                    {
                        document.getElementById("plogoimageid").src = e.target.result;
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);        
        }
    }  
}
uploadSignatureImage =e=> {
    e.preventDefault();
    if(e.target.files[0])
    {
        var filesize=e.target.files[0].size/1024/1024
        
        if(filesize>2)
        {
           alert("Min image file size is 1MB and max file size is 4MB");
           return ;
        }
        else
        {
            var reader = new FileReader();
            reader.onload = function (e) {            
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;            
                    var width = this.width;
                    
                   /* if (height < 100 || width < 100||height > 400 || width > 400) {
                        alert("Min image size is 100 width,100 height and maxsize is 400 width,400 height");
                        return ;
                    }
                    else*/
                    {
                        document.getElementById("psignatureimageid").src = e.target.result;
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);        
        }
    }  
}
uploadSecondLogoImage =e=> {
    e.preventDefault();
    if(e.target.files[0])
    {
        var filesize=e.target.files[0].size/1024/1024
        
        if(filesize>2)
        {
           alert("Min image file size is 1MB and max file size is 4MB");
           return ;
        }
        else
        {
            var reader = new FileReader();
            reader.onload = function (e) {            
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;            
                    var width = this.width;
                    
                   /* if (height < 100 || width < 100||height > 400 || width > 400) {
                        alert("Min image size is 100 width,100 height and maxsize is 400 width,400 height");
                        return ;
                    }
                    else*/
                    {
                        document.getElementById("psecondlogoimageid").src = e.target.result;
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);        
        }
    }  
}
uploadSecondSignatureImage =e=> {
    e.preventDefault();
    if(e.target.files[0])
    {
        var filesize=e.target.files[0].size/1024/1024
        
        if(filesize>2)
        {
           alert("Min image file size is 1MB and max file size is 4MB");
           return ;
        }
        else
        {
            var reader = new FileReader();
            reader.onload = function (e) {            
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;            
                    var width = this.width;
                    
                   /* if (height < 100 || width < 100||height > 400 || width > 400) {
                        alert("Min image size is 100 width,100 height and maxsize is 400 width,400 height");
                        return ;
                    }
                    else*/
                    {
                        document.getElementById("psecondsignatureimageid").src = e.target.result;
                    }
                };
            };
            reader.readAsDataURL(e.target.files[0]);        
        }
    }  
}
saveCompetencyPartnerInfo(event)
{
    if(document.getElementById("competencynameid").value=="")
    {
        alert("Competency name is required!");
        document.getElementById("competencynameid").focus();
        return;
    }
    this.setState({ con: true });
    let payload = {
        key : "competencypartnerinformation",            
        competencynameid:document.getElementById("competencynameid").value,
        customernameid:document.getElementById("customernameid").value,
        signaturenameid:document.getElementById("signaturenameid").value,
        designernameid:document.getElementById("designernameid").value,
        partnercompanynameid:document.getElementById("partnercompanynameid").value,
        // plogoimageid:document.getElementById("plogoimageid").src,
        plogoimageid:document.getElementById("plogoimageid").value,
        psignatureimageid:document.getElementById("psignatureimageid").value,
        secondsignameid:document.getElementById("secondsignameid").value,
        seconddesignernameid:document.getElementById("seconddesignernameid").value,
        secondpartnercompanynameid:document.getElementById("secondpartnercompanynameid").value,
        psecondlogoimageid:document.getElementById("psecondlogoimageid").value,
        psecondsignatureimageid:document.getElementById("psecondsignatureimageid").value,
        thirdsignameid:document.getElementById("thirdsignameid").value,
        thirddesignernameid:document.getElementById("thirddesignernameid").value,
        thirdpartnercompanynameid:document.getElementById("thirdpartnercompanynameid").value,
        pthirdlogoimageid:document.getElementById("pthirdlogoimageid").value,
        pthirdsignatureimageid:document.getElementById("pthirdsignatureimageid").value,
        ipaddress: this.ipAddress
    };
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));    
}

savePartnerCompanyInfo(event)
{    
    this.setState({ con: true });
    let payload = {
        key : "partnercompanyinformation",            
        signaturenameid:document.getElementById("signaturenameid").value,
        designernameid:document.getElementById("designernameid").value,
        partnercompanynameid:document.getElementById("partnercompanynameid").value,
        partnercompanynamefordisplayid:document.getElementById("partnercompanynamefordisplayid").value,
        plogoimageid:document.getElementById("plogoimageid").value,
        psignatureimageid:document.getElementById("psignatureimageid").value,
        ipaddress: this.ipAddress
    };
    this.props.dispatch(UpdateProfileFetchData(updateProfile, payload));    
}

componentDidUpdate(prevProps, prevState) {
    if (prevProps.generatedaddress) {
        if (prevProps.generatedaddress.Status === 200) {                
            this.publickey=prevProps.generatedaddress.Data.publickey;                     
            this.setState({ con: false });            

        }
        else
        {
            ToastsStore.error(prevProps.generatedaddress.message);                                     
            this.setState({ con: false }); 
        }            
        
    }
    if (prevProps.UpdateProfile) {
        if (prevProps.UpdateProfile.Status === 200) {
            try {
                this.publickey=prevProps.UpdateProfile.Data.publickey;  
                this.setState({
                    host: prevProps.UpdateProfile.Data.smtphost,
                    port: prevProps.UpdateProfile.Data.smtpport,
                    username: prevProps.UpdateProfile.Data.smtpusername,
                    password: prevProps.UpdateProfile.Data.smtppassword,
                    clientid: prevProps.UpdateProfile.Data.smtpclientid,
                    clientsecret: prevProps.UpdateProfile.Data.smtpclientsecret,
                    refreshtoken: prevProps.UpdateProfile.Data.smtprefreshtoken,
                    from: prevProps.UpdateProfile.Data.smtpfrom,
                    cc: prevProps.UpdateProfile.Data.smtpcc
                })

            } catch (error) {
                
            }            
            this.setState({ con: false });                                
            ToastsStore.success(prevProps.UpdateProfile.message);                     
        }
        else
        {
            ToastsStore.error(prevProps.UpdateProfile.message);                                     
            this.setState({ con: false }); 
        }            
        
    }
    
    if (prevProps.UploadLogoProfile) {
        if (prevProps.UploadLogoProfile.Status === 200) {
            this.setState({ con: false }); 
            ToastsStore.success(prevProps.UploadLogoProfile.message);                                     
        }
        else
        {
            ToastsStore.error(prevProps.UploadLogoProfile.message);                                     
            this.setState({ con: false }); 
            
        }                 
    }
    if (prevProps.Profile) {
        if (prevProps.Profile.Status === 200) {
            this.setState({ con: false });
            this.setState({ Profile: prevProps.Profile.Data });
            if(prevProps.Profile.Data.blockchain=="bitcoin")
            {
                this.blockchainselectindex=1;
            }
            else if(prevProps.Profile.Data.blockchain=="ethereum")
            {
                this.blockchainselectindex=2;
            }
            
            if(prevProps.Profile.Data.ishtml==true)
            {
                this.certificateformatselectindex=0;
            }
            else
            {
                this.certificateformatselectindex=1;
            }

            this.downloadtypeselectindex = prevProps.Profile.Data.downloadmultiplefile;
            
            if(prevProps.Profile.Data.isapimode==false)
            {
                this.apiexcelselectindex=0;
            }
            else
            {
                this.apiexcelselectindex=1;
            }
            
            if(prevProps.Profile.Data.iss3==true)
            {
                this.s3bucketselectindex=0;
            }
            else
            {
                this.s3bucketselectindex=1;
            }

            if(prevProps.Profile.Data.certonceorcustomer=="customer")
            {
                this.certonces3infoselectindex=1;
            }
            else
            {
                this.certonces3infoselectindex=0;               
                document.getElementById("awsaccesskeyid").value="";
                document.getElementById("awsaccesskeyid").disabled=true;

                document.getElementById("awssecretkeyid").value="";
                document.getElementById("awssecretkeyid").disabled=true;

                document.getElementById("bucketnameid").value="";
                document.getElementById("bucketnameid").disabled=true;

                document.getElementById("regionid").value="";
                document.getElementById("regionid").disabled=true;

           
            }

            this.publickey=prevProps.Profile.Data.publickey; 
            // console.log(this.publickey)  ;
            // if(this.publickey ==''){
            //     this.blockchainselectindex = 0;
            // }
            // else if(this.publickey.indexOf("0x")!=-1){
            //     this.blockchainselectindex = 2;
            // }
            // else if(this.publickey.indexOf("0x")==-1){
            //     this.blockchainselectindex = 1;
            // }
            if(prevProps.Profile.Data.isadmin==true)
            {             
                this.setState({ is_parent: true});
            }
            else{
                this.setState({ is_parent: false });
            }
            if(prevProps.Profile.Data.smtptype==true) this.setState({smtpOAuth: true, smtpPWD: false});
            else if(prevProps.Profile.Data.smtptype==false) this.setState({smtpOAuth: false, smtpPWD: true});

            this.setState({host:prevProps.Profile.Data.smtphost,port:prevProps.Profile.Data.smtpport,
                clientid:prevProps.Profile.Data.smtpclientid,clientsecret:prevProps.Profile.Data.smtpclientsecret,refreshtoken:prevProps.Profile.Data.smtprefreshtoken,
                username:prevProps.Profile.Data.smtpusername,password:prevProps.Profile.Data.smtppassword,from:prevProps.Profile.Data.smtpfrom,cc:prevProps.Profile.Data.smtpcc,
                awsaccesskey:prevProps.Profile.Data.awsaccesskey,
                awssecretkey:prevProps.Profile.Data.awssecretkey,
                bucketname:prevProps.Profile.Data.s3bucketname,
                region:prevProps.Profile.Data.awsregion,
                azureconnectionstring:prevProps.Profile.Data.azureConnectionString,
                gdrivecredential:prevProps.Profile.Data.gdriveCredential,
                gdrivetoken:prevProps.Profile.Data.gdriveToken,
                ftphost: prevProps.Profile.Data.ftphost,
                ftpuser: prevProps.Profile.Data.ftpuser,
                ftppassword: prevProps.Profile.Data.ftppassword,
                localpath: prevProps.Profile.Data.localpath,
                storagetype: prevProps.Profile.Data.storagetype,
                isoffice365: prevProps.Profile.Data.isoffice365,
                office365clientid: prevProps.Profile.Data.office365clientid,
                office365clientsecret: prevProps.Profile.Data.office365clientsecret,
                office365tanentid: prevProps.Profile.Data.office365tanentid,
                office365accesstoken: prevProps.Profile.Data.office365accesstoken,
                office365refreshtoken: prevProps.Profile.Data.office365refreshtoken,
                baseurl:prevProps.Profile.Data.baseurl,
                issuerurl:prevProps.Profile.Data.issuerurl,
                revocationurl:prevProps.Profile.Data.revocationurl,
                issuername:prevProps.Profile.Data.issuername,
                issueremail:prevProps.Profile.Data.issueremail,
                gasprice:prevProps.Profile.Data.gasprice,
                verifybaseurl:prevProps.Profile.Data.verifybaseurl,
                apikey:prevProps.Profile.Data.apikey,
                contactemail:prevProps.Profile.Data.contactemail,
                stripepublickey:prevProps.Profile.Data.stripepublickey,
                stripesecretkey:prevProps.Profile.Data.stripesecretkey,
                stripecertonceapikey:prevProps.Profile.Data.stripecertonceapikey,
                certonceversion:prevProps.Profile.Data.certonceversion,
                office365gettokenurl:prevProps.Profile.Data.office365gettokenurl,
            });
            // if (this.state.storagetype == 1) {
            //     document.getElementById("s3bucket_block").style.display = "block";
            //     document.getElementById("s3bucketbutton").style.display = "block"
            // }
            // else if (this.state.storagetype == 2){
    
            // }
            // else if (this.state.storagetype == 3){
    
            // }
            // else if (this.state.storagetype == 3){
                
            // }
            
        }
        else{
            ToastsStore.error(prevProps.Profile.message);                                     
            this.setState({ con: false }); 
        }
        // console.log(this.publickey);
    }
    if (prevProps.Transaction) {
        if (prevProps.Transaction.Status === 200) {                
            this.setState({ con: false });
            this.setState({ Transaction: prevProps.Transaction.Data });
            //console.log(this.state.is_parent);
        }
        else
        {
            this.setState({ con: false });
            ToastsStore.error(prevProps.Transaction.message);
        }
    }
    if (prevProps.RegisterTeamUserResult) {
        if (prevProps.RegisterTeamUserResult.Status === 200) { 
            this.props.dispatch(GetTransactionFetchData(getTransaction));               
            this.setState({ con: false,showAddUserModal:false,showPermissionModal:false});                
            ToastsStore.success(prevProps.RegisterTeamUserResult.message);
        }
        else
        {
            this.setState({ con: false,showAddUserModal:false,showPermissionModal:false});
            ToastsStore.error(prevProps.RegisterTeamUserResult.message);
        }
    }
 
}
addUser()
{
    this.setState({showAddUserModal:true});
}
addUserAction()
{    
    var firstname=document.getElementById("firstnameid").value;
    var lastname=document.getElementById("lastnameid").value;
    var email=document.getElementById("emailid").value;
    var password=document.getElementById("passwordid").value;
    var confirmpassword=document.getElementById("confirmpasswordid").value;
    var job_title=document.getElementById("job_titleid").value;
    if(firstname==="")
    {
        alert("Please input first name.");
        return;
    }
    if(lastname==="")
    {
        alert("Please input last name.");
        return;
    }
    if(email==="")
    {
        alert("Please input email.");
        return;
    }
    if(password==="")
    {
        alert("Please input password.");
        return;
    }
    if(confirmpassword==="")
    {
        alert("Please input confirm password.");
        return;
    }
    if(password!==confirmpassword)
    {
        alert("Password and confirmation password do not match.");
        return;
    }
    if(job_title==="")
    {
        alert("Please select job_title.");
        return;
    }
    
    this.setState({ con: true }); 
    let payload = {
        firstname : firstname,
        lastname: lastname,
        email: email,
        password: password,        
        job_title: job_title,
    };
    this.props.dispatch(RegisterTeamUserData(registerteamuserurl,payload));
    
}
updatePermission()
{
    this.setState({ con: true }); 
    var c_permission=this.state.permissionChecked;      
    this.props.dispatch(RegisterTeamUserData(updatepermission,c_permission));
}
editPermission(pemail)
{
    
    for(var index =0;index<this.state.Transaction.length;index++)
    {
           if(this.state.Transaction[index].email==pemail)
           {
              
              var permission_set={
                email:this.state.Transaction[index].email,
                isadmin:this.state.Transaction[index].isadmin,
                issuer:this.state.Transaction[index].issuer,
                viewonly:this.state.Transaction[index].viewonly,
                managemember:this.state.Transaction[index].managemember,
                uploadmember:this.state.Transaction[index].uploadmember,
                sendinvite:this.state.Transaction[index].sendinvite,
                previewinviteemail:this.state.Transaction[index].previewinviteemail,
                designcertificate:this.state.Transaction[index].designcertificate,
                managecertificate:this.state.Transaction[index].managecertificate,
                generatecertificate:this.state.Transaction[index].generatecertificate,
                previewcertificate:this.state.Transaction[index].previewcertificate,
                issuecertificate:this.state.Transaction[index].issuecertificate,
                sendcertificate:this.state.Transaction[index].sendcertificate,
                changelogo:this.state.Transaction[index].changelogo,
                changetemplatecontent:this.state.Transaction[index].changetemplatecontent,
                validate:this.state.Transaction[index].validate,                
            }
            
            this.setState({permissionChecked:permission_set,showPermissionModal:true});
           }
    }
}
changePermission(statename,newstate)
{
    var c_permission=this.state.permissionChecked;
    if(statename=="isadmin") c_permission.isadmin=!newstate;
    if(statename=="issuer") 
    {
        c_permission.issuer=!newstate;
        
        if(c_permission.issuer==true)
        {
           c_permission.viewonly=false;
           c_permission.generatecertificate=true;
           c_permission.previewcertificate=true;
           c_permission.issuecertificate=true;
        }
        else
        {
            
           //c_permission.viewonly=true; 
           c_permission.generatecertificate=false;
           c_permission.previewcertificate=false;
           c_permission.issuecertificate=false;
           
        }
    }
    if(statename=="viewonly")
    {
        c_permission.viewonly=!newstate;
        if(c_permission.viewonly==true)
        {
         c_permission.issuer=false;
         c_permission.generatecertificate=false;
         c_permission.previewcertificate=false;
         c_permission.issuecertificate=false;
        }
    }
    if(statename=="managemember") c_permission.managemember=!newstate;
    if(statename=="uploadmember") c_permission.uploadmember=!newstate;
    if(statename=="sendinvite") c_permission.sendinvite=!newstate;
    if(statename=="previewinviteemail") c_permission.previewinviteemail=!newstate;
    if(statename=="designcertificate") c_permission.designcertificate=!newstate;
    if(statename=="changetemplatecontent") c_permission.changetemplatecontent=!newstate;
    if(statename=="managecertificate") c_permission.managecertificate=!newstate;
    if(statename=="generatecertificate") c_permission.generatecertificate=!newstate;
    if(statename=="previewcertificate") c_permission.previewcertificate=!newstate;
    if(statename=="issuecertificate") {c_permission.issuecertificate=!newstate;}
    if(statename=="sendcertificate") c_permission.sendcertificate=!newstate;
    if(statename=="changelogo") c_permission.changelogo=!newstate;
    if(statename=="validate") c_permission.validate=!newstate;
    
    this.setState({permissionChecked:c_permission});

}

closeAddUserModal()
{
    this.setState({showAddUserModal:false});
}
closePermissionModal()
{
    this.setState({showPermissionModal:false});
}
handleOnHtmlOrPng()
{
    this.setState({showPermissionModal:false});
}
handleSMTPStatus(smtptype, status){
    if(smtptype=="oauth"){
        this.setState({smtpOAuth: !status});
        this.setState({smtpPWD: status});
    }
    else if(smtptype=="pwd"){
        this.setState({smtpOAuth: status});
        this.setState({smtpPWD: !status});
    }

}

changeStorageType = (e) => {
    let idx = e.target.selectedIndex;
    if (idx == 0) {
        document.getElementById("s3bucket_type").style.display = "";
        document.getElementById("s3bucket_block").style.display = "";
        document.getElementById("s3bucketbutton").style.display = "";
        document.getElementById("azure_block").style.display = "none";
        document.getElementById("azurebutton").style.display = "none";
        document.getElementById("gdrive_block").style.display = "none";
        document.getElementById("gdrivebutton").style.display = "none";
        document.getElementById("ftp_block").style.display = "none";
        document.getElementById("ftpbutton").style.display = "none";
        document.getElementById("local_block").style.display = "none";
        document.getElementById("localbutton").style.display = "none";
        this.setState({storagetype: 1});
    }
    else if (idx == 1){
        document.getElementById("s3bucket_type").style.display = "none";
        document.getElementById("s3bucket_block").style.display = "none";
        document.getElementById("s3bucketbutton").style.display = "none";
        document.getElementById("azure_block").style.display = "";
        document.getElementById("azurebutton").style.display = "";
        document.getElementById("gdrive_block").style.display = "none";
        document.getElementById("gdrivebutton").style.display = "none";
        document.getElementById("ftp_block").style.display = "none";
        document.getElementById("ftpbutton").style.display = "none";
        document.getElementById("local_block").style.display = "none";
        document.getElementById("localbutton").style.display = "none";
        this.setState({storagetype: 2});
    }
    else if (idx == 2){
        document.getElementById("s3bucket_type").style.display = "none";
        document.getElementById("s3bucket_block").style.display = "none";
        document.getElementById("s3bucketbutton").style.display = "none";
        document.getElementById("azure_block").style.display = "none";
        document.getElementById("azurebutton").style.display = "none";
        document.getElementById("gdrive_block").style.display = "";
        document.getElementById("gdrivebutton").style.display = "";
        document.getElementById("ftp_block").style.display = "none";
        document.getElementById("ftpbutton").style.display = "none";
        document.getElementById("local_block").style.display = "none";
        document.getElementById("localbutton").style.display = "none";
        this.setState({storagetype: 3});
    }
    else if (idx == 3){
        document.getElementById("s3bucket_type").style.display = "none";
        document.getElementById("s3bucket_block").style.display = "none";
        document.getElementById("s3bucketbutton").style.display = "none";
        document.getElementById("azure_block").style.display = "none";
        document.getElementById("azurebutton").style.display = "none";
        document.getElementById("gdrive_block").style.display = "none";
        document.getElementById("gdrivebutton").style.display = "none";
        document.getElementById("ftp_block").style.display = "";
        document.getElementById("ftpbutton").style.display = "";
        document.getElementById("local_block").style.display = "none";
        document.getElementById("localbutton").style.display = "none";
        this.setState({storagetype: 4});
    }
    else if (idx == 4){
        document.getElementById("s3bucket_type").style.display = "none";
        document.getElementById("s3bucket_block").style.display = "none";
        document.getElementById("s3bucketbutton").style.display = "none";
        document.getElementById("azure_block").style.display = "none";
        document.getElementById("azurebutton").style.display = "none";
        document.getElementById("gdrive_block").style.display = "none";
        document.getElementById("gdrivebutton").style.display = "none";
        document.getElementById("ftp_block").style.display = "none";
        document.getElementById("ftpbutton").style.display = "none";
        document.getElementById("local_block").style.display = "";
        document.getElementById("localbutton").style.display = "";
        this.setState({storagetype: 5});
    }
}



render() {         
        axios.get('https://jsonip.com/')
        .then(response => {
            console.log(response.data.ip);
            this.ipAddress = response.data.ip;
        })
        .catch(error => console.log(error));       

        let blockchainList = this.BlockchainList.length > 0
        && this.BlockchainList.map((item, i) => {
            if(this.blockchainselectindex==i)
            {
                return (
                    <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                )
            }
            else
            {
                return (
                    <option key={i} value={item.value}>{item.label}</option>
                )
            }
        }, this);


        
        if(this.props.Changepassword && this.state.loadercheck){           
            this.setState({ loadercheck: false })
            if(this.props.Changepassword.Status == 200)
            {
                this.setState({ con: false })
                ToastsStore.success(this.props.Changepassword.message); 
                this.setState({ oldpassword: "" })
                this.setState({ newpassword: "" })
                this.setState({ confirmpassword: "" })          
                
            }
            else if(this.props.Changepassword.Status == 400)
            {
                this.setState({ con: false })
                ToastsStore.error(this.props.Changepassword.message);
            }
         }
         //console.log(this.state.awsaccesskey);
        
        let storageList = this.StorageType.length > 0
        && this.StorageType.map((item, i) => {
            if(this.state.storagetype== (i+1))
                {
                    
                    return (
                        <option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option title={item.label} key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this); 
        

       
        if (this.state.storagetype == 1) {
            document.getElementById("s3bucket_type").style.display = "";
            document.getElementById("s3bucket_block").style.display = "";
            document.getElementById("s3bucketbutton").style.display = ""
        }
        else if (this.state.storagetype == 2){
            document.getElementById("azure_block").style.display = "";
            document.getElementById("azurebutton").style.display = "";
        }
        else if (this.state.storagetype == 3){
            document.getElementById("gdrive_block").style.display = "";
            document.getElementById("gdrivebutton").style.display = "";
        }
        else if (this.state.storagetype == 4){
            document.getElementById("ftp_block").style.display = "";
            document.getElementById("ftpbutton").style.display = "";
        }
        else if (this.state.storagetype == 5){
            document.getElementById("local_block").style.display = "";
            document.getElementById("localbutton").style.display = "";
        }
        
        let smtpServiceTypeList = this.smtpServiceType.length > 0
        && this.smtpServiceType.map((item, i) => {
            if(this.state.isoffice365== i)
                {
                    
                    return (
                        <option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option title={item.label} key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this); 

        let certonceVersonList = this.certonceVerson.length > 0
        && this.certonceVerson.map((item, i) => {
            if(this.state.certonceversion==item.value)
                {
                    
                    return (
                        <option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option title={item.label} key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this); 

        if(this.state.isoffice365==0)
        {
            document.getElementById("gsmtpid").style.display = "";
            document.getElementById("office365id").style.display = "none";
        }
        else if(this.state.isoffice365==1)
        {
            document.getElementById("gsmtpid").style.display = "none";
            document.getElementById("office365id").style.display = "";
        }
         
        return (
            <div>
                <section id="tabs" className="project-tab">
                    {this.state.con && (<Spinner></Spinner>)}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <nav>
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a onClick={this.GetProfile.bind(this)} className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Profile</a>
                                        <a onClick={this.GetProfile.bind(this)} className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#generatekey" role="tab" aria-controls="nav-profile" aria-selected="false">Generate key pair</a>
                                        <a  className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#ChangePassword" role="tab" aria-controls="nav-profile" aria-selected="false">Change Password</a>
                                        {(this.state.is_parent)?(<a onClick={this.GetTransaction.bind(this)} className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Team</a>):""}
                                        <a onClick={this.GetTransaction.bind(this)} className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#Deductionhstry" role="tab" aria-controls="nav-profile" aria-selected="false">Setting</a>

                                        {/* <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Project Tab 3</a> */}
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane forgotPwd" id="generatekey" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    
                                            
                                                <center id="bitcoinaddressdiv" style={{marginTop:"10px"}}>
                                                
                                                    <table style={{width: "100%",height:"50px"}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{textAlign:"right"}}>
                                                                <label style={{display:"inline",marginRight:"10px"}}>Blockchain</label>
                                                                </td>
                                                                <td>
                                                                    <div style={{marginTop:"10px",marginBottom:"10px",marginLeft:"10px"}}>                                                            
                                                                        <select  style={{"height":"30px"}} onChange={this.selectBlockchain} >
                                                                        {blockchainList}
                                                                        </select>
                                                                    </div>
                                                                </td>                                                                
                                                            </tr>
                                                        </tbody>
                                                    </table> 
                                                    <div style={{ marginwidth: "80%",height:"500px",border: "1px solid green",textAlign:"center"}} >
                                                        <table style={{width: "100%",height:"100%",textAlign:"center"}}>
                                                          <tbody>
                                                            <tr>
                                                                <td>
                                                                <h1>Public address</h1>
                                                                <div id="puplickeyaddress">{this.publickey}</div>
                                                                <div id="publickeyaddressqrcode">{this.GetPublicKeyQr()}</div>
                                                                </td>                                                                
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                     </div>
                                                  </center>
                                                
                                                 <center style={{marginTop:"10px"}}>   
                                                    <div style={{"marginTop":"10px"}}>
                                                    {this.GetButton()}
                                                    {"     "}                                                    
                                                    <button className="btn btn-info" style={{paddingLeft:"27px",paddingRight:"27px"}} onClick={()=>this.printAddress()}>Print</button>
                                                    </div>
                                                        
                                                  </center>
                                            
                                        </div>
                                    

                                    <div style={{ marginTop: "17px" }} className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                        <div className="row">
                                            <ul className="col-sm-12">
                                            <li>
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-3 res-width">
                                                            <label>Version</label>
                                                        </div>
                                                        <div className="col-md-6 col-sm-3 text-left res-width2">
                                                            <p>{this.state.certonceversion=="v2"?"Version 2":"Version3"}</p>
                                                            
                                                            {/* <select  style={{"padding":"0px 10px","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"0px"}} onChange={this.selectVersion} >
                                                                    {certonceVersonList}
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-3 res-width">
                                                            <label>Name </label>
                                                        </div>
                                                        <div className="col-md-6 col-sm-3 text-left res-width2">
                                                            <p>{this.state.Profile.fname} {this.state.Profile.lname}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-sm-6 res-width">
                                                            <label>Email</label>
                                                        </div>
                                                        <div className="col-sm-6 text-left res-width2">
                                                            <p>{this.state.Profile.email}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-sm-6 res-width">
                                                            <label>Members</label>
                                                        </div>
                                                        <div className="col-sm-6 text-left res-width2">
                                                            <p>{this.state.Profile.graduates_members}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-sm-6 res-width">
                                                            <label>Organization</label>
                                                        </div>
                                                        <div className="col-sm-6 text-left res-width2">
                                                            <p>{this.state.Profile.organization_name}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-sm-6 res-width">
                                                            <label>Organization Logo</label>
                                                        </div>
                                                        <div className="col-sm-3 text-left res-width2">
                                                            <input type="file" onChange={this.uploadLogo} />
                                                        </div>
                                                        <div className="col-sm-3 text-left res-width2">
                                                            <p>{(this.state.Profile.logoimage)?(<img style={{"height":"100px"}} id="logoimage" src={this.state.Profile.logoimage}></img>):(<img style={{"width":"0px","height":"0px"}} id="logoimage" src=""></img>)}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <table className="table table-hover transaction-table table-responsive-md" cellSpacing="0" style={{"marginTop":"10px"}}>
                                            <thead>
                                                <tr>
                                                    <th>&#8470;</th>
                                                    <th>First&nbsp;Name</th>
                                                    <th>Last&nbsp;Name</th>
                                                    <th>Email&nbsp;ID</th>
                                                    <th>Role</th>
                                                    <th>Created</th>
                                                    <th>Permission</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.Transaction.map((tr, i) =>
                                                    <tr key={i} >
                                                        <td>{i+1}</td>
                                                        <td>{tr.fname}</td>
                                                        <td>{tr.lname}</td>
                                                        <td>{tr.email}</td>
                                                        <td>{tr.job_title}</td>
                                                        <td>{tr.created_at}</td>
                                                        <td>
                                                           {(this.state.is_parent)?(<button style={{"backgroundColor":"#e9ecef","border":"1px solid #ced4da","color":"#495057","fontSize":"14px","width":"73px","height":"28px","marginTop":"-11px","borderRadius":"7px"}} onClick={e=>this.editPermission(tr.email)}  >{"Edit"}</button>):""}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {(this.state.is_parent)?(<button className="btn btn-info" style={{paddingLeft:"27px",paddingRight:"27px",float:"right"}} onClick={e=>this.addUser()}>Add user</button>):""}
                                        <Modal size='lg' dialogClassName="issueConfirm" show={this.state.showAddUserModal} onHide={this.closeAddUserModal} centered scrollable>
                                            <Modal.Header closeButton>
                                            <Modal.Title><p style={{"color":"#0275d8","fontSize":"24px"}}>Add&nbsp;&nbsp;User</p></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>                                            
                                            <table border="0" style={{"width":"90%","margin": "auto"}}>
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>First&nbsp;Name:</p>
                                                    </td>
                                                    <td>
                                                    <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="firstnameid"  />
                                                    </td>
                                                    </tr>

                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>Last&nbsp;Name:</p>
                                                    </td>
                                                    <td>
                                                    <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="lastnameid"  />
                                                    </td>
                                                    </tr>

                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>EMail&nbsp;ID:</p>
                                                    </td>
                                                    <td>
                                                    <input type="text" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="emailid"  />
                                                    </td>
                                                    </tr>

                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>Password:</p>
                                                    </td>
                                                    <td>
                                                    <input type="password" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="passwordid"  />
                                                    </td>
                                                    </tr>

                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>Confirm Password:</p>
                                                    </td>
                                                    <td>
                                                    <input type="password" style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="confirmpasswordid"  />
                                                    </td>
                                                    </tr>

                                                    <tr>
                                                    <td>
                                                    <p style={{"color":"black","fontSize":"16px"}}>Role:</p>
                                                    </td>
                                                    <td>
                                                        <select id="job_titleid" name="job_title" className="form-control own-input" style={{"marginLeft":"5px"}}>
                                                            <option value="Accounting">Accounting</option>
                                                            <option value="Human Resources">Human Resources</option>
                                                            <option value="AccInformation Technology (IT) and Digital Mediaounting">Information Technology (IT) and Digital Media</option>
                                                            <option value="Real Estate">Real Estate</option>
                                                            <option value="Controller of Examination">Controller of Examination</option>
                                                            <option value="MIS Manager">MIS Manager</option>
                                                            <option value="Dy. Controller of Examination">Dy. Controller of Examination</option>
                                                            <option value="Examination Assistant">Examination Assistant</option>
                                                        </select>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </Modal.Body>                        
                                            <Modal.Footer>
                                            <span><Button onClick={this.addUserAction}>&nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                                            <Button onClick={this.closeAddUserModal}>Cancel</Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal size='lg' dialogClassName="issueConfirm" show={this.state.showPermissionModal} onHide={this.closePermissionModal} centered scrollable>
                                            <Modal.Header closeButton>
                                            <Modal.Title><p style={{"color":"#0275d8","fontSize":"24px"}}>Setting Permission</p></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>                                            
                                            <table border="0" style={{"width":"90%","margin": "auto"}}>
                                                <tbody>
                                                    <tr style={{"height":"40px"}}>
                                                      <td colSpan={3} style={{"textAlign":"center","fontSize":"21px","color":"#0275d8"}}>Main permission</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <label>                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.isadmin} onChange={e => this.changePermission("isadmin",this.state.permissionChecked.isadmin)}></input>
                                                             &nbsp;&nbsp;ADMIN 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label>                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.issuer} onChange={e => this.changePermission("issuer",this.state.permissionChecked.issuer)}></input>
                                                             &nbsp;&nbsp;ISSUER 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                             
                                                            <input type="checkbox" checked={this.state.permissionChecked.viewonly} onChange={e => this.changePermission("viewonly",this.state.permissionChecked.viewonly)}></input>
                                                            &nbsp;&nbsp;VIEW ONLY
                                                        </label>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr style={{"height":"40px"}}>
                                                      <td colSpan={3} style={{"textAlign":"center","fontSize":"21px","color":"#0275d8"}}>Detailed permission</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.managemember} onChange={e => this.changePermission("managemember",this.state.permissionChecked.managemember)}></input>
                                                            &nbsp;&nbsp;MANAGE MEMBER  
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.uploadmember} onChange={e => this.changePermission("uploadmember",this.state.permissionChecked.uploadmember)}></input>
                                                            &nbsp;&nbsp;UPLOAD MEMBER 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.sendinvite} onChange={e => this.changePermission("sendinvite",this.state.permissionChecked.sendinvite)}></input>
                                                            &nbsp;&nbsp;SEND INVITE 
                                                        </label>
                                                        </td>
                                                        
                                                        
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <label >                                                            
                                                                <input type="checkbox" checked={this.state.permissionChecked.previewinviteemail} onChange={e => this.changePermission("previewinviteemail",this.state.permissionChecked.previewinviteemail)}></input>
                                                                &nbsp;&nbsp;PREVIEW INVITE EMAIL 
                                                            </label>
                                                        </td>           
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.designcertificate} onChange={e => this.changePermission("designcertificate",this.state.permissionChecked.designcertificate)}></input>
                                                            &nbsp;&nbsp;DESIGN CERTIFICATE 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                              
                                                            <input type="checkbox" checked={this.state.permissionChecked.managecertificate} onChange={e => this.changePermission("managecertificate",this.state.permissionChecked.managecertificate)}></input>
                                                            &nbsp;&nbsp;MANAGE CERTIFICATE
                                                        </label>
                                                        </td>
                                                        
                                                        
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.generatecertificate} onChange={e => this.changePermission("generatecertificate",this.state.permissionChecked.generatecertificate)}></input>
                                                            &nbsp;&nbsp;GENERATE CERTIFICATE 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.previewcertificate} onChange={e => this.changePermission("previewcertificate",this.state.permissionChecked.previewcertificate)}></input>
                                                            &nbsp;&nbsp;PREVIEW CERTIFICATE 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.issuecertificate} onChange={e => this.changePermission("issuecertificate",this.state.permissionChecked.issuecertificate)}></input>
                                                            &nbsp;&nbsp;ISSUE CERTIFICATE 
                                                        </label>
                                                        </td>
                                                        
                                                        
                                                        
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                        <label >                                                              
                                                            <input type="checkbox" checked={this.state.permissionChecked.sendcertificate} onChange={e => this.changePermission("sendcertificate",this.state.permissionChecked.sendcertificate)}></input>
                                                            &nbsp;&nbsp;SEND CERTIFICATE
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.changelogo} onChange={e => this.changePermission("changelogo",this.state.permissionChecked.changelogo)}></input>
                                                            &nbsp;&nbsp;CHANGE  PROFILE 
                                                        </label>
                                                        </td>
                                                        <td>
                                                        <label >                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.validate} onChange={e => this.changePermission("validate",this.state.permissionChecked.validate)}></input>
                                                            &nbsp;&nbsp;VALIDATE 
                                                        </label>
                                                        </td>  

                                                        <td>
                                                        <label style={{"display":"none"}}>                                                            
                                                            <input type="checkbox" checked={this.state.permissionChecked.changetemplatecontent} onChange={e => this.changePermission("changetemplatecontent",this.state.permissionChecked.changetemplatecontent)}></input>
                                                            &nbsp;&nbsp;CHANGE TEMPLATE CONTENT 
                                                        </label>
                                                        </td>                                                        
                                                        
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </Modal.Body>                        
                                            <Modal.Footer>
                                            <span><Button onClick={this.updatePermission}>&nbsp;&nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                                            <Button onClick={this.closePermissionModal}>Cancel</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    <div className="tab-pane forgotPwd" id="ChangePassword" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <form onSubmit={this.handleSubmit} className="text-center">
                                                <input type="password"
                                                 id="OTP" className="second" placeholder="Enter Old Password" 
                                                 value={this.state.oldpassword}
                                                 onChange={this.onHandleChange1}
                                                 name="oldpassword" />
                                                <input type="password" id="OTP1" className="second" placeholder="Please Enter New Password" name="newpassword"
                                                 value={this.state.newpassword}
                                                 onChange={this.onHandleChange2} />
                                                <input type="password" id="OTP2" className="third" placeholder="Confirm Password" name="confirmpassword"
                                                value={this.state.confirmpassword}
                                                onChange={this.onHandleChange3} />
                                                <input type="submit" className="fourth" value="Submit" />
                                            </form>
                                    </div>
                                    <div className="tab-pane forgotPwd" id="Deductionhstry" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div style={{ marginwidth: "80%",border: "0px solid green",textAlign:"center"}} >
                                            
                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"61px","fontSize":"17px"}}>SMTP</legend>
                                                <table style={{"margin":"17px auto"}}>
                                                    <tr>
                                                        <td style={{"width":"135px","textAlign":"left"}}>SMTP Type</td>                                                        
                                                        <td>
                                                            <select  style={{"padding":"7px 10px","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"8px"}} onChange={this.changeSmtpServiceType} >
                                                                    {smtpServiceTypeList}
                                                            </select>
                                                        </td>                                              
                                                    </tr>                                                                                                
                                                </table>
                                                <div id="gsmtpid" style={{display: 'none'}}>
                                                    <table style={{"marginLeft":"20px","marginRight":"20px","margin":"auto"}}>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className="row">
                                                                    <div className="col-xl-6 col-lg-6 col-md-12">
                                                                        <label>                                                            
                                                                            <input type="checkbox" checked={this.state.smtpOAuth} onChange={e => this.handleSMTPStatus("oauth", this.state.smtpOAuth)}></input>
                                                                            &nbsp;&nbsp;OAuth 
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-6 col-lg-6 col-md-12">
                                                                        <label>                                                            
                                                                            <input type="checkbox" checked={this.state.smtpPWD} onChange={e => this.handleSMTPStatus("pwd", this.state.smtpPWD)}></input>
                                                                            &nbsp;&nbsp;User/Password 
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {this.state.smtpPWD==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>Host</td>  
                                                            <td>
                                                                <input id="smtphost" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter Host"  defaultValue={this.state.host}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        {this.state.smtpPWD==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>Port</td>                                                    
                                                            <td>
                                                                <input id="smtpport" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter Port"  defaultValue={this.state.port}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>Username</td>                                                
                                                            <td>
                                                                <input id="smtpusername" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter Username"  defaultValue={this.state.username}></input>
                                                            </td>                                              
                                                        </tr>
                                                        {this.state.smtpPWD==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>Password</td>                                                    
                                                            <td>
                                                                <input id="smtppassword" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter Password"  defaultValue={this.state.password}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        
                                                        {this.state.smtpOAuth==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>ClientID</td>                                                    
                                                            <td>
                                                                <input id="smtpclientid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter ClientID"  defaultValue={this.state.clientid}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        {this.state.smtpOAuth==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>ClientSecret</td>                                                    
                                                            <td>
                                                                <input id="smtpclientsecret" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter ClientSecret"  defaultValue={this.state.clientsecret}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        {this.state.smtpOAuth==true?(<tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>RefreshToken</td>                                                    
                                                            <td>
                                                                <input id="smtprefreshtoken" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter RefreshToken"  defaultValue={this.state.refreshtoken}></input>
                                                            </td>                                              
                                                        </tr>):""}
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>From</td>                                                    
                                                            <td>
                                                                <input id="smtpfrom" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter From"  defaultValue={this.state.from}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>CC</td>                                                    
                                                            <td>
                                                                <input id="smtpcc" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter CC"  defaultValue={this.state.cc}></input>
                                                            </td>                                              
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div id="office365id" style={{display: 'none'}}>
                                                    <table style={{"marginLeft":"20px","marginRight":"20px","margin":"auto"}}>
                                                        
                                                        
                                                       <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>ClientID</td>                                                    
                                                            <td>
                                                                <input id="office365clientid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter ClientID"  defaultValue={this.state.office365clientid}></input>
                                                            </td>                                              
                                                        </tr>
                                                       <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>ClientSecret</td>                                                    
                                                            <td>
                                                                <input id="office365clientsecret" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter ClientSecret"  defaultValue={this.state.office365clientsecret}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>TenantId</td>                                                    
                                                            <td>
                                                                <input id="office365tanentid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter TanentId"  defaultValue={this.state.office365tanentid}></input>
                                                            </td>                                              
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>From</td>                                                    
                                                            <td>
                                                                <input id="office365from" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter From"  defaultValue={this.state.from}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>CC</td>                                                    
                                                            <td>
                                                                <input id="office365cc" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter CC"  defaultValue={this.state.cc}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>AccessToken</td>                                                    
                                                            <td>
                                                                <input id="office365accesstoken" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.office365accesstoken}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>RefreshToken</td>                                                    
                                                            <td>
                                                                <input id="office365refreshtoken" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.office365refreshtoken}></input>
                                                            </td>                                              
                                                        </tr>
                                                    </table>       
                                                </div>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveSmtp()}>Save</button>
                                                {this.state.isoffice365==1&&(<button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.getToken()}>Get Token</button>)}
                                                <br/>
                                            </fieldset>
                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"215px","fontSize":"17px"}}>CONTACT INFORMATION</legend>
                                                    

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>                                                    
                                                    <tr>
                                                        <td style={{"width":"135px","textAlign":"left"}}>Email</td>                                                    
                                                        <td>
                                                            <input id="contactemailid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter contact email"  defaultValue={this.state.contactemail}></input>
                                                        </td>                                              
                                                    </tr>                                                    
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveContactInfo()}>Save</button>
                                                <br/>
                                            </fieldset>
                                            <fieldset style={{"border":"1px solid green","width":"670px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"215px","fontSize":"17px"}}>STRIPE INFORMATION</legend>
                                                    

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>                                                    
                                                    <tr>
                                                        <td style={{"width":"310px","textAlign":"left"}}>Stripe public key</td>                                                    
                                                        <td>
                                                            <input  id="stripepublickeyid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter stripe public key"  defaultValue={this.state.stripepublickey}></input>
                                                        </td>                                              
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"310","textAlign":"left"}}>Stripe secret key</td>                                                    
                                                        <td>
                                                            <input  id="stripesecretkeyid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter stripe secret key"  defaultValue={this.state.stripesecretkey}></input>
                                                        </td>                                              
                                                    </tr>                  
                                                    <tr>
                                                        <td style={{"width":"310","textAlign":"left"}}>Api Key(generated by CertOnce)</td>                                                    
                                                        <td>
                                                            <input disabled id="stripecertonceapikeyid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"   defaultValue={this.state.stripecertonceapikey}></input>
                                                        </td>                                              
                                                    </tr>                                                    
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveStripeInfo()}>Save</button>
                                                <br/>
                                            </fieldset>

                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"180px","fontSize":"17px"}}>CERTIFICATE FORMAT</legend>
                                                   <table style={{"margin":"17px auto"}}>
                                                        <tr>                                                        
                                                            <td>
                                                                <SelectionBox 
                                                                handleOnHtmlOrPng={this.handleOnHtmlOrPng.bind(this)}
                                                                certificateformatselectindex={this.certificateformatselectindex}
                                                                />
                                                            </td>                                              
                                                        </tr>                                                                                                
                                                   </table>                                                
                                            </fieldset>
                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"117px","fontSize":"17px"}}>PDF FORMAT</legend>
                                                    <table style={{"margin":"17px auto"}}>
                                                            <tr>                                                        
                                                                <td>
                                                                    <S3PdfSelectionBox 
                                                                    handleOnS3OrPdf={this.handleOnS3OrPdf.bind(this)}
                                                                    s3bucketselectindex={this.s3bucketselectindex}
                                                                    />
                                                                </td>                                              
                                                            </tr>                                                                                                
                                                    </table>
                                            </fieldset>
                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"220px","fontSize":"17px"}}>UPLOAD STUDENT MODE</legend>
                                                    <table style={{"margin":"17px auto"}}>
                                                            <tr>                                                        
                                                                <td>
                                                                    <ApiExcelSelectionBox 
                                                                    handleApiExcel={this.handleApiExcel.bind(this)}
                                                                    apiexcelselectindex={this.apiexcelselectindex}
                                                                    />
                                                                </td>                                              
                                                            </tr>                                                                                                
                                                    </table>
                                            </fieldset>

                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"215px","fontSize":"17px"}}>STORAGE INFORMATION</legend>
                                                    <table style={{"margin":"17px auto"}}>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Storage Type</td>                                                        
                                                            <td>
                                                                <select  style={{"padding":"7px 10px","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"8px"}} onChange={this.changeStorageType} >
                                                                        {storageList}
                                                                </select>
                                                            </td>                                              
                                                        </tr>                                                                                                
                                                    </table>
                                                    
                                                    <table id="s3bucket_type" style={{"margin":"17px auto", display: 'none'}}>
                                                            <tr>
                                                                <td style={{"width":"135px","textAlign":"left"}}>Bucket Type</td>                                                        
                                                                <td>
                                                                    <S3BucketSelectionBox 
                                                                    handleOnCertOnceS3OrCustomerS3={this.handleOnCertOnceS3OrCustomerS3.bind(this)}
                                                                    certonces3infoselectindex={this.certonces3infoselectindex}
                                                                    />
                                                                </td>                                              
                                                            </tr>                                                                                                
                                                    </table>
                                                    <table id="s3bucket_block" style={{"margin":"auto",marginBottom: "17px", display: 'none'}}>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Aws access key</td>  
                                                            <td>
                                                                <input id="awsaccesskeyid"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter aws accesskey"  defaultValue={this.state.awsaccesskey}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Aws secret key</td>                                                    
                                                            <td>
                                                                <input id="awssecretkeyid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter aws secretkey"  defaultValue={this.state.awssecretkey}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Bucket name</td>                                                
                                                            <td>
                                                                <input id="bucketnameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter bucket name"  defaultValue={this.state.bucketname}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Region</td>                                                    
                                                            <td>
                                                                <input id="regionid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter region"  defaultValue={this.state.region}></input>
                                                            </td>                                              
                                                        </tr>                                                    
                                                    </table>
                                                    <button id="s3bucketbutton" type="button" className="btn btn-primary" style={{"margin":"10px", display: 'none'}} onClick={()=>this.saveS3Info()}>Save</button>

                                                    <table id="azure_block" style={{"margin":"auto",marginBottom: "17px", display: 'none'}}>
                                                        <tr>
                                                            <td style={{"width":"150px","textAlign":"left"}}>Connection String</td>  
                                                            <td>
                                                                <input id="azureconnectionstring"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter aws accesskey"  defaultValue={this.state.azureconnectionstring}></input>
                                                            </td>                                              
                                                        </tr>                                                    
                                                    </table>
                                                    <button id="azurebutton" type="button" className="btn btn-primary" style={{"margin":"10px", display: 'none'}} onClick={()=>this.saveAzureInfo()}>Save</button>

                                                    <table id="gdrive_block" style={{"margin":"auto",marginBottom: "17px", display: 'none'}}>
                                                        <tr>
                                                            <td style={{"width":"100px","textAlign":"left"}}>Credential</td>  
                                                            <td>
                                                                <textarea id="gdrivecredential" rows="4" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"350px"}}  type="text"  placeholder="Enter GDrive Credential"  defaultValue={this.state.gdrivecredential}></textarea>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"135px","textAlign":"left"}}>Token</td>                                                    
                                                            <td>
                                                            <textarea id="gdrivetoken" rows="4" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"350px"}}  type="text"  placeholder="Enter GDrive Token"  defaultValue={this.state.gdrivetoken}></textarea>
                                                            </td>                                              
                                                        </tr>                                                                                                       
                                                    </table>
                                                    <button id="gdrivebutton" type="button" className="btn btn-primary" style={{"margin":"10px", display: 'none'}} onClick={()=>this.saveGDriveInfo()}>Save</button>

                                                    <table id="ftp_block" style={{"margin":"auto",marginBottom: "17px", display: 'none'}}>
                                                        <tr>
                                                            <td style={{"width":"150px","textAlign":"left"}}>Host</td>  
                                                            <td>
                                                                <input id="ftphost"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter FTP Host"  defaultValue={this.state.ftphost}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"150px","textAlign":"left"}}>User</td>  
                                                            <td>
                                                                <input id="ftpuser"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter FTP Username"  defaultValue={this.state.ftpuser}></input>
                                                            </td>                                              
                                                        </tr>
                                                        <tr>
                                                            <td style={{"width":"150px","textAlign":"left"}}>Password</td>  
                                                            <td>
                                                                <input id="ftppassword"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter FTP Password"  defaultValue={this.state.ftppassword}></input>
                                                            </td>                                              
                                                        </tr>                                                    
                                                    </table>
                                                    <button id="ftpbutton" type="button" className="btn btn-primary" style={{"margin":"10px", display: 'none'}} onClick={()=>this.saveFtpInfo()}>Save</button>

                                                    <table id="local_block" style={{"margin":"auto",marginBottom: "17px", display: 'none'}}>
                                                        <tr>
                                                            <td style={{"width":"150px","textAlign":"left"}}>Local Storage Path</td>  
                                                            <td>
                                                                <input id="localpath"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter Local Path"  defaultValue={this.state.localpath}></input>
                                                            </td>                                              
                                                        </tr>                                                                                                            
                                                    </table>
                                                    <button id="localbutton" type="button" className="btn btn-primary" style={{"margin":"10px", display: 'none'}} onClick={()=>this.saveLocalInfo()}>Save</button>

                                            </fieldset>

                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"180px","fontSize":"17px"}}>DOWNLOAD TYPE</legend>
                                                   <table style={{"margin":"17px auto"}}>
                                                        <tr>                                                        
                                                            <td>
                                                                <DownloadTypeSelectionBox 
                                                                handleOnDownloadType={this.handleOnDownloadType.bind(this)}
                                                                downloadtypeselectindex={this.downloadtypeselectindex}
                                                                />
                                                            </td>                                              
                                                        </tr>                                                                                                
                                                   </table>                                                
                                            </fieldset>
                                            
                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"215px","fontSize":"17px"}}>ISSUER INFORMATION</legend>
                                                    

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Base url</td>  
                                                        <td>
                                                            <input id="baseurlid"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter base url"  defaultValue={this.state.baseurl}></input>
                                                        </td>                                              
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Issuer base url</td>  
                                                        <td>
                                                            <input id="issuerurlid"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter issuer url"  defaultValue={this.state.issuerurl}></input>
                                                        </td>                                              
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Revocation base url</td>                                                    
                                                        <td>
                                                            <input id="revocationurlid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter revocation url"  defaultValue={this.state.revocationurl}></input>
                                                        </td>                                              
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Issuer name</td>                                                
                                                        <td>
                                                            <input id="issuernameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter issuer name"  defaultValue={this.state.issuername}></input>
                                                        </td>                                              
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Issuer email</td>                                                    
                                                        <td>
                                                            <input id="issueremailid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter issuer email"  defaultValue={this.state.issueremail}></input>
                                                        </td>                                              
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Verify base url</td>                                                    
                                                        <td>
                                                            <input id="verifybaseurlid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter issuer email"  defaultValue={this.state.verifybaseurl}></input>
                                                        </td>                                              
                                                    </tr>      
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveIssuerInfo()}>Save</button>
                                                <br/>
                                            </fieldset> 

                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"215px","fontSize":"17px"}}>GAS INFORMATION</legend>
                                                    

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>                                                    
                                                    <tr>
                                                        <td style={{"width":"135px","textAlign":"left"}}>Gas price</td>                                                    
                                                        <td>
                                                            <input maxlength="3" id="gaspriceid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="Enter gas price"  defaultValue={this.state.gasprice}></input>
                                                        </td>                                              
                                                    </tr>                                                    
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveGasInfo()}>Save</button>
                                                <br/>
                                            </fieldset>

                                            <fieldset style={{"border":"1px solid green","width":"550px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"100px","fontSize":"17px"}}>API KEY</legend>
                                                    <table style={{"margin":"auto",marginBottom: "17px","width":"90%"}}>                                                    
                                                    <tr>                                                        
                                                        <td>
                                                            <input disabled id="apikeyid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.apikey}></input>
                                                        </td>                                              
                                                    </tr>                                                    
                                                </table>
                                                
                                                <br/>
                                            </fieldset>

                                            {/* <fieldset style={{"border":"1px solid green","width":"805px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"310px","fontSize":"17px"}}>COMPETENCY PARTNER INFORMATION</legend>                                                   

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>
                                                    <tr>
                                                        <td style={{"width":"290px","textAlign":"left"}}>Competency name</td>  
                                                        <td>
                                                            <input id="competencynameid"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issuerurl}></input>
                                                        </td> 
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"290px","textAlign":"left"}}>Customer name</td>  
                                                        <td>
                                                            <input id="customernameid"  style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issuerurl}></input>
                                                        </td> 
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Signature name</td>                                                    
                                                        <td>
                                                            <input id="signaturenameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.revocationurl}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Designer name</td>                                                
                                                        <td>
                                                            <input id="designernameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issuername}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Partner company name</td>                                                    
                                                        <td>
                                                            <input id="partnercompanynameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Logo image</td>                                                    
                                                        <td>
                                                            <input id="plogoimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>  
                                                        <td>
                                                        </td>                                            
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Signature image</td>
                                                        <td>
                                                            <input id="psignatureimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Second signature name</td>                                                    
                                                        <td>
                                                            <input id="secondsignameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.revocationurl}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Second designer name</td>                                                
                                                        <td>
                                                            <input id="seconddesignernameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issuername}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Second partner company name</td>                                                    
                                                        <td>
                                                            <input id="secondpartnercompanynameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Second logo image</td>                                                    
                                                        <td>
                                                            <input id="psecondlogoimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>  
                                                        <td>
                                                        </td>                                             
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Second signature image</td>                                                    
                                                        <td>
                                                            <input id="psecondsignatureimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>  
                                                        <td>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Third signature name</td>                                                    
                                                        <td>
                                                            <input id="thirdsignameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.revocationurl}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Third designer name</td>                                                
                                                        <td>
                                                            <input id="thirddesignernameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issuername}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Third partner company name</td>                                                    
                                                        <td>
                                                            <input id="thirdpartnercompanynameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Third logo image</td>                                                    
                                                        <td>
                                                            <input id="pthirdlogoimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>  
                                                        <td>
                                                        </td>                                             
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"190px","textAlign":"left"}}>Third signature image</td>                                                    
                                                        <td>
                                                            <input id="pthirdsignatureimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  defaultValue={this.state.issueremail}></input>
                                                        </td>  
                                                        <td>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.saveCompetencyPartnerInfo()}>Save</button>
                                                <br/>
                                            </fieldset>  */}

                                            <fieldset style={{"border":"1px solid green","width":"805px","borderRadius":"4px","margin":"auto","marginTop":"40px"}}>
                                                <legend style={{"width":"310px","fontSize":"17px"}}>PARTNER COMPANY INFORMATION</legend>                                                   

                                                    <table style={{"margin":"auto",marginBottom: "17px"}}>                                                    
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Signature name</td>                                                    
                                                        <td>
                                                            <input id="signaturenameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder="" ></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Designer name</td>                                                
                                                        <td>
                                                            <input id="designernameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  ></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Partner company name</td>                                                    
                                                        <td>
                                                            <input id="partnercompanynameid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  ></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Partner company name for display</td>                                                    
                                                        <td>
                                                            <input id="partnercompanynamefordisplayid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  ></input>
                                                        </td>
                                                        <td></td>
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Logo image</td>                                                    
                                                        <td>
                                                            {/* <input style={{"textAlign":"left","fontSize":"13px","width":"275px","height":"27px"}} type="file" onChange={this.uploadLogoImage} accept=".png" /> */}
                                                            <input id="plogoimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  ></input>
                                                        </td>  
                                                        <td>
                                                            {/* <img id="plogoimageid" src=""></img>   */}
                                                        </td>                                            
                                                    </tr>      
                                                    <tr>
                                                        <td style={{"width":"215px","textAlign":"left"}}>Signature image</td>
                                                        <td>
                                                            {/* <input style={{"textAlign":"left","fontSize":"13px","width":"275px","height":"27px"}} type="file" onChange={this.uploadSignatureImage} accept=".png" />                             */}
                                                            <input id="psignatureimageid" style={{"textAlign":"left","border":"1px solid black","fontSize":"13px","padding":"10px","width":"275px","height":"27px"}}  type="text"  placeholder=""  ></input>
                                                        </td>
                                                        <td>
                                                            {/* <img id="psignatureimageid" src=""></img>   */}
                                                        </td>
                                                    </tr>      
                                                    
                                                </table>
                                                <button type="button" className="btn btn-primary" style={{"margin":"10px"}} onClick={()=>this.savePartnerCompanyInfo()}>Save</button>
                                                <br/>
                                            </fieldset>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <table className="table" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Contest Name</th>
                                                    <th>Date</th>
                                                    <th>Award Position</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><a href="#">Work 1</a></td>
                                                    <td>Doe</td>
                                                    <td>john@example.com</td>
                                                </tr>
                                                <tr>
                                                    <td><a href="#">Work 2</a></td>
                                                    <td>Moe</td>
                                                    <td>mary@example.com</td>
                                                </tr>
                                                <tr>
                                                    <td><a href="#">Work 3</a></td>
                                                    <td>Dooley</td>
                                                    <td>july@example.com</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastsContainer store={ToastsStore} />
            </div>
        )
    }
}

function mapStateToProps(state, actions) {
    
    if (state.fetchProfileData && state.fetchProfileData.Data && state.fetchProfileData.Data.Status) {
        return { Profile: state.fetchProfileData.Data }
    }
    else if (state.generateAddressData && state.generateAddressData.Data && state.generateAddressData.Data.Status) {
        return { generatedaddress: state.generateAddressData.Data }
    }
    else if (state.updateProfileData && state.updateProfileData.Data && state.updateProfileData.Data.Status) {
        return { UpdateProfile: state.updateProfileData.Data }
    }
    else if (state.fetchGetTransaction && state.fetchGetTransaction.Data && state.fetchGetTransaction.Data.Status) {
        return { Transaction: state.fetchGetTransaction.Data }
    }
    else if (state.ChangepasswordData && state.ChangepasswordData.Data && state.ChangepasswordData.Data.Status) {
        return { Changepassword: state.ChangepasswordData.Data }
    }
    else if (state.uploadLogoImageData && state.uploadLogoImageData.Data && state.uploadLogoImageData.Data.Status) {
         
        return { UploadLogoProfile: state.uploadLogoImageData.Data }
    }
    else if (state.registerTeamUserData && state.registerTeamUserData.Data && state.registerTeamUserData.Data.Status) {
         
        return { RegisterTeamUserResult: state.registerTeamUserData.Data }
    }
    else {
        return {}

    }
}

export default connect(mapStateToProps)(withRouter(MyProfileView));

// export default MyProfileView;