
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_LOADING,
  REGISTER_COMPLETE
    } from './types';
const INITIAL_STATE = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false
}



export function fetchRegister(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REGISTER_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case REGISTER_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case REGISTER_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case REGISTER_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}