import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import HomeCertOnceDemoComponent from "../../components/HomeCertOnceDemoComponent"

const HomeCertOnceDemo = (prop) => {

    return (
       
        <HeaderandFooter >
          <HomeCertOnceDemoComponent />
        </HeaderandFooter>
      
    );
};

export default HomeCertOnceDemo;