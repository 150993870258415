import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import VerificationComponent from "../../components/VerificationComponent"

const Verification = (prop) => {

    return (
       
        <HeaderandFooter >
         <VerificationComponent />
        </HeaderandFooter>
      
    );
};

export default Verification;