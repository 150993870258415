import {
    GET_PROFILE_FAILURE,GET_PROFILE_LOADING,GET_PROFILE_SUCCESS,GET_PROFILE_COMPLETE,
    UPDATE_PROFILE_FAILURE,UPDATE_PROFILE_LOADING,UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE_COMPLETE,
    GET_TRANSACTION_FAILURE,GET_TRANSACTION_LOADING,GET_TRANSACTION_SUCCESS,GET_TRANSACTION_COMPLETE,
    GET_GENERATEADDRESS_FAILURE,GET_GENERATEADDRESS_LOADING,GET_GENERATEADDRESS_SUCCESS,GET_GENERATEADDRESS_COMPLETE,
    UPLOAD_LOGO_FAILURE,UPLOAD_LOGO_LOADING,UPLOAD_LOGO_SUCCESS,UPLOAD_LOGO_COMPLETE,
    REGISTER_TEAM_USER_FAILURE,REGISTER_TEAM_USER_LOADING,REGISTER_TEAM_USER_SUCCESS,REGISTER_TEAM_USER_COMPLETE,
    FAILURE,LOADING,SUCCESS,COMPLETE} from './types';

require('es6-promise').polyfill();
require('isomorphic-fetch');


//register team user
export function registerTeamUserFailed(msg) {
    return {
        type: REGISTER_TEAM_USER_FAILURE,
        hasErrored: msg
    };
}
export function registerTeamUserLoading(bool) {
    return {
        type: REGISTER_TEAM_USER_LOADING,
        isLoading: bool
    };
}
export function registerTeamUserSuccess(items) {
    return {
        type: REGISTER_TEAM_USER_SUCCESS,
        Data:items
    };
}

export function registerTeamUserComplete(status) {
    return {
        type: REGISTER_TEAM_USER_COMPLETE,
        isCompleted: status
    };
}

export function RegisterTeamUserData(url,payload) {
   
   var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(registerTeamUserLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(registerTeamUserLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(registerTeamUserSuccess(eventData));
                dispatch(registerTeamUserComplete(true));
            })
            .catch((err) => {
                dispatch(registerTeamUserFailed(err.message))
            }
            );
    };
  
}

//upload logo
export function uploadLogoFailed(msg) {
    return {
        type: UPLOAD_LOGO_FAILURE,
        hasErrored: msg
    };
}
export function uploadLogoLoading(bool) {
    return {
        type: UPLOAD_LOGO_LOADING,
        isLoading: bool
    };
}
export function uploadLogoSuccess(items) {
    return {
        type: UPLOAD_LOGO_SUCCESS,
        Data:items
    };
}

export function uploadLogoComplete(status) {
    return {
        type: UPLOAD_LOGO_COMPLETE,
        isCompleted: status
    };
}

export function UploadLogoData(url,payload) {
   
   var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(uploadLogoLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(uploadLogoLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(uploadLogoSuccess(eventData));
                dispatch(uploadLogoComplete(true));
            })
            .catch((err) => {
                dispatch(uploadLogoFailed(err.message))
            }
            );
    };
  
}

export function fetchGenerateAddressFailed(msg) {
    return {
        type: GET_GENERATEADDRESS_FAILURE,
        hasErrored: msg
    };
}
export function fetchGenerateAddressLoading(bool) {
    return {
        type: GET_GENERATEADDRESS_LOADING,
        isLoading: bool
    };
}
export function fetchGenerateAddressSuccess(items) {
    return {
        type: GET_GENERATEADDRESS_SUCCESS,
        Data:items
    };
}

export function fetchGenerateAddressComplete(status) {
    return {
        type: GET_GENERATEADDRESS_COMPLETE,
        isCompleted: status
    };
}

export function GenerateAddressFetchData(url,payload) {
   
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchGenerateAddressLoading(true));
        var requestUrl =  url + '/?blockchain=' + payload['value'];    
        
        fetch(requestUrl, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchGenerateAddressLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchGenerateAddressSuccess(eventData));
                dispatch(fetchGenerateAddressComplete(true));
            })
            .catch((err) => {
                dispatch(fetchGenerateAddressFailed(err.message))
            }
            );
    };
  
}
//User Price
export function fetchUpdateProfileFailed(msg) {
    return {
        type: UPDATE_PROFILE_FAILURE,
        hasErrored: msg
    };
}
export function fetchUpdateProfileLoading(bool) {
    return {
        type: UPDATE_PROFILE_LOADING,
        isLoading: bool
    };
}
export function fetchUpdateProfileSuccess(items) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        Data:items
    };
}

export function fetchUpdateProfileComplete(status) {
    return {
        type: UPDATE_PROFILE_COMPLETE,
        isCompleted: status
    };
}

export function UpdateProfileFetchData(url,payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchUpdateProfileLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchUpdateProfileLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchUpdateProfileSuccess(eventData));
                dispatch(fetchUpdateProfileComplete(true));
            })
            .catch((err) => {
                dispatch(fetchUpdateProfileFailed(err.message))
            }
            );
    };
  
}


export function fetchGetProfileFailed(msg) {
    return {
        type: GET_PROFILE_FAILURE,
        hasErrored: msg
    };
}
export function fetchGetProfileLoading(bool) {
    return {
        type: GET_PROFILE_LOADING,
        isLoading: bool
    };
}
export function fetchGetProfileSuccess(items) {
    return {
        type: GET_PROFILE_SUCCESS,
        Data:items
    };
}

export function fetchGetProfileComplete(status) {
    return {
        type: GET_PROFILE_COMPLETE,
        isCompleted: status
    };
}

export function GetProfileFetchData(url) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchGetProfileLoading(true));
        fetch(url, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchGetProfileLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchGetProfileSuccess(eventData));
                dispatch(fetchGetProfileComplete(true));
            })
            .catch((err) => {
                dispatch(fetchGetProfileFailed(err.message))
            }
            );
    };
  
}


//User GetTransaction plan
export function fetchGetTransactionFailed(msg) {
    return {
        type: GET_TRANSACTION_FAILURE,
        hasErrored: msg
    };
}
export function fetchGetTransactionLoading(bool) {
    return {
        type: GET_TRANSACTION_LOADING,
        isLoading: bool
    };
}
export function fetchGetTransactionSuccess(items) {
    return {
        type: GET_TRANSACTION_SUCCESS,
        Data:items
    };
}

export function fetchGetTransactionComplete(status) {
    return {
        type: GET_TRANSACTION_COMPLETE,
        isCompleted: status
    };
}



export function GetTransactionFetchData(url) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchGetTransactionLoading(true));
        fetch(url, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchGetTransactionLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchGetTransactionSuccess(eventData));
                dispatch(fetchGetTransactionComplete(true));
            })
            .catch((err) => {
                dispatch(fetchGetTransactionFailed(err.message))
            }
            );
    };
  
}



export function fetchFailed(msg) {
    return {
        type: FAILURE,
        hasErrored: msg
    };
}
export function fetchLoading(bool) {
    return {
        type: LOADING,
        isLoading: bool
    };
}
export function fetchSuccess(items) {
    return {
        type: SUCCESS,
        Data:items
    };
}

export function fetchComplete(status) {
    return {
        type: COMPLETE,
        isCompleted: status
    };
}

export function FetchData(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchSuccess(eventData));
                dispatch(fetchComplete(true));
            })
            .catch((err) => {
                dispatch(fetchFailed(err.message))
            }
            );
    };
  
}


