
import { USER_LOGIN_FAILURE,USER_LOGIN_LOADING,USER_LOGIN_SUCCESS,USER_LOGIN_COMPLETE } from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');


//User Login
export function fetchUserloginFailed(msg) {
    return {
        type: USER_LOGIN_FAILURE,
        hasErrored: msg
    };
}
export function fetchUserloginLoading(bool) {
    return {
        type: USER_LOGIN_LOADING,
        isLoading: bool
    };
}
export function fetchUserloginSuccess(items) {
    return {
        type: USER_LOGIN_SUCCESS,
        Data:items
    };
}

export function loginUserComplete(status) {
    return {
        type: USER_LOGIN_COMPLETE,
        isCompleted: status
    };
}

export function UserloginFetchData(url, payload) {
        return (dispatch) => {
        dispatch(fetchUserloginLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchUserloginLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchUserloginSuccess(eventData));
                dispatch(loginUserComplete(true));
            })
            .catch((err) => {
                dispatch(fetchUserloginFailed(err.message))
            }
            );
    };
  
}
