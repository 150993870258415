import { INSERT_STUDENTS_FAILURE,INSERT_STUDENTS_LOADING,INSERT_STUDENTS_SUCCESS,INSERT_STUDENTS_COMPLETE,
         INVITE_STUDENTS_FAILURE,INVITE_STUDENTS_LOADING,INVITE_STUDENTS_SUCCESS,INVITE_STUDENTS_COMPLETE,
         GET_PUBLICKEY_FAILURE,GET_PUBLICKEY_LOADING,GET_PUBLICKEY_SUCCESS,GET_PUBLICKEY_COMPLETE, 
         ISSUE_FAILURE,ISSUE_LOADING,ISSUE_SUCCESS,ISSUE_COMPLETE, 
         INSERT_SCHEDULEDEMO_FAILURE,INSERT_SCHEDULEDEMO_LOADING,INSERT_SCHEDULEDEMO_SUCCESS,INSERT_SCHEDULEDEMO_COMPLETE,
} from './types';
const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};

//Insert students
export const fetchInsertScheduleDemo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INSERT_SCHEDULEDEMO_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
        };
        case INSERT_SCHEDULEDEMO_LOADING:
                return {
                    ...state,
                    loading: true,
                isCompleted:false,
                error: null
        };
        case INSERT_SCHEDULEDEMO_SUCCESS:
                return {
                    ...state,
                    loading: false,
                isCompleted:false,
                error: null,
                Data: action.Data
        };
        case INSERT_SCHEDULEDEMO_COMPLETE:
                return {
                    ...state,
                    loading: false,
                isCompleted:action.isCompleted,
                error: null,
                Data: {}
        };
        default:
            return state;

        }
}
//Insert students
export const fetchInsertStudents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INSERT_STUDENTS_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
case INSERT_STUDENTS_LOADING:
        return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case INSERT_STUDENTS_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case INSERT_STUDENTS_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}

//Invite students
export const fetchInviteStudents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INVITE_STUDENTS_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
case INVITE_STUDENTS_LOADING:
        return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case INVITE_STUDENTS_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case INVITE_STUDENTS_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}

//get public key
export const fetchGetPublicKey = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PUBLICKEY_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
case GET_PUBLICKEY_LOADING:
        return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case GET_PUBLICKEY_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case GET_PUBLICKEY_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}
//issue
export const fetchIssue = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ISSUE_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
case ISSUE_LOADING:
        return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case ISSUE_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case ISSUE_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}