
import { USER_LOGIN_FAILURE,USER_LOGIN_LOADING,USER_LOGIN_SUCCESS,USER_LOGIN_COMPLETE } from './types';

const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};
    export const fetchlogin = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_LOGIN_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case USER_LOGIN_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case USER_LOGIN_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case USER_LOGIN_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}
