import { GET_PROFILE_FAILURE,GET_PROFILE_LOADING,GET_PROFILE_SUCCESS,GET_PROFILE_COMPLETE,
  UPDATE_PROFILE_FAILURE,UPDATE_PROFILE_LOADING,UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE_COMPLETE,
  GET_TRANSACTION_FAILURE,GET_TRANSACTION_LOADING,GET_TRANSACTION_SUCCESS,GET_TRANSACTION_COMPLETE,
  GET_GENERATEADDRESS_FAILURE,GET_GENERATEADDRESS_LOADING,GET_GENERATEADDRESS_SUCCESS,GET_GENERATEADDRESS_COMPLETE,
  UPLOAD_LOGO_FAILURE,UPLOAD_LOGO_LOADING,UPLOAD_LOGO_SUCCESS,UPLOAD_LOGO_COMPLETE,
  REGISTER_TEAM_USER_FAILURE,REGISTER_TEAM_USER_LOADING,REGISTER_TEAM_USER_SUCCESS,REGISTER_TEAM_USER_COMPLETE,
  FAILURE,LOADING,SUCCESS,COMPLETE} from './types';


const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};

export const registerTeamUserData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case REGISTER_TEAM_USER_FAILURE:
      return {
          ...state,
          loading: false,
          isCompleted:false,
          error: action.hasErrored,
        }; 
        case REGISTER_TEAM_USER_LOADING:
        return {
          ...state,
          loading: true,
          isCompleted:false,
          error: null
        }; 
        case REGISTER_TEAM_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          isCompleted:false,
          error: null,
          Data: action.Data
        };
        case REGISTER_TEAM_USER_COMPLETE:
        return {
          ...state,
          loading: false,
          isCompleted:action.isCompleted,
          error: null,
          Data: {}
        };
        default:
        return state;
      
  }
}

export const uploadLogoImageData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case UPLOAD_LOGO_FAILURE:
      return {
          ...state,
          loading: false,
          isCompleted:false,
          error: action.hasErrored,
        }; 
        case UPLOAD_LOGO_LOADING:
        return {
          ...state,
          loading: true,
          isCompleted:false,
          error: null
        }; 
        case UPLOAD_LOGO_SUCCESS:
        return {
          ...state,
          loading: false,
          isCompleted:false,
          error: null,
          Data: action.Data
        };
        case UPLOAD_LOGO_COMPLETE:
        return {
          ...state,
          loading: false,
          isCompleted:action.isCompleted,
          error: null,
          Data: {}
        };
        default:
        return state;
      
  }
}

export const generateAddressData = (state = INITIAL_STATE, action) => {
      //console.log(action.type);
    switch (action.type) {
        case GET_GENERATEADDRESS_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case GET_GENERATEADDRESS_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case GET_GENERATEADDRESS_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case GET_GENERATEADDRESS_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}


    export const fetchProfileData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROFILE_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case GET_PROFILE_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case GET_PROFILE_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case GET_PROFILE_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}


    export const updateProfileData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case UPDATE_PROFILE_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case UPDATE_PROFILE_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case UPDATE_PROFILE_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}

export function fetchGetTransaction(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_TRANSACTION_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case GET_TRANSACTION_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case GET_TRANSACTION_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case GET_TRANSACTION_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}


export const ChangepasswordData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case FAILURE:
      return {
          ...state,
          loading: false,
          isCompleted:false,
          error: action.hasErrored,
        }; 
        case LOADING:
        return {
          ...state,
          loading: true,
          isCompleted:false,
          error: null
        }; 
        case SUCCESS:
        return {
          ...state,
          loading: false,
          isCompleted:false,
          error: null,
          Data: action.Data
        };
        case COMPLETE:
        return {
          ...state,
          loading: false,
          isCompleted:action.isCompleted,
          error: null,
          Data: {}
        };
        default:
        return state;
      
  }
}