import {SEND_DASHBOARD_COMPLETE, SEND_DASHBOARD_FAILURE, SEND_DASHBOARD_LOADING, SEND_DASHBOARD_SUCCESS,
} from './types';

require('es6-promise').polyfill();
require('isomorphic-fetch');
var fetchTimeout = require('fetch-timeout');

export function dashboardFetchFailed(msg) {
    return {
        type: SEND_DASHBOARD_FAILURE,
        hasErrored: msg
    };
}

export function dashboardFetchLoading(bool) {
    return {
        type: SEND_DASHBOARD_LOADING,
        isLoading: bool
    };
}

export function dashboardFetchSuccess(items) {
    return {
        type: SEND_DASHBOARD_SUCCESS,
        Data:items
    };
}

export function dashboardFetchComplete(status) {
    return {
        type: SEND_DASHBOARD_COMPLETE,
        isCompleted: status
    };
}

export function Dashboard(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( dashboardFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( dashboardFetchSuccess(eventData));
        dispatch(dashboardFetchComplete(true));
    })
    .catch((err) => {
            dispatch( dashboardFetchFailed(err.message))
    }
    );
    };
}