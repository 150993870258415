import React, { Component } from 'react';
// import { StripeMain } from "../stripe/StripeMain";
import { withRouter } from 'react-router-dom';
import StripeBtn from "../stripe/stripeBtn";
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/core';


// Publishable key
// pk_test_DRaWsbRWJG7HpAZTCDU5o6rG

// Secret key
// sk_test_sfrsqdqZBh5s1NnfeIzBWxN2

class PaymentComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      plan:{},
      fullName: "",
      Address: "",
      Phone: "",
      City: "",
      State: "",
      Country: "",
      postalcode: "",
      ExtraEmailInvoice: "",
      Email: "",
      PlanID: "",
      Amount: 0,
      loading: false,
      count:0
    }
    this.submit = this.submit.bind(this);
    this.fullNameChanage = this.fullNameChanage.bind(this);
    this.AddressChanage = this.AddressChanage.bind(this);
    this.PhoneChanage = this.PhoneChanage.bind(this);
    this.CityChanage = this.CityChanage.bind(this);
    this.StateChanage = this.StateChanage.bind(this);
    this.CountryChanage = this.CountryChanage.bind(this);
    this.postalcodeChanage = this.postalcodeChanage.bind(this);
    this.ExtraEmailInvoiceChanage = this.ExtraEmailInvoiceChanage.bind(this);
    this.loaderpoint = this.loaderpoint.bind(this)


  }
  async submit(ev) {
    // User clicked submit
  }

  fullNameChanage(event) {
    this.setState({ fullName: event.target.value });
  }
  AddressChanage(event) {
    this.setState({ Address: event.target.value });
  }

  PhoneChanage(event) {
    this.setState({ Phone: event.target.value });
  }
  CityChanage(event) {
    this.setState({ City: event.target.value });
  }
  StateChanage(event) {
    this.setState({ State: event.target.value });
  }
  CountryChanage(event) {
    this.setState({ Country: event.target.value });
  }
  postalcodeChanage(event) {
    this.setState({ postalcode: event.target.value });
  }
  ExtraEmailInvoiceChanage(event) {
    this.setState({ ExtraEmailInvoice: event.target.value });
  }

  loaderpoint(value) 
  {
    console.log("loaderpoint:",value)
    this.state.loader = value ;
  }



  componentDidMount() {

    if (this.props.location.state) {
      this.setState({ Email: localStorage.getItem("email") })
      this.setState({ PlanID: this.props.location.state.PlanID });
      this.setState({ count: this.props.location.state.count});
      this.setState({ Amount: (this.props.location.state.Amount) * 100 });
      this.setState({plan:this.props.location.state.plan})

    }
    else {
      this.props.history.push("/prices");
    }

    console.log("this.props.match.params:", this.props.location.state)

  }




  render() {
    // console.log('SEARCH RESULTS STATE', this.state);
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


    return (

      <div className="container">
      <div className="row">
        <div className="well col-xs-10 col-sm-10 col-md-12 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
      
            <div className="row">
                <div className="text-center">
                    <h1>Summary</h1>
                </div>
              
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>#</th>
                            <th className="text-center">Price</th>
                            <th className="text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-md-9"><em>{this.state.plan.plan_name}</em></td>
                            <td className="col-md-1" style={{textAlign: "center"}}> 1 </td>
                            <td className="col-md-1 text-center">$ {this.state.plan.price} </td>
                            <td className="col-md-1 text-center">$ {this.state.plan.price}</td>
                        </tr>
                
                        <tr>
                            <td>   </td>
                            <td>   </td>
                            <td className="text-right">
                            <p>
                                <strong>Subtotal: </strong>
                            </p>
                            <p>
                                <strong>Tax: </strong>
                            </p></td>
                            <td className="text-center">
                            <p>
                                <strong>$ {this.state.plan.price}</strong>
                            </p>
                            <p>
                                <strong>$ 0</strong>
                            </p></td>
                        </tr>
                        <tr>
                            <td>   </td>
                            <td>   </td>
                            <td className="text-right"><h4><strong>Total: </strong></h4></td>
                            <td className="text-center text-danger"><h4><strong>${this.state.plan.price}</strong></h4></td>
                        </tr>
                    </tbody>
                </table>
                <div className="col-md-12 text-right" style = {{marginBottom: "19px"}}>
                <StripeBtn customer={this.state} />
                </div>
                {/* <button type="button" className="btn btn-success btn-lg btn-block">
                    Pay Now   <span className="glyphicon glyphicon-chevron-right"></span>
                </button> */}
            </div>
        </div>
    </div>
   
      </div>
    )

  }
}

export default withRouter(PaymentComponent);
