import React from 'react'
import "./button.css";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { insertReceipientForGS } from "../../utils/constants";
import { InsertStudentsData} from '../../redux/GetStarted/actions';
import Spinner from "../Spinner";
import {Alert,Button, Modal} from 'react-bootstrap';
export class StepOne extends React.Component {
  onetimecode="";
  constructor () {
    super()
    this.state = {  
           con: false,
           inputstyle1: {     
            width:"40%",
            textAlign:"left",
            border:"1px solid",
            height:"40px"
          },
          inputstyle2: {     
            width:"40%",
            textAlign:"left",
            border:"1px solid",
            height:"40px"
          },
          inputstyle3: {     
            width:"40%",
            textAlign:"left",
            border:"1px solid",
            height:"40px"
          },
          alertData: {
            status: false,
            className: '',
            message: ''
            },
    }
    this.nextFn = this.nextFn.bind(this);    
    this.prevFn = this.prevFn.bind(this);    
    this.onClick = this.onClick.bind(this);    
  }

  inputrowstyle= {    
    justifyContent:"center",
    display: "inline",
    textAlign: "center",  
    width:"400px",
  }
componentDidMount(){
  
}
componentDidUpdate(prevProps, prevState) {
  
  if ( prevProps.Students ) {
    if(prevProps.Students.Status==200)
    {
       this.props.setOneTimeCode(prevProps.Students.Data.onetimecode);
       this.setState({con:false});
       //console.log(this.props.values.onetimecode);
       this.props.nextStep();
    }
    else
    {
      this.setState({con:false});
      this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.Students.message}});
      window.scrollTo(0,0);
    }
  }
}
nextFn =e=> {
    e.preventDefault();
    
    if(this.validationHandle())
    {
    
    this.setState({con:true});
    let payload = {
            firstname : this.props.values.firstName,
            lastname: this.props.values.lastName,
            emailaddress: this.props.values.emailAddress
        };
     this.props.dispatch(InsertStudentsData(insertReceipientForGS, payload));
    }
    
  }
  prevFn =e=> {    
  }
  validationHandle()
  {
     if(this.props.values.firstName==="")
     {
       this.setState({"inputstyle1":{     
          width:"40%",
          textAlign:"left",
          border:"2px solid red",
          height:"40px"
        }});
        
        return false;
     }
     if(this.props.values.lastName==="")
     {
       this.setState({"inputstyle2":{     
          width:"40%",
          textAlign:"left",
          border:"2px solid red",
          height:"40px"
        }});
        
        return false;
     }
     if(this.props.values.emailAddress==="")
     {
       this.setState({"inputstyle3":{     
          width:"40%",
          textAlign:"left",
          border:"2px solid red",
          height:"40px"
        }});
        
        return false;
     }
     return true;

  }
  onClick=input=>e=>{
    
    if(input==="firstName")
    {
      this.setState({"inputstyle1":{     
          width:"40%",
          textAlign:"left",
          border:"1px solid",
          height:"40px"
        }});
    }
    if(input==="lastName")
    {
      this.setState({"inputstyle2":{     
          width:"40%",
          textAlign:"left",
          border:"1px solid",
          height:"40px"
        }});
    }
    if(input==="emailAddress")
    {
      this.setState({"inputstyle1":{     
          width:"40%",
          textAlign:"left",
          border:"1px solid",
          height:"40px"
        }});
    }    
  }
  
  closeAlert() {
      var alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData});
    }
 

  render () {
    const {values,handleChange}=this.props;
    var alertData = this.state.alertData;
    
    return (
      <div>
      {this.state.con && (<Spinner></Spinner>)}
      { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
      <div className='row' style={{"justifyContent": "center"}}>
          <div className='six columns'>
            <p style={{"marginBottom":"0px","fontWeight":"600","fontSize":"18px"}}>Issuing Authority : CertOnce Welcome University</p>
            <p style={{"marginBottom":"0px","fontWeight":"600","fontSize":"18px","marginBottom":"10px"}}>Certificate Name : Demo Course 2019</p>
          </div>
        </div>
        <div className='row' style={this.inputrowstyle}>
          <div >
            <label>First Name</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input className="form-control own-input" style={this.state.inputstyle1} placeholder='First Name'  type='text' onClick={this.onClick("firstName")}  onChange={this.props.handleChange('firstName')} defaultValue={values.firstName}  autoFocus  />            
          </div>
        </div>
        <div className='row' style={this.inputrowstyle}>
          <div className='six columns'>
            <label>Last Name</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input className="form-control own-input" style={this.state.inputstyle2}   placeholder='Last Name'   type='text' onClick={this.onClick("lastName")}  onChange={this.props.handleChange('lastName')} defaultValue={values.lastName} />
          </div>
        </div>
        <div className='row' style={this.inputrowstyle}>
          <div className='six columns'>
            <label>Email Address</label>
            <input className="form-control own-input" style={this.state.inputstyle3}   placeholder='Email Address'   type='text' onClick={this.onClick("emailAddress")}  onChange={this.props.handleChange('emailAddress')} defaultValue={values.emailAddress} />
          </div>
        </div>
        <br/>
        <div  style={{"justifyContent": "center","display": "flex"}}>
          <div style={{"width":"50%"}}>
            <button className="back" disabled>Previous</button>            
          </div>
          <div style={{"width":"50%"}}>           
            <button className="forward" onClick={this.nextFn}>Next</button>
          </div>
        </div>

        
        


      </div>
    )
  }
}
function mapStateToProps(state, actions) {
    if (state.fetchInsertStudents && state.fetchInsertStudents.Data && state.fetchInsertStudents.Data.Status) {
        return { Students: state.fetchInsertStudents.Data}
    } 
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(StepOne));
//export default StepOne