import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';




import Spinner from "../Spinner";
import * as XLSX from 'xlsx';

///////////////////////////////////////////////////
import StepOne from "../../components/StepOne"
import StepTwo from "../../components/StepTwo"
import StepThree from "../../components/StepThree"
import StepFour from "../../components/StepFour"
import StepFive from "../../components/StepFive"
import StepSix from "../../components/StepSix"
///////////////////////////////////////////////////
class CertOnceComponent extends Component {

    
    constructor(props) {
        super(props);
        this.state={
            con: true, 
            step:1,
            firstName: '', 
            lastName: '',
            emailAddress:'', 
            onetimecode:'',   
            publickey:'', 
            signedcertificateurl:'',         
        }        
        
        this.setOneTimeCode = this.setOneTimeCode.bind(this);
        this.setPublicKey = this.setPublicKey.bind(this);
        this.setSignedcertificateurl = this.setSignedcertificateurl.bind(this);
    }

    
   
  componentDidMount(){
       
   }
  componentDidUpdate(prevProps, prevState) {
        
   }
   //process next step
   nextStep=()=>{
    const {step}=this.state;
    this.setState({step:step+1});
   }
   //process prev step
   prevStep=()=>{
    const {step}=this.state;
    this.setState({step:step-1});    
   }
   handleChange=input=>e=>{      
     this.setState({[input]:e.target.value});     
   }  
   setOneTimeCode(arg)
   {
       this.setState({onetimecode:arg});
       //console.log(arg)
   }
   setPublicKey(arg)
   {
       this.setState({publickey:arg});
   }
   setSignedcertificateurl(arg)
   {
       this.setState({signedcertificateurl:arg});
   }
    render() {
        
        const {step}=this.state;
        const {firstName,lastName,emailAddress,onetimecode,publickey,signedcertificateurl}=this.state;
        const values={firstName,lastName,emailAddress,onetimecode,publickey,signedcertificateurl}
        switch (step) {
            case 1:
               return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepOne 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        setOneTimeCode={this.setOneTimeCode}
                        values={values}                        
                    />
                    </div>                
                </div>            
               )                
            case 2:
              return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepTwo 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                        setPublicKey={this.setPublicKey}
                    />
                    </div>                
                </div>            
               )                
            case 3:
              return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepThree 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                        
                    />
                    </div>                
                </div>            
               )                
            case 4:
              return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepFour 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                        setSignedcertificateurl={this.setSignedcertificateurl}
                    />
                    </div>                
                </div>            
               )                   
            case 5:
              return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepFive 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}                        
                    />
                    </div>                
                </div>            
               )
               
            case 6:
              return (                
                <div className='container' style={{"marginBottom":"10%","marginTop":"4%"}}>
                    <div>
                    <StepSix 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}                        
                    />
                    </div>                
                </div>            
               )                                              
            default:
                break;
        }
        

        }
        


}
function mapStateToProps(state, actions) {
    {
        return {};

    }
}
export default connect(mapStateToProps)(withRouter(CertOnceComponent));

// export default MyProfileView;