
import { FAILURE,LOADING,SUCCESS,COMPLETE } from './types';

const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};
    export const fetchApiData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}
