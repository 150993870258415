//Bake openBadges
export const SEND_BAKEOPENBADGES_SUCCESS = 'SEND_BAKEOPENBADGES_SUCCESS';
export const SEND_BAKEOPENBADGES_LOADING = 'SEND_BAKEOPENBADGES_LOADING';
export const SEND_BAKEOPENBADGES_FAILURE = 'SEND_BAKEOPENBADGES_FAILURE';
export const SEND_BAKEOPENBADGES_COMPLETE = 'SEND_BAKEOPENBADGES_COMPLETE';
//Generate certificate
export const SEND_GENERATECERTIFICATE_SUCCESS = 'SEND_GENERATECERTIFICATE_SUCCESS';
export const SEND_GENERATECERTIFICATE_LOADING = 'SEND_GENERATECERTIFICATE_LOADING';
export const SEND_GENERATECERTIFICATE_FAILURE = 'SEND_GENERATECERTIFICATE_FAILURE';
export const SEND_GENERATECERTIFICATE_COMPLETE = 'SEND_GENERATECERTIFICATE_COMPLETE';

//Issue certificate
export const SEND_ISSUECERTIFICATE_SUCCESS = 'SEND_ISSUECERTIFICATE_SUCCESS';
export const SEND_ISSUECERTIFICATE_LOADING = 'SEND_ISSUECERTIFICATE_LOADING';
export const SEND_ISSUECERTIFICATE_FAILURE = 'SEND_ISSUECERTIFICATE_FAILURE';
export const SEND_ISSUECERTIFICATE_COMPLETE = 'SEND_ISSUECERTIFICATE_COMPLETE';

//Preview certificate
export const SEND_PREVIEWCERTIFICATE_SUCCESS = 'SEND_PREVIEWCERTIFICATE_SUCCESS';
export const SEND_PREVIEWCERTIFICATE_LOADING = 'SEND_PREVIEWCERTIFICATE_LOADING';
export const SEND_PREVIEWCERTIFICATE_FAILURE = 'SEND_PREVIEWCERTIFICATE_FAILURE';
export const SEND_PREVIEWCERTIFICATE_COMPLETE = 'SEND_PREVIEWCERTIFICATE_COMPLETE';

//Send certificate
export const SEND_CERTIFICATE_SUCCESS = 'SEND_CERTIFICATE_SUCCESS';
export const SEND_CERTIFICATE_LOADING = 'SEND_CERTIFICATE_LOADING';
export const SEND_CERTIFICATE_FAILURE = 'SEND_CERTIFICATE_FAILURE';
export const SEND_CERTIFICATE_COMPLETE = 'SEND_CERTIFICATE_COMPLETE';

export const SEND_REVOKE_SUCCESS = 'SEND_REVOKE_SUCCESS';
export const SEND_REVOKE_LOADING = 'SEND_REVOKE_LOADING';
export const SEND_REVOKE_FAILURE = 'SEND_REVOKE_FAILURE';
export const SEND_REVOKE_COMPLETE = 'SEND_REVOKE_COMPLETE';

//Issue certificate daemon
export const SEND_ISSUECERTIFICATEDAEMON_SUCCESS = 'SEND_ISSUECERTIFICATEDAEMON_SUCCESS';
export const SEND_ISSUECERTIFICATEDAEMON_LOADING = 'SEND_ISSUECERTIFICATEDAEMON_LOADING';
export const SEND_ISSUECERTIFICATEDAEMON_FAILURE = 'SEND_ISSUECERTIFICATEDAEMON_FAILURE';
export const SEND_ISSUECERTIFICATEDAEMON_COMPLETE = 'SEND_ISSUECERTIFICATEDAEMON_COMPLETE';

export const SEND_ISSUECONFIRM_SUCCESS = 'SEND_ISSUECONFIRM_SUCCESS';
export const SEND_ISSUECONFIRM_LOADING = 'SEND_ISSUECONFIRM_LOADING';
export const SEND_ISSUECONFIRM_FAILURE = 'SEND_ISSUECONFIRM_FAILURE';
export const SEND_ISSUECONFIRM_COMPLETE = 'SEND_ISSUECONFIRM_COMPLETE';

export const SEND_VALIDATE_SUCCESS = 'SEND_VALIDATE_SUCCESS';
export const SEND_VALIDATE_LOADING = 'SEND_VALIDATE_LOADING';
export const SEND_VALIDATE_FAILURE = 'SEND_VALIDATE_FAILURE';
export const SEND_VALIDATE_COMPLETE = 'SEND_VALIDATE_COMPLETE';

export const SUCCESS = 'SUCCESS';
export const LOADING = 'LOADING';
export const FAILURE = 'FAILURE';
export const COMPLETE = 'COMPLETE';

//Batch certificate
export const SEND_BATCH_SUCCESS = 'SEND_BATCH_SUCCESS';
export const SEND_BATCH_LOADING = 'SEND_BATCH_LOADING';
export const SEND_BATCH_FAILURE = 'SEND_BATCH_FAILURE';
export const SEND_BATCH_COMPLETE = 'SEND_BATCH_COMPLETE';

//reset certificate
export const SEND_RESETCERTIFICATE_SUCCESS = 'SEND_RESETCERTIFICATE_SUCCESS';
export const SEND_RESETCERTIFICATE_LOADING = 'SEND_RESETCERTIFICATE_LOADING';
export const SEND_RESETCERTIFICATE_FAILURE = 'SEND_RESETCERTIFICATE_FAILURE';
export const SEND_RESETCERTIFICATE_COMPLETE = 'SEND_RESETCERTIFICATE_COMPLETE';

//unhold
export const SEND_UNHOLD_SUCCESS = 'SEND_UNHOLD_SUCCESS';
export const SEND_UNHOLD_LOADING = 'SEND_UNHOLD_LOADING';
export const SEND_UNHOLD_FAILURE = 'SEND_UNHOLD_FAILURE';
export const SEND_UNHOLD_COMPLETE = 'SEND_UNHOLD_COMPLETE';

//Preview Student Photo
export const SEND_PREVIEWSTUDENTPHOTO_SUCCESS = 'SEND_PREVIEWSTUDENTPHOTO_SUCCESS';
export const SEND_PREVIEWSTUDENTPHOTO_LOADING = 'SEND_PREVIEWSTUDENTPHOTO_LOADING';
export const SEND_PREVIEWSTUDENTPHOTO_FAILURE = 'SEND_PREVIEWSTUDENTPHOTO_FAILURE';
export const SEND_PREVIEWSTUDENTPHOTO_COMPLETE = 'SEND_PREVIEWSTUDENTPHOTO_COMPLETE';

//Download Preview Image
export const SEND_DOWNLOADPREVIEWIMAGE_SUCCESS = 'SEND_DOWNLOADPREVIEWIMAGE_SUCCESS';
export const SEND_DOWNLOADPREVIEWIMAGE_LOADING = 'SEND_DOWNLOADPREVIEWIMAGE_LOADING';
export const SEND_DOWNLOADPREVIEWIMAGE_FAILURE = 'SEND_DOWNLOADPREVIEWIMAGE_FAILURE';
export const SEND_DOWNLOADPREVIEWIMAGE_COMPLETE = 'SEND_DOWNLOADPREVIEWIMAGE_COMPLETE';


//Download Preview Image
export const SEND_CERTIFICATE_FORREVIEW_SUCCESS = 'SEND_CERTIFICATE_FORREVIEW_SUCCESS';
export const SEND_CERTIFICATE_FORREVIEW_LOADING = 'SEND_CERTIFICATE_FORREVIEW_LOADING';
export const SEND_CERTIFICATE_FORREVIEW_FAILURE = 'SEND_CERTIFICATE_FORREVIEW_FAILURE';
export const SEND_CERTIFICATE_FORREVIEW_COMPLETE = 'SEND_CERTIFICATE_FORREVIEW_COMPLETE';

