
export const INSERT_STUDENTS_SUCCESS= 'INSERT_STUDENTS_SUCCESS';
export const INSERT_STUDENTS_LOADING = 'INSERT_STUDENTS_LOADING';
export const INSERT_STUDENTS_FAILURE = 'INSERT_STUDENTS_FAILURE';
export const INSERT_STUDENTS_COMPLETE = 'INSERT_STUDENTS_COMPLETE';

export const INVITE_STUDENTS_SUCCESS= 'INVITE_STUDENTS_SUCCESS';
export const INVITE_STUDENTS_LOADING = 'INVITE_STUDENTS_LOADING';
export const INVITE_STUDENTS_FAILURE = 'INVITE_STUDENTS_FAILURE';
export const INVITE_STUDENTS_COMPLETE = 'INVITE_STUDENTS_COMPLETE';

export const GET_PUBLICKEY_SUCCESS= 'GET_PUBLICKEY_SUCCESS';
export const GET_PUBLICKEY_LOADING = 'GET_PUBLICKEY_LOADING';
export const GET_PUBLICKEY_FAILURE = 'GET_PUBLICKEY_FAILURE';
export const GET_PUBLICKEY_COMPLETE = 'GET_PUBLICKEY_COMPLETE';

export const ISSUE_SUCCESS= 'ISSUE_SUCCESS';
export const ISSUE_LOADING = 'ISSUE_LOADING';
export const ISSUE_FAILURE = 'ISSUE_FAILURE';
export const ISSUE_COMPLETE = 'ISSUE_COMPLETE';

export const INSERT_SCHEDULEDEMO_SUCCESS= 'INSERT_SCHEDULEDEMO_SUCCESS';
export const INSERT_SCHEDULEDEMO_LOADING = 'INSERT_SCHEDULEDEMO_LOADING';
export const INSERT_SCHEDULEDEMO_FAILURE = 'INSERT_SCHEDULEDEMO_FAILURE';
export const INSERT_SCHEDULEDEMO_COMPLETE = 'INSERT_SCHEDULEDEMO_COMPLETE';
