import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import MyProfileView from "../../components/MyProfileView"

const MyProfile = (prop) => {

    return (
       
        <HeaderandFooter >
 <MyProfileView />
    </HeaderandFooter>
      
    );
};

export default MyProfile;