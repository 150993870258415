import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import CertOnceDemoComponent from "../../components/CertOnceDemoComponent"

const CertOnceDemo = (prop) => {

    return (
       
        <HeaderandFooter >
          <CertOnceDemoComponent />
        </HeaderandFooter>
      
    );
};

export default CertOnceDemo;