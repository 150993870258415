import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetCohortsFetchData } from '../../redux/ManageStudents/actions';
import { GetProfileFetchData } from '../../redux/Profile/actions';
//import "./style.css";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import Select from "react-dropdown-select";
import * as XLSX from 'xlsx';
import { getCohorts, getProfile } from "../../utils/constants";
import { Alert, Button, Modal } from 'react-bootstrap';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';
///////////////template part////////////////
import CertificateEmailTemplate from "../../components/Templates/CertificateEmailTemplate";
import InvitationEmailTemplate from "../../components/Templates/InvitationEmailTemplate";
import OpenBadgeEmailTemplate from "../../components/Templates/OpenBadgeEmailTemplate";
import PreviewTemplate from "../../components/Templates/PreviewTemplate";
////////////template part end//////////////
import { FetchCertificateTemplate } from '../../redux/DesignCertificate/actions';
import { fetchcertificatetemplateurl } from "../../utils/constants";

class DesignCertificateComponent extends Component {
    template_num = 1;
    templates_array = { "1": "School of Hard Knocks", "2": "College of Life" };
    enabledbt = false;
    enablenextstep = true;
    Cohorts = [];
    progressselectindex = 1;
    template_content = "";
    preview_content = "";
    template_openbadges_content = "";
    template_content_from_db = "";
    template_openbadges_content_from_db = "";
    cohortid = "";
    country = "";
    selectedCertTyp = "";
    //CertType=[{"label":"Certificate","value":"Certificate"},{"label":"Email","value":"Email"},{"label":"OpenBadge Email","value":"Badge"}];
    // CertType=[{"label":"Certificate","value":"Certificate"},{"label":"Email","value":"Email"}];
    CertType = [{ "label": "Degree", "value": "degree" }, { "label": "Migration", "value": "migration" }, { "label": "Transfer", "value": "transfer" }, { "label": "Awards", "value": "awards" }, { "label": "Relieving", "value": "relieving" }, { "label": "Bonafide", "value": "bonafide" }, { "label": "Medal", "value": "medal" }, { "label": "Transcript", "value": "transcript" }, { "label": "Semester-wise Grade card", "value": "semesterwisegradecard" }, { "label": "OpenBadges", "value": "openbadges" }, { "label": "OR", "value": "or" }];
    certtypeselectindex = 0;
    constructor(props) {
        super(props);
        this.state = {
            con: false,
            step: 1,
            Cohorts: [],
            alertData: {
                status: false,
                className: '',
                message: ''
            },
            // selectedCertType:'Certificate',
            displayCohort: [],
        }
        this.GetCohorts = this.GetCohorts.bind(this);
    }

    GetProfile() {
        console.log("GetProfile")
        this.setState({ con: true })
        this.props.dispatch(GetProfileFetchData(getProfile));
    }
    GetCohorts() {
        this.setState({ con: true });
        let payload = {
            certtypevalue: this.selectedCertType
        };
        this.props.dispatch(GetCohortsFetchData(getCohorts, payload));
    }
    selectCohortForProgress = (e) => {
        this.setState({ con: true });
        let idx = e.target.selectedIndex;
        this.progressselectindex = idx + 1;
        this.setState({ size: 1 });
        e.target.blur();
        this.cohortid = this.Cohorts[this.progressselectindex].value;
        this.country = this.Cohorts[this.progressselectindex].country;
        if (this.country == null) this.country = "";
        console.log("country=", this.country);
        if (this.cohortid == "" || this.cohortid == null) alert("Please select cohort.");
        localStorage.setItem('selectedCohort', this.cohortid);
        //this.props.dispatch(FetchCertificateTemplate(fetchcertificatetemplateurl+"/?cohortid="+this.cohortid));       
    }

    setCohort = displayCohort => {
        if (displayCohort.length != 0) {
            //this.progressselectindex=idx+1;
            this.selectedCohort = displayCohort[0].value;
            this.cohortid = displayCohort[0].value;
            this.setState({ size: 1 });
            localStorage.setItem('selectedCohort', this.selectedCohort);
            //this.props.dispatch(FetchCertificateTemplate(fetchcertificatetemplateurl+"/?cohortid="+this.cohortid)); 
        }
    }

    checkCohortValid() {
        var cohortList = [];
        for (let index = 0; index < this.Cohorts.length; index++) {
            cohortList.push(this.Cohorts[index].value);
        }
        let cohortid = localStorage.getItem('selectedCohort');
        if (cohortid == null || cohortid == undefined) {
            if (this.Cohorts[0] == null || this.Cohorts[0] == undefined) return;
            cohortid = this.Cohorts[0].value;
        }
        if (cohortid == null || cohortid == undefined) return false;
        console.log(cohortid);
        if (cohortList.indexOf(cohortid) == -1) return false;
        else return cohortid;

    }
    nextStepForFirst = (temp_num) => {

        let cohortsearchmodelstrg = localStorage.getItem('cohortsearchmode');
        if (cohortsearchmodelstrg == undefined || cohortsearchmodelstrg == null || cohortsearchmodelstrg == "") cohortsearchmodelstrg = 0;
        if (cohortsearchmodelstrg == 1) {
            var checkCohort = this.checkCohortValid();
            console.log(checkCohort);
            if (checkCohort == false) {
                this.setState({ alertData: { status: 'empty', className: 'warning', message: 'Please check correct batch' } });
                return;
            }
        }

        this.template_num = temp_num;
        this.template_content = "";
        //if(this.enabledbt==true)
        {
            const { step } = this.state;
            //if(this.enablenextstep==true)
            {

                //this.cohortid=this.Cohorts[this.progressselectindex].value;

                if (this.cohortid == "" || this.cohortid == null) {
                    alert("Please select cohort.");
                    return;
                }

                if (this.country == null) this.country = "";
                console.log("country=", this.country);
                this.setState({ step: step + 1 });
            }

        }
        //else
        {
            //this.setState({ con: true }); 
        }
    }
    nextStep = () => {

        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    setPreviewContent = (content) => {
        this.preview_content = content;
    }
    setTemplateContent = (content) => {
        this.template_content = content;
    }
    setOpenBadgesTemplateContent = (content) => {
        this.template_openbadges_content = content;
        //console.log(content)
    }

    //process prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    changeCertType = (e) => {
        let idx = e.target.selectedIndex;
        this.certtypeselectindex = idx;
        let value = e.target.options[idx].value;

        this.selectedCertType = value;
        localStorage.setItem('selectedCertType', this.selectedCertType);
    }

    componentDidMount() {
        let selectedcerttypeid = localStorage.getItem('selectedCertType');
        if (selectedcerttypeid == null || selectedcerttypeid == undefined) {
            selectedcerttypeid = "degree";
            localStorage.setItem('selectedCertType', "degree");
        }
        else {
            this.CertType.length > 0
                && this.CertType.map((item, i) => {
                    if (item.value == selectedcerttypeid) {
                        this.certtypeselectindex = i;
                    }

                }, this);
        }
        this.selectedCertType = selectedcerttypeid;
        this.GetProfile();
        this.GetCohorts();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.template_content_status) {
            this.setState({ con: false });
            this.enabledbt = true;
            if (prevProps.template_content_status.Status === 200) {
                this.template_content_from_db = prevProps.template_content_status.message;

            }
            else if (prevProps.template_content_status.Status === 400) {
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.template_content_status.message } });
                this.enablenextstep = false;
            }
        }
        if (prevProps.Cohorts) {
            this.setState({ con: false });
            if (prevProps.Cohorts.Status === 200) {
                try {
                    this.Cohorts = prevProps.Cohorts.Data;
                    if (prevProps.Cohorts.Templates !== null && prevProps.Cohorts.Templates !== "") {
                        try {
                            this.templates_array = JSON.parse(prevProps.Cohorts.Templates);
                        } catch (e) {
                        }
                        ///console.log(this.templates_array)
                    }
                    let cohortid = localStorage.getItem('selectedCohort');
                    if (cohortid == null) {
                        cohortid = "";
                        this.progressselectindex = 0;
                        this.cohortid = this.Cohorts[0]["value"];
                        this.country = this.Cohorts[0]["country"];
                        if (this.country == null) this.country = "";
                    }
                    else {
                        this.Cohorts.length > 0
                            && this.Cohorts.map((item, i) => {
                                if (item.value == cohortid) {
                                    this.progressselectindex = i;
                                    this.country = item.country;
                                    this.cohortid = item.value;
                                    this.setState({ displayCohort: [{ label: item.label, value: item.value }] });
                                }

                            }, this);
                    }
                    // console.log("country=",this.country);
                    if (this.cohortid == "" || this.cohortid == null) alert("Please select cohort.");
                    //this.props.dispatch(FetchCertificateTemplate(fetchcertificatetemplateurl+"/?cohortid="+this.cohortid));            
                } catch (error) {
                    console.log("error==========", error);
                    this.enabledbt = false;
                    this.enablenextstep = false;
                    this.setState({ alertData: { status: 'warning', className: 'warning', message: "There are no added cohorts" } });

                }

            }
            else {
                this.enabledbt = false;
                this.enablenextstep = false;
                this.setState({ alertData: { status: 'warning', className: 'warning', message: prevProps.Cohorts.message } });
            }
        }
        if (prevProps.Profile) {
            console.log("prevProps.Profile=", prevProps.Profile);
            if (prevProps.Profile.Status === 200) {
                //this.setState({cohortsearchmode:prevProps.Profile.Data.cohortsearchmode}); 
                this.setState({ cohortsplitlength: prevProps.Profile.Data.cohortsplitlength });
            }
        }
    }
    closeAlert() {
        let alertData = {
            status: false,
            className: '',
            message: ''
        }
        this.setState({ alertData: alertData })
    }
    renderItems() {
        const items = [];
        for (const key in this.templates_array) {
            let index = key;
            let template_label = this.templates_array[key];
            //let template_label = value;
            //console.log(index+"="+template_label)   ;
            items.push(
                <div className="imageThumb">
                    <a href="javascript:void(0)" className="cohort_template" onClick={() => this.nextStepForFirst(index)}>
                        <img src={"images/thumbnails/template" + index + ".png"} width="155" height="120"></img>
                        <p>{template_label}</p>
                    </a>
                </div>
            )
        }
        return items;
    }
    renderBody() {
        if (this.state.selectedCertType == "Email") {
            return (
                <div id="maincontainer">
                    <div id="maintopStrip"></div>
                    <div id="mainContent">
                        <div id="mainprogress">
                            <a className="steps current" id="step1">
                                <cufon className="cufon cufon-canvas" alt="Select " style={{ "width": "62px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Select Template</cufontext>
                                </cufon>

                            </a>
                            <a className="steps" id="step2">
                                <cufon className="cufon cufon-canvas" alt="Personalize" style={{ "width": "107px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Personalize</cufontext>
                                </cufon>
                            </a>
                            <a className="steps" id="step3">
                                <cufon className="cufon cufon-canvas" alt="Download" style={{ "width": "96px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Save & Preview</cufontext>
                                </cufon>
                            </a>
                            <center>
                                <div className="seldesignh1">
                                    <cufon className="cufon cufon-canvas" alt="Select " style={{ "width": "84px", " height": "27px" }}>
                                        <cufontext className="seldesign">Select Email Template</cufontext>
                                    </cufon>
                                </div>
                                <div className="imageThumb">
                                    <a href="javascript:void(0)" className="cohort_template" onClick={() => this.nextStepForFirst("CertificateEmailTemplate")}>
                                        <img src={"images/thumbnails/certificateemailtemplate.png"} width="155" height="120"></img>
                                        <p>Certificate Email</p>
                                    </a>
                                </div>
                                <div className="imageThumb">
                                    <a href="javascript:void(0)" className="cohort_template" onClick={() => this.nextStepForFirst("InvitationEmailTemplate")}>
                                        <img src={"images/thumbnails/invitationemailtemplate.png"} width="155" height="120"></img>
                                        <p>Invitation Email</p>
                                    </a>
                                </div>
                                <div className="imageThumb">
                                    <a href="javascript:void(0)" className="cohort_template" onClick={() => this.nextStepForFirst("OpenBadgeEmailTemplate")}>
                                        <img src={"images/thumbnails/openbadgeemailtemplate.png"} width="155" height="120"></img>
                                        <p>OpenBadge Email</p>
                                    </a>
                                </div>
                            </center>
                        </div>
                    </div>
                    <div id="bottomStrip"></div>
                </div>
            );
        }
        else if (this.state.selectedCertType == "Badge") {
            return (
                <div id="maincontainer">
                    <div id="maintopStrip"></div>
                    <div id="mainContent">
                        <div id="mainprogress">
                            <a className="steps current" id="step1">
                                <cufon className="cufon cufon-canvas" alt="Select " style={{ "width": "62px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Select Template</cufontext>
                                </cufon>

                            </a>
                            <a className="steps" id="step2">
                                <cufon className="cufon cufon-canvas" alt="Personalize" style={{ "width": "107px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Personalize</cufontext>
                                </cufon>
                            </a>
                            <a className="steps" id="step3">
                                <cufon className="cufon cufon-canvas" alt="Download" style={{ "width": "96px", "height": "19px" }}>
                                    <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                    <cufontext>Save & Preview</cufontext>
                                </cufon>
                            </a>
                            <center>
                                <div className="seldesignh1">
                                    <cufon className="cufon cufon-canvas" alt="Select " style={{ "width": "84px", " height": "27px" }}>
                                        <cufontext className="seldesign">Select OpenBadge Email Template</cufontext>
                                    </cufon>
                                </div>
                                <div className="imageThumb">
                                    <a href="javascript:void(0)" className="cohort_template" onClick={() => this.nextStepForFirst(16)}>
                                        <img src={"images/thumbnails/template15.png"} width="155" height="120"></img>
                                        <p>OpenBadge Email</p>
                                    </a>
                                </div>
                            </center>
                        </div>
                    </div>
                    <div id="bottomStrip"></div>
                </div>
            );
        }
        else {
            return (
                <div id="maincontainer">
                    <div id="maintopStrip"></div>
                    <div id="mainContent">
                        <div id="mainprogress">
                            <a className="steps current" id="step1">

                                <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                Select design


                            </a>
                            <a className="steps" id="step2">

                                <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                Personalize

                            </a>
                            <a className="steps" id="step3">

                                <canvas width="10" height="22" style={{ "width": "1px", "height": "22px", "top": "-3px", "left": "-1px" }}></canvas>
                                Save & Preview

                            </a>
                            <center>
                                <div className="seldesignh1">
                                    Select certificate design
                                </div>
                                {this.renderItems()}
                            </center>
                        </div>
                    </div>
                    <div id="bottomStrip"></div>
                </div>
            );
        }

    }
    // changeCertType = (e) => {
    //     let idx = e.target.selectedIndex;
    //     this.certtypeselectindex=idx;
    //     let value = e.target.options[idx].value;        
    //     this.setState({selectedCertType:value});         
    // }
    isNumeric(str) {
        if (typeof str != "string") return false;
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    changeHandleMultipleCohort = () => {
        var collength = 0;
        var cohortList = [];
        for (let index = 0; index < this.Cohorts.length; index++) {
            let cohortsplitarr = this.Cohorts[index].value.split('-');
            collength = cohortsplitarr.length;
            cohortList.push(this.Cohorts[index].value);
        }
        let combinedOptions = '';
        for (let i = 0; i < collength; i++) {
            const dropdown = document.getElementById(`dropdown-${i}`);
            if (dropdown) {
                combinedOptions += dropdown.value;
                if (i < collength - 1) {
                    combinedOptions += '-';
                }
            }
        }
        console.log(combinedOptions);
        // if(cohortList.indexOf(combinedOptions)==-1)
        // {
        //     this.setState({alertData:{status:'empty',className: 'warning', message: 'The selected batch does not exist'}})            
        // }
        // else
        {

            this.selectedCohort = combinedOptions;
            this.cohortid = combinedOptions;
            this.setState({ size: 1 });
            localStorage.setItem('selectedCohort', this.selectedCohort);

        }
    }

    renderMultiCohort() {
        var cohortmainarray = [];
        var dropdownarray = [];
        var collength = 0;

        let cohortid = localStorage.getItem('selectedCohort');
        if (cohortid == null || cohortid == undefined) {
            if (this.Cohorts[0] == null || this.Cohorts[0] == undefined) return;
            cohortid = this.Cohorts[0].value;
        }
        if (cohortid == null || cohortid == undefined) return;
        this.selectedCohort = cohortid;

        var selectedOptions = [];

        selectedOptions = this.selectedCohort.split('-');
        for (let index = 0; index < this.Cohorts.length; index++) {
            let cohortsplitarr = this.Cohorts[index].value.split('-');
            collength = cohortsplitarr.length;
            cohortmainarray.push(cohortsplitarr);
        }


        const selectStyle0 = {
            borderColor: 'rgb(206, 212, 218)',
            height: '35px',
            borderRadius: '0.25rem',
            padding: '0px 2px',
            width: '145px'
        };
        const selectStyle1 = {
            borderColor: 'rgb(206, 212, 218)',
            height: '35px',
            borderRadius: '0.25rem',
            padding: '0px 2px',
            width: '70px'
        };
        const selectStyle2 = {
            borderColor: 'rgb(206, 212, 218)',
            height: '35px',
            borderRadius: '0.25rem',
            padding: '0px 2px',
            width: '86px'
        };
        var selectStyle = {};
        for (let index1 = 0; index1 < collength; index1++) {
            let dropdownOptions = [];
            let tmpDropdownOptions = [];
            for (let index = 0; index < cohortmainarray.length; index++) {
                let cohortsplitarr = cohortmainarray[index];
                if (tmpDropdownOptions.indexOf(cohortsplitarr[index1]) == -1) {
                    if (selectedOptions[index1] == cohortsplitarr[index1]) dropdownOptions.push(<option selected={true} key={index}>{cohortsplitarr[index1]}</option>);
                    else dropdownOptions.push(<option key={index}>{cohortsplitarr[index1]}</option>);
                    tmpDropdownOptions.push(cohortsplitarr[index1]);
                }
            }
            if (index1 == 0 || index1 == 5) selectStyle = selectStyle0;
            else if (index1 == 1 || index1 == 2 || index1 == 4) selectStyle = selectStyle1;
            else selectStyle = selectStyle2;
            dropdownarray.push(
                <select
                    style={selectStyle}
                    key={index1}
                    id={`dropdown-${index1}`} // Unique ID for each dropdown
                    onChange={() => this.changeHandleMultipleCohort()}
                >
                    {dropdownOptions}
                </select>
            );
        }

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        };

        return (
            <div style={containerStyle}>
                {dropdownarray}
                {/* <button
                    style={{ borderRadius: "0.25rem", height: "36px", width:'60px' }}
                    className="input-group-text"
                    onClick={this.cohortSearchAction}
                >
                    <i className="fa fa-search" style={{marginLeft:'auto',marginRight:'auto'}}></i>
                </button> */}
            </div>
        );

    }

    render() {
        require("./style.css");
        const { step } = this.state;
        var alertData = this.state.alertData;

        let cohortListForProgress = this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if (this.progressselectindex == i && i !== 0) {
                    return (
                        <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else if (this.progressselectindex !== i && i !== 0) {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    )
                }
            }, this);
        let certtypeList = this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if (this.certtypeselectindex == i) {

                    return (
                        <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    )
                }
            }, this);
        switch (step) {
            case 1:
                return (
                    <section className="project-tab">
                        {this.state.con && (<Spinner></Spinner>)}
                        {(alertData.status) ? (<Alert variant={alertData.className} onClose={() => this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
                        <div className="row">


                            {/* <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label style={{ marginTop: "7px", fontSize: "14px", marginRight: "8px" }}>Certificate-Type</label>
                                    <select
                                        style={{
                                            padding: "7px 10px",
                                            borderColor: "rgb(206, 212, 218)",
                                            marginTop: "1px",
                                            borderRadius: "4px",
                                            fontSize: "12px",
                                            marginRight:"50px"
                                        }}
                                        onChange={this.changeCertType}
                                    >
                                        {certtypeList}
                                    </select>
                                    <label style={{ marginTop: "8px", fontSize: "14px", marginRight: "8px" }}>Batch</label>
                                    {this.state.cohortsearchmode === 0 ? (
                                        <Select
                                            className="cohortDropdown"
                                            style={{
                                                borderColor: "rgb(206, 212, 218)",
                                                borderRadius: "4px",
                                                width: "100%", // Adjust width to match parent container
                                            }}
                                            values={this.state.displayCohort}
                                            options={this.Cohorts}
                                            clearable={true}
                                            placeholder=""
                                            onChange={(values) => this.setCohort(values)}
                                        />
                                    ) : (
                                        this.renderMultiCohort()
                                    )}
                                    <label style={{ marginTop: "8px", fontSize: "14px" }}>{this.country}</label>
                                </div>
                            </div> */}


                        </div>
                        {this.renderBody()}
                    </section>
                )
            case 2:
                if (this.template_num == "CertificateEmailTemplate") {
                    return (
                        <div>
                            <CertificateEmailTemplate
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                setTemplateContent={this.setTemplateContent}
                                value={this.template_content}
                                value_fromdb={this.template_content_from_db}

                            />
                        </div>
                    )
                }
                else if (this.template_num == "InvitationEmailTemplate") {
                    return (
                        <div>
                            <InvitationEmailTemplate
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                setTemplateContent={this.setTemplateContent}
                                value={this.template_content}
                                value_fromdb={this.template_content_from_db}

                            />
                        </div>
                    )
                }
                else if (this.template_num == "OpenBadgeEmailTemplate") {
                    return (
                        <div>
                            <OpenBadgeEmailTemplate
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                setTemplateContent={this.setTemplateContent}
                                value={this.template_content}
                                value_fromdb={this.template_content_from_db}

                            />
                        </div>
                    )
                }
                else {
                    let TmpTemplate = React.lazy(() => import("../../components/Templates/Template" + (parseInt(this.template_num))));
                    if (this.template_num == 9 || this.template_num == 44 || this.template_num == 45 || this.template_num == 58 || this.template_num == 59) {
                        return (
                            <div><Suspense fallback={<Spinner></Spinner>} >
                                <TmpTemplate
                                    nextStep={this.nextStep}
                                    prevStep={this.prevStep}
                                    setTemplateContent={this.setTemplateContent}
                                    setPreviewContent={this.setPreviewContent}
                                    value_fromdb={this.template_content_from_db}
                                    value={this.template_content}
                                    cohortid={this.cohortid}
                                    value_openbadges_fromdb={this.template_openbadges_content_from_db}
                                    value_openbadges={this.template_openbadges_content}
                                    setOpenBadgesTemplateContent={this.setOpenBadgesTemplateContent}
                                />
                            </Suspense>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div><Suspense fallback={<Spinner></Spinner>} >
                                <TmpTemplate
                                    nextStep={this.nextStep}
                                    prevStep={this.prevStep}
                                    setTemplateContent={this.setTemplateContent}
                                    setPreviewContent={this.setPreviewContent}
                                    value={this.template_content}
                                    value_fromdb={this.template_content_from_db}
                                    cohortid={this.cohortid}
                                />
                            </Suspense>
                            </div>
                        )
                    }

                }
            case 3:
                return (
                    <div>
                        <PreviewTemplate
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            value={this.template_content}
                            value_openbadges={this.template_openbadges_content}
                            cohortid={this.cohortid}
                            previewContent={this.preview_content}
                        />
                    </div>
                )


            default:
                break;
        }
    }
}

function mapStateToProps(state, actions) {
    if (state.fetchCertificateTemplate && state.fetchCertificateTemplate.Data && state.fetchCertificateTemplate.Data.Status) {
        return { template_content_status: state.fetchCertificateTemplate.Data }
    }
    else if (state.fetchCohortsData && state.fetchCohortsData.Data && state.fetchCohortsData.Data.Status) {
        return { Cohorts: state.fetchCohortsData.Data }
    }
    else if (state.fetchProfileData && state.fetchProfileData.Data && state.fetchProfileData.Data.Status) {
        return { Profile: state.fetchProfileData.Data }
    }
    else {
        return {}
    }
}
export default connect(mapStateToProps)(withRouter(DesignCertificateComponent));