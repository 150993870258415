import {UPDATE_CERTIFICATE_TEMPLATE_SUCCESS,UPDATE_CERTIFICATE_TEMPLATE_LOADING,UPDATE_CERTIFICATE_TEMPLATE_FAILURE,UPDATE_CERTIFICATE_TEMPLATE_COMPLETE,
        GET_TEMPLATE_SUCCESS,GET_TEMPLATE_LOADING,GET_TEMPLATE_FAILURE,GET_TEMPLATE_COMPLETE,
        SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE, SEND_GENERATEPREVIEWCERTIFICATE_FAILURE, SEND_GENERATEPREVIEWCERTIFICATE_LOADING, SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS,
        SEND_BAKEPREVIEWOPENBADGES_COMPLETE, SEND_BAKEPREVIEWOPENBADGES_FAILURE, SEND_BAKEPREVIEWOPENBADGES_LOADING, SEND_BAKEPREVIEWOPENBADGES_SUCCESS,
        SEND_GETLOGOIMAGE_COMPLETE, SEND_GETLOGOIMAGE_FAILURE, SEND_GETLOGOIMAGE_LOADING, SEND_GETLOGOIMAGE_SUCCESS,
        SEND_GETSIGNATUREIMAGE_COMPLETE, SEND_GETSIGNATUREIMAGE_FAILURE, SEND_GETSIGNATUREIMAGE_LOADING, SEND_GETSIGNATUREIMAGE_SUCCESS,
        SEND_GETPARTNER_COMPLETE, SEND_GETPARTNER_FAILURE, SEND_GETPARTNER_LOADING, SEND_GETPARTNER_SUCCESS,
    } from './types';

require('es6-promise').polyfill();
require('isomorphic-fetch');
var fetchTimeout = require('fetch-timeout');

//Issue certificate daemon

export function updatecertificatetemplateLoading(bool) {
    
    return {
        type: UPDATE_CERTIFICATE_TEMPLATE_LOADING,
        isLoading: bool
    };
}
export function updatecertificatetemplateSuccess(items) {
    return {
        type: UPDATE_CERTIFICATE_TEMPLATE_SUCCESS,
        Data:items
    };
}

export function updatecertificatetemplateComplete(status) {
    return {
        type: UPDATE_CERTIFICATE_TEMPLATE_COMPLETE,
        isCompleted: status
    };
}
export function updatecertificatetemplateFailed(msg) {
    return {
        type: UPDATE_CERTIFICATE_TEMPLATE_FAILURE,
        hasErrored: msg
    };
}
export function UpdateCertificateTemplate(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json'
             }
            })
                .then((response) => {
                if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch( updatecertificatetemplateLoading(false));
            return response;
            })
            .then(response => {
                    return response.json();
            })
            .then((eventData) => {
                    dispatch( updatecertificatetemplateSuccess(eventData));
                dispatch(updatecertificatetemplateComplete(true));
            })
            .catch((err) => {
                    dispatch( updatecertificatetemplateFailed(err.message))
            });
    };
}

export function fetchcertificatetemplateLoading(bool) {
    
    return {
        type: GET_TEMPLATE_LOADING,
        isLoading: bool
    };
}
export function fetchcertificatetemplateSuccess(items) {
    return {
        type: GET_TEMPLATE_SUCCESS,
        Data:items
    };
}

export function fetchcertificatetemplateComplete(status) {
    return {
        type: GET_TEMPLATE_COMPLETE,
        isCompleted: status
    };
}
export function fetchcertificatetemplateFailed(msg) {
    return {
        type: GET_TEMPLATE_FAILURE,
        hasErrored: msg
    };
}
export function FetchCertificateTemplate(url) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetch(url, {
            method: "GET",            
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,                
             }
            })
                .then((response) => {
                if (!response.ok) {
                throw Error(response.statusText);
            }
            dispatch( fetchcertificatetemplateLoading(false));
            return response;
            })
            .then(response => {
                    return response.json();
            })
            .then((eventData) => {
                    dispatch( fetchcertificatetemplateSuccess(eventData));
                dispatch(fetchcertificatetemplateComplete(true));
            })
            .catch((err) => {
                    dispatch( fetchcertificatetemplateFailed(err.message))
            });
    };
}

//Generate PreviewCertificate
export function generatePreviewCertificateFetchFailed(msg) {
    return {
        type: SEND_GENERATEPREVIEWCERTIFICATE_FAILURE,
        hasErrored: msg
    };
}

export function generatePreviewCertificateFetchLoading(bool) {
    return {
        type: SEND_GENERATEPREVIEWCERTIFICATE_LOADING,
        isLoading: bool
    };
}

export function generatePreviewCertificateFetchSuccess(items) {
    return {
        type: SEND_GENERATEPREVIEWCERTIFICATE_SUCCESS,
        Data:items
    };
}

export function generatePreviewCertificateFetchComplete(status) {
    return {
        type: SEND_GENERATEPREVIEWCERTIFICATE_COMPLETE,
        isCompleted: status
    };
}

export function GeneratePreviewCertificate(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( generatePreviewCertificateFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( generatePreviewCertificateFetchSuccess(eventData));
        dispatch(generatePreviewCertificateFetchComplete(true));
    })
    .catch((err) => {
            dispatch( generatePreviewCertificateFetchFailed(err.message))
    }
    );
    };
}

//Bake PreviewOpenBadges
export function bakePreviewOpenBadgesFetchFailed(msg) {
    return {
        type: SEND_BAKEPREVIEWOPENBADGES_FAILURE,
        hasErrored: msg
    };
}

export function bakePreviewOpenBadgesFetchLoading(bool) {
    return {
        type: SEND_BAKEPREVIEWOPENBADGES_LOADING,
        isLoading: bool
    };
}

export function bakePreviewOpenBadgesFetchSuccess(items) {
    return {
        type: SEND_BAKEPREVIEWOPENBADGES_SUCCESS,
        Data:items
    };
}

export function bakePreviewOpenBadgesFetchComplete(status) {
    return {
        type: SEND_BAKEPREVIEWOPENBADGES_COMPLETE,
        isCompleted: status
    };
}

export function BakePreviewOpenBadges(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( generatePreviewCertificateFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( generatePreviewCertificateFetchSuccess(eventData));
        dispatch(generatePreviewCertificateFetchComplete(true));
    })
    .catch((err) => {
            dispatch( generatePreviewCertificateFetchFailed(err.message))
    }
    );
    };
}

//Get Logo Image
export function getLogoImageFetchFailed(msg) {
    return {
        type: SEND_GETLOGOIMAGE_FAILURE,
        hasErrored: msg
    };
}

export function getLogoImageFetchLoading(bool) {
    return {
        type: SEND_GETLOGOIMAGE_LOADING,
        isLoading: bool
    };
}

export function getLogoImageFetchSuccess(items) {
    return {
        type: SEND_GETLOGOIMAGE_SUCCESS,
        Data:items
    };
}

export function getLogoImageFetchComplete(status) {
    return {
        type: SEND_GETLOGOIMAGE_COMPLETE,
        isCompleted: status
    };
}

export function GetLogoImage(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( getLogoImageFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( getLogoImageFetchSuccess(eventData));
        dispatch(getLogoImageFetchComplete(true));
    })
    .catch((err) => {
            dispatch( getLogoImageFetchFailed(err.message))
    }
    );
    };
}

//Get Signature Image
export function getSignatureImageFetchFailed(msg) {
    return {
        type: SEND_GETSIGNATUREIMAGE_FAILURE,
        hasErrored: msg
    };
}

export function getSignatureImageFetchLoading(bool) {
    return {
        type: SEND_GETSIGNATUREIMAGE_LOADING,
        isLoading: bool
    };
}

export function getSignatureImageFetchSuccess(items) {
    return {
        type: SEND_GETSIGNATUREIMAGE_SUCCESS,
        Data:items
    };
}

export function getSignatureImageFetchComplete(status) {
    return {
        type: SEND_GETSIGNATUREIMAGE_COMPLETE,
        isCompleted: status
    };
}

export function GetSignatureImage(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( getSignatureImageFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( getSignatureImageFetchSuccess(eventData));
        dispatch(getSignatureImageFetchComplete(true));
    })
    .catch((err) => {
            dispatch( getSignatureImageFetchFailed(err.message))
    }
    );
    };
}

//Get Partner
export function getPartnerFetchFailed(msg) {
    return {
        type: SEND_GETPARTNER_FAILURE,
        hasErrored: msg
    };
}

export function getPartnerFetchLoading(bool) {
    return {
        type: SEND_GETPARTNER_LOADING,
        isLoading: bool
    };
}

export function getPartnerFetchSuccess(items) {
    return {
        type: SEND_GETPARTNER_SUCCESS,
        Data:items
    };
}

export function getPartnerFetchComplete(status) {
    return {
        type: SEND_GETPARTNER_COMPLETE,
        isCompleted: status
    };
}

export function GetPartner(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
    return (dispatch) => {
        fetchTimeout(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            mode: "no-cors",
            headers: {
                'Authorization':  Token,                
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*'
            }
        },60*60*1000)
            .then((response) => {
            if (!response.ok) {
            throw Error(response.statusText);
        }
        dispatch( getPartnerFetchLoading(false));
        return response;
    })
    .then(response => {
            return response.json();
    })
    .then((eventData) => {
            dispatch( getPartnerFetchSuccess(eventData));
        dispatch(getPartnerFetchComplete(true));
    })
    .catch((err) => {
            dispatch( getPartnerFetchFailed(err.message))
    }
    );
    };
}