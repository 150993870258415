import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { server_ip,verifier_ip} from "../../utils/constants";
import './styles.css'; // Import the CSS file
class HeaderandFooter extends Component {
    constructor(props) {
        super(props)


        this.Logout = this.Logout.bind(this);
        this.openGetStarted = this.openGetStarted.bind(this);
    }



    Logout() {
        localStorage.clear();
        window.location.href=server_ip;
        //this.props.history.push('/');
        // window.location.reload();
    }


    componentDidMount() {

        //console.log("test:", window.location.pathname)

        if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "Login" || window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "/Register") {

            if (localStorage.getItem('Token')) {
                console.log("Token")
                this.props.history.push('/Dashboard');
            }
            else {
                console.log("No token")
            }

        }
        else {

            if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "CertOnceDemo") {
                this.props.history.push('/CertOnceDemo');
            }
            else if(window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "HomeCertOnceDemo")
            {
                  //this.props.history.push('/HomeCertOnceDemo');
            }
            else if(window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "RegisterDemo")
            {
                  this.props.history.push('/RegisterDemo');
            }
            else if (!localStorage.getItem('Token')) {
                this.props.history.push('/login');
            }

        }

    }
    
     openBlockcertsVerifier(){

       var token = localStorage.getItem('Token');
       if (!token) {            
            window.open(verifier_ip+"/verifier.php");
        } 
        else
        {
            var user_id = localStorage.getItem('user_id');        
            var organization_verify_uri = localStorage.getItem('organizationverifyuri');
            if(organization_verify_uri==""||organization_verify_uri=="null"||organization_verify_uri==null||typeof organization_verify_uri=="undefined") organization_verify_uri="certonce";
            window.open(verifier_ip+"/verifier/"+organization_verify_uri+"/"+user_id);
        }
    }    
    openGetStarted(){
        window.open(server_ip+"/#/HomeCertOnceDemo","_self");
    } 
    goScroll=e=>
    {
        e.preventDefault();        
        if (window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "" ||!window.location.href.substring(window.location.href.lastIndexOf('/') + 1))
        {
           window.goScroll(e.currentTarget.href.substring(e.currentTarget.href.lastIndexOf('/') + 1));
        }

    }
   GetLoginMenu() {
       var token = localStorage.getItem('Token');
       if (!token) {
            return (
                <div  style={{"float":"right","position":"relative","left":"-44px","top":"-70px"}}>                                
                    <Link to="/login" className="primary-btn"> Login </Link>
                </div>
            )

        }
   }
    GetMenu() {

        var token = localStorage.getItem('Token');
        var selectedNumber=0;
        const url = window.location.href;
        if(url.indexOf("Dashboard")!=-1) selectedNumber=0;
        if(url.indexOf("ManageStudents")!=-1) selectedNumber=1;
        if(url.indexOf("DesignCertificate")!=-1) selectedNumber=2;
        if(url.indexOf("ManageCertificate")!=-1) selectedNumber=3;
        if(url.indexOf("Verification")!=-1) selectedNumber=4;
        if(url.indexOf("MyProfile")!=-1) selectedNumber=5;
        if (token) {
            return (
                <nav id="nav-menu-container">                    
                    <ul className="nav-menu">
                        <li className="menu-active"><NavLink to="/Dashboard" className={selectedNumber==0 ? "active-link" : ""}>Dashboard</NavLink></li>
                        <li className="menu-active"><NavLink to="/ManageStudents" className={selectedNumber==1 ? "active-link" : ""}>Manage Students</NavLink></li>
                        <li className="menu-active"><NavLink to="/DesignCertificate" className={selectedNumber==2 ? "active-link" : ""}>Design Certificate</NavLink></li>
                        <li className="menu-active"><NavLink to="/ManageCertificate" className={selectedNumber==3 ? "active-link" : ""}>Manage Certificate</NavLink></li>
                        <li className="menu-active"><NavLink to="/Verification" className={selectedNumber==4 ? "active-link" : ""}>Verification</NavLink></li>
                        {/* <li className="menu-active"><a href="javascript:void(0)" onClick={this.openBlockcertsVerifier} >Blockcerts Verifier</a></li> */}
                        {/*<li className="menu-active"><Link to ="/MyCertificates">My Certificates</Link></li>*/}
                        <li className="menu-active"><NavLink to="/MyProfile" className={selectedNumber==5 ? "active-link" : ""}>My Profile</NavLink></li>
                        <li className="menu-active"><a href="javascript:void(0)" onClick={this.Logout} >Logout</a></li> 
                    </ul>
                </nav>
            )

        }
        else {

            return (
                   <nav style={{"display":"none"}} className="navbar navbar-expand-lg navbar-light">

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                            <li className="nav-item ">
                                <a className="nav-link" href="/">HOME <span className="sr-only">(current)</span></a>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                SOLUTION
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item id" href="#product" onClick={this.goScroll}>Product</a>
                                <a className="dropdown-item" href="">How It Works</a>
                                <a className="dropdown-item" href="FAQ.html">FAQ</a>
                                <a className="dropdown-item" href="samples.html">Sample Certificates</a>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                COMPANY
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="about.html">About</a>
                                <a className="dropdown-item" href="blogs.html">Blogs</a>
                                <a className="dropdown-item" href="jobs.html">Jobs</a>
                                <a className="dropdown-item" href="events.html">Events</a>
                                </div>
                            </li>


                            <li className="nav-item"><a className="nav-link" href="#" onClick={this.openBlockcertsVerifier} >Blockcerts Verifier</a></li>
                            <li className="nav-item"><Link to="HomeCertOnceDemo" className="hrefstyle ticker-btn">Get Started</Link></li>
                            <li className="nav-item"><Link to="/login" className="nav-link hrefstyle"> Login </Link></li>
                            </ul>
                        </div>
                        </nav>


            )
        }
    }


    render() {

        //console.log(this.props.children);


        return (<span>

            <header id="home">
                <div className="container">
                    <div className="row align-items-center justify-content-between d-flex">
                        <div id="logo">
                            <a href={server_ip}><img src="img/certonce_logo.png" alt="" title="" style={{"width":"200px"}} /></a>
                        </div>
                        {this.GetMenu()}                
                    </div>
                </div>
                {this.GetLoginMenu()}    
            </header>




            {this.props.children}



            <footer className="footer-area section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>About Us</h6>
                                <p>
                                    We transform the way organizations leverage blockchain technology in managing digital records.
				</p>
                                <p className="footer-text">

                                    Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com/" target="_blank">Colorlib</a>

                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5  col-md-6 col-sm-6">
                            <div className="single-footer-widget">
                                <h6>Newsletter</h6>
                                <p>Stay update with our latest</p>
                                <div className="" id="mc_embed_signup">
                                    <form target="_blank" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01" method="get" className="form-inline">
                                        <input className="form-control" name="EMAIL" placeholder="Enter Email" required="" style={{"height":"41px"}} type="email" />
                                        <button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                                        <div className="footer-one">
                                            <input name="b_36c4fd991d266f23781ded980_aefe40901a" tabIndex="-1" type="text" />
                                        </div>

                                        <div className="info"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 social-widget">
                            <div className="single-footer-widget">
                                <h6>Follow Us</h6>
                                <p>Let us be social</p>
                                <div className="footer-social d-flex align-items-center">
                                    <a href="#"><i className="fa fa-facebook"></i></a>
                                    <a href="#"><i className="fa fa-twitter"></i></a>
                                    <a href="#"><i className="fa fa-dribbble"></i></a>
                                    <a href="#"><i className="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <ToastsContainer store={ToastsStore} />
        </span>
        )

    }
}



function mapStateToProps(state, actions) {
    return {};

}


export default connect(mapStateToProps)(withRouter(HeaderandFooter));

