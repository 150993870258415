import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import "./style.css";
import Spinner from "../../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import {Alert,Button, Modal} from 'react-bootstrap';
import { Markup } from 'interweave';
import PropTypes from 'prop-types';



class CertificateEmailTemplateComponent extends Component {
    initrightsidedata="";
    constructor(props) {
        super(props); 
        this.template_content_gl=this.props.value;       
        this.state={
            con: false,            
            alertData: {
                status: false,
                className: '',
                message: ''
            },            

            idl1:"A Digital credential has been issued by ORGANIZATION_NAME",
            idl2:`<p style="color:black;">Hello FIRST_NAME</p>
<p style="color:black;">You have been issued a blockchain secured digital credential by ORGANIZATION_NAME.</p>                            
<p><b>What do you do it?</b></p>
<ul>
  <li type= "none" style="color:black;">  &ndash; This is a <a href="BASE_URL">Tamper proof</a> and <a href="JSON_URL">Trusted digital credential</a> that you can share with anyone, a
future employer or any authority and they can <a href="VERIFIER_URL">instantly verify</a> independent of the issuer ORGANIZATION_NAME.</li>    
  <li type= "none" style="color:black;">  &ndash; This is a <a href="PDF_URL">Trusted pdf digital credential.</a></li>
</ul>

<p ><b>How do you share it?</b></p>
<ul>
    <li type= "none" style="color:black;">  &ndash; You can share this VERIFIER_URL on any social media or professional platform or forward on Whatsapp or mail.</li>
</ul>


<p style="color:black;"><b>How the receiver will verify it?</b></p>
<ul>
    <li type= "none" style="color:black;">  &ndash; The receiver can go to <a href="VERIFIER_URL">this verification link</a> and just post the link shared by you and verify the
authenticity of this certificate</li>    
</ul>

<p style="color:black;">We are excited to have you join as a part of this digital credentialing revolution that is bringing back
the trust in digital documents.</p>
<p style="color:black;">Read More about advantages of Blockchain Secured Credentials.</p>
<p style="color:black;">Warm regards</p>
<p style="color:black;">Team CertOnce</p>
`,
             
        }
        
        
    }
 

 
nextFn =e=> {
    e.preventDefault(); 
    var payload={"ismailtemplate":"true","mail_type":"certificate","subject":document.getElementById("idl1").value,"mail_content":document.getElementById("idl2").value};    
    //console.log(payload)
    this.props.setTemplateContent(JSON.stringify(payload));    
    this.props.nextStep();
  }
prevFn =e=> {
e.preventDefault();    
this.props.prevStep();
}

preview=()=>{    
    document.getElementById("templatediv").innerHTML=this.initrightsidedata;
    

    var array1=(document.getElementById("idl1").value).split("\n");    
    document.getElementById("idr1").innerHTML=array1.join("<br>"); 

    var array2=(document.getElementById("idl2").value).split("\n");    
    document.getElementById("idr2").innerHTML=array2.join("<br>");  

    
}

componentDidMount()
{
    if(this.initrightsidedata=="")
    {
     this.initrightsidedata =document.getElementById("templatediv").innerHTML;  
    }

    if(this.props.value_fromdb!=""&&this.props.value_fromdb!=null&&this.props.value=="")
    {
       //document.getElementById("templatediv").innerHTML=this.props.value_fromdb;
    }
    
    if(this.props.value!="")
    {
        //document.getElementById("templatediv").innerHTML=this.props.value;
    }
}
componentDidUpdate(prevProps, prevState) {
    
}

closeAlert() {
    let alertData = {
        status: false,
        className: '',
        message: ''
    }
    this.setState({alertData:alertData})
}






render() {

    const {placeholder} = this.state;
    var alertData = this.state.alertData;        
    return (
        <section className="project-tab">
        {this.state.con && (<Spinner></Spinner>)}
        { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}         
            <div id="container5">
            <div id="topStrip5"></div>                
            <div id="mainContent5">	
            <div id="progress">
                    <a className="steps" id="step1">
                    <cufon className="cufon cufon-canvas" alt="Select " style={{"width": "62px","height": "19px"}}>
                        <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                        <cufontext>Select design</cufontext>
                    </cufon>                        
                    </a>
                    <a className="steps current" id="step2">
                        <cufon className="cufon cufon-canvas" alt="Personalize" style={{"width": "107px", "height": "19px"}}>
                            <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                            <cufontext>Personalize</cufontext>
                        </cufon>
                    </a>
                    <a className="steps" id="step3">
                        <cufon className="cufon cufon-canvas" alt="Download" style={{"width": "96px", "height": "19px"}}>
                            <canvas width="10" height="22" style={{"width": "0px","height": "22px","top": "-3px","left": "-1px"}}></canvas>
                            <cufontext>Save</cufontext>
                        </cufon>
                    </a>
                    
                        
                    
                    </div>
                    <div className="row_margin row">
                        <div className="column" style={{"width":"30%"}}> 
                            <center>
                            <textarea id="idl1" style={{"border":"1px solid black","height":"10px","fontSize":"13px","padding":"10px","maxHeight":"90px","minHeight":"90px","borderRadius":"7px"}} placeholder="Subject" className="input2" onChange={e=>this.setState({idl1:e.target.value})} defaultValue={this.state.idl1}/>
                            <br/>
                            <textarea id="idl2" style={{"border":"1px solid black","height":"800px","fontSize":"13px","padding":"10px","minHeight":"90px","borderRadius":"7px"}} placeholder="Email Content" className="input2" onChange={e=>this.setState({idl2:e.target.value})} defaultValue={this.state.idl2}/>
                            </center>
                        </div>
                        <div className="column-r" id="templatediv" style={{"width":"68%","padding":"23px"}}>                                                            
                            <span style={{"fontSize": "19px","color": "black","fontFamily": "Arial","margin":"0px","marginTop":"7px"}}>Subject: </span><span id="idr1" ></span>
                            <br/>
                            <hr style={{"border": "1px solid black"}}/>
                            
                            <span id="idr2" ></span>
                        </div>
                    </div> 
                </div>		
                <div id="bottomStrip5"></div>
                <div  className="container-contact100-form-btn">
                            <button  className="contact100-form-btn preview" style={{"width": "111px"}} onClick={this.preview}>
                                <span>
                                    Preview
                                </span>
                            </button>
                        </div>

                        <div style={{"justifyContent": "left"}} className="container-contact100-form-btn">
                            
                            <button className="contact100-form-btn prev" onClick={this.prevFn}>
                                <span>
                                    Prev
                                </span>
                            </button>&nbsp;&nbsp;&nbsp;
                                <button className="contact100-form-btn next" onClick={this.nextFn}>
                                    <span>
                                        Next
                                    </span>
                            </button>
                        </div>
            </div>     
        </section>
    )}
    
}
function mapStateToProps(state, actions) {
    if (state.fetchStudentsData && state.fetchStudentsData.Data && state.fetchStudentsData.Data.Status) {
        return { Students: state.fetchStudentsData.Data}
    } /*else if (state.fetchCohortsData && state.fetchCohortsData.Data && state.fetchCohortsData.Data.Status) {
        return { Cohorts: state.fetchCohortsData.Data }
    }*/ else if(state.fetchSendInvitation && state.fetchSendInvitation.Data && state.fetchSendInvitation.Data.Status) {
        return { InvitationStatus: state.fetchSendInvitation.Data}
    }
    else if(state.fetchUploadStudents && state.fetchUploadStudents.Data && state.fetchUploadStudents.Data.Status) {
        return { UploadStudents: state.fetchUploadStudents.Data}
    }
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(CertificateEmailTemplateComponent));

// export default MyProfileView;