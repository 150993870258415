import React,{ Component } from 'react';

export class  Spinner extends Component {

    render(){
           return (
            <div id="cover-spin"></div>
    ); 
    }

};

export default Spinner;
