import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { GetStudentsFetchData, GetCohortsFetchData, SendInvitation, UploadStudentsFetchData,CopyEmailAsPkData, reduxUpdateInformation, DeleteStudentsData} from '../../redux/ManageStudents/actions';
import {PreviewStudentPhoto} from '../../redux/ManageCertificate/actions';
import {GetProfileFetchData} from '../../redux/Profile/actions';
import "./style.css";
import Spinner from "../Spinner";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import Select from "react-dropdown-select";
import * as XLSX from 'xlsx';
import {uploadstudentbyapi, getProfile, copyemailaspkurl, UPLOADSTUDENTS,UPLOADPHOTOS, UPLOADMULTIPHOTOS, getStudents,getCohorts,sendInvitation,server_ip, updateinformationurl, previewstudentphoto, deletestudent} from "../../utils/constants";
import {Alert,Button, Modal} from 'react-bootstrap';
import { Markup } from 'interweave';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import axios from 'axios';
import ApiDialog from './apidialog'; // Adjust the import path as necessary
// import { deleteStudentsData } from '../../redux/ManageStudents/reducer';

class ManageStudentsComponent extends Component {
    checkStats = {};
    selectedCustomDropdown={};
    cohortsearchmode=0;
    Cohorts = [];
    dropdownCohortArray={};
    CertType=[{"label":"Degree","value":"degree"},{"label":"Migration","value":"migration"},{"label":"Transfer","value":"transfer"},{"label":"Awards","value":"awards"},{"label":"Relieving","value":"relieving"},{"label":"Bonafide","value":"bonafide"},{"label":"Medal","value":"medal"},{"label":"Transcript","value":"transcript"},{"label":"Semester-wise Grade card","value":"semesterwisegradecard"},{"label":"OpenBadges","value":"openbadges"},{"label":"OR","value":"or"}];
    selectedCohort = '';
    selectedCertType='';
    files = [];
    photofiles = [];
    pageCount = 1;
    mainPageCount = 1;
    mainPageOffset=0;
    initialMainPageIndex=0;
    maxitemsperpage=50;
    cohortselectindex=0;
    certtypeselectindex=0;
    publicnewmeail="";
    isapimode=false;
    ipAddress = "";
    constructor(props) {
        super(props);
        this.state={
            con: true,
            dialogOpen: false,
            InvitationStatus: false,
            placeholder: "Choose File",
            Cohorts: [],
            Students: [],
            loaded: true,
            StudentsData: [],
            UploadStudents:[],
            alertData: {
                status: false,
                className: '',
                message: ''
            },
            showModal: false,
            showUpdateInformation:false,
            deleteStudentConfirmModal: false,
            newemail:"",
            newfirstname:"",
            newmiddlename:"",
            newlastname:"",
            newstudentid: "",
            newfacultyname: "",
            newfathername: "",
            newmothername: "",
            newphonenumber: "",
            newsecondemailaddress: "",
            newserialnumber: "",
            newspecializationone: "",
            newspecializationtwo: "",
            newcompetencyname: "",
            newcoursename: "",
            selectedcoid:"",
            selectedstdid:"",
            ModalContents:[],
            currentOffset : 0,
            searchValue:"",
            displayCohort: [],
            selectedphotoContent: "",
            selectedPhotoTitle: "",
            isRenderedCohort:false
        }
        this.GetStudents = this.GetStudents.bind(this);
        this.changeCohorts = this.changeCohorts.bind(this);
        this.GetCohorts = this.GetCohorts.bind(this);
        this.sendInvitation = this.sendInvitation.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeUpdateInformation = this.closeUpdateInformation.bind(this);
        this.closeDeleteStudentConfirmModal = this.closeDeleteStudentConfirmModal.bind(this);
        this.updateInformation = this.updateInformation.bind(this);
        this.delete_student = this.delete_student.bind(this);
        this.deleteStudentConfirm = this.deleteStudentConfirm.bind(this);        
        localStorage.setItem("selectedpageindex","0");  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;   
        this.selectPageSize = this.selectPageSize.bind(this);        
        //initialize ms        
    }
    GetCohorts() {
        this.setState({ con: true });     
        let payload = {
            certtypevalue:this.selectedCertType
        };   
        this.props.dispatch(GetCohortsFetchData(getCohorts, payload));
    }
    setNewEmail(pemail)
    {
        this.setState({"newemail":pemail});
    }
    setFirstName(name)
    {
        this.setState({"newfirstname":name});
    }
    setMiddleName(name)
    {
        this.setState({"newmiddlename":name});
    }
    setLastName(name)
    {
        this.setState({"newlastname":name});
    }
    setStudentInfo(keyword, value){
        this.setState({keyword: value});
    }
    selectPageSize = (e) => {
        let idx = e.target.selectedIndex;        
        let value = e.target.options[idx].value; 
        this.maxitemsperpage=value;
        

        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }
    copyemailaspk() {
        try {
            this.setState({con:true});
            let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {            
                this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students'}})
                this.setState({con:false});
            } 
            else
            {
                this.closeAlert();

                var selectedCohortJson = this.getSelectedCohortDropdownValue();
                let payload = {
                    selectedItems : selectedItems,
                    certtype : this.selectedCertType,
                    selectedCohortJson:selectedCohortJson
                };
                this.props.dispatch(CopyEmailAsPkData(copyemailaspkurl, JSON.stringify(payload)));
            }    
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
    }

    initCheckStatsinprogress(argstudents) {
        let data = [];
        for (let index = 0; index < argstudents.length; index++) {
            const parent = argstudents[index].data;
            let subItems = [];
            const children = argstudents[index].children;
            if(children !== null && children !== undefined && children.length > 0) {
                for (let i = 0; i < children.length; i++) {
                    const child = children[i].data;
                    let idval=child.cohortid+"="+child.studentid;
                    data.push({id: idval, checked: false});
                }
            }
            let idval=parent.cohortid+"="+parent.studentid;
            data.push({id: idval, checked: false});
        }
        this.checkStats["checkStats"]=data;
    }
    GetStudents (cohortId,pageOffset,searchquery,certtype="degree") {
        
        if(cohortId=="") return;
        /*
        ///temp///
        cohortId=[];
        cohortId.push("rv engineering-ug-2023-regular-odd-semester1");
        cohortId.push("rv engineering-pg-2024-regular-even-semester2");
        /////////
        */
        this.setState({ con: true });
        this.closeAlert();
        //this.setState({loaded: false});
        
        if(certtype==""||certtype==null) certtype="degree";

        var selectedCohortJson = this.getSelectedCohortDropdownValue();
        try {
            localStorage.setItem('selectedCohortJson', JSON.stringify(selectedCohortJson));
            localStorage.setItem('selectedCertType', certtype);
        } catch (error) {
            
        }
        let payload = {
            key : "cohort",
            //value: cohortId,
            cohort: cohortId,
            certtypevalue:certtype,
            pageOffset:pageOffset,
            maxitemsperpage:this.maxitemsperpage,
            searchValue:searchquery,
            step: "all",
            selectedCohortJson:selectedCohortJson
        };
        this.props.dispatch(GetStudentsFetchData(getStudents, payload));
    }
    
    changeCohorts = (e) => {
        let idx = e.target.selectedIndex;
        this.cohortselectindex=idx;
        let value = e.target.options[idx].value; 
        this.selectedCohort=value;
        this.setState({size: 1});
        e.target.blur();
        localStorage.setItem('selectedCohort', this.selectedCohort);
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }

    setCohort = displayCohort => {
        if(displayCohort.length != 0){
            this.selectedCohort = displayCohort[0].value;
            this.setState({size: 1});
            localStorage.setItem('selectedCohort', this.selectedCohort);

            localStorage.setItem('selectedpageindex',0);  
            this.mainPageOffset=0;
            this.initialMainPageIndex=0;       
            this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        }        
    }

    changeCertType = (e) => {
        let idx = e.target.selectedIndex;
        this.certtypeselectindex=idx;
        let value = e.target.options[idx].value;	      

        this.selectedCertType=value;        
        localStorage.setItem('selectedCertType', this.selectedCertType);
        /*
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        */
        this.GetCohorts();
    }
    
    componentDidMount()
    {
       /*
        let cohortid = localStorage.getItem('selectedCohort');   
        if(cohortid==null || cohortid==undefined)
        {
            cohortid=this.Cohorts[0];
        }
        else
        {
            this.Cohorts.length > 0
            && this.Cohorts.map((item, i) => {
                if(item.value==cohortid)
                {
                    this.cohortselectindex=i;
                }                
            
            }, this);
        }
        this.selectedCohort=cohortid;
        */
        let selectedcerttypeid = localStorage.getItem('selectedCertType');        
        if(selectedcerttypeid==null || selectedcerttypeid==undefined)
        {
            selectedcerttypeid="degree";
            localStorage.setItem("selectedCertType","degree");
        }
        else
        {
            this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if(item.value==selectedcerttypeid)
                {
                this.certtypeselectindex=i;                        
                }                
            
            }, this);
        }
        this.selectedCertType=selectedcerttypeid;

        console.log(this.selectedCohort, this.selectedCertType);

        localStorage.setItem("selectedpageindex","0");  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;  
        
        
        //this.GetStudents(cohortid,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        this.GetProfile();
       
       
    }
    GetProfile() {
        console.log("GetProfile")
        this.setState({ con: true })
        this.props.dispatch(GetProfileFetchData(getProfile));
    } 
    fetchApiData()
    {
        console.log("fetchApiData");
        //this.setState({ con: true });
        this.setState(prevState => ({
            dialogOpen: true
          }));
        let payload = {
            
        };               

        /*
        var Token = 'bearer ' +localStorage.getItem('Token');
        const headers = {
        'Authorization':  Token,                
        }
        axios.post(uploadstudentbyapi, payload, { credentials: "same-origin",responseType: 'application/json', timeout: 3600000, headers: headers
        }).then(response => {             
            if(response.status == "200"){    
                if(response.data.Status==200)
                { 
                    this.setState({alertData:{status:'Success',className: 'success', message: response.data.message}});
                }
                else
                {
                    this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
                }
                this.setState({con:false});
            }
            else if (response.status == "400"){
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: response.data.message}});
            }
            else {
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'failed'}});
            }
            
        }).catch((error) => {
            this.setState({con:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: "Error"}});
        }); 
        */
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.state.isRenderedCohort==true)
        {
            this.setState({isRenderedCohort:false});  
            localStorage.setItem("selectedpageindex","0");  
            this.mainPageOffset=0;
            this.initialMainPageIndex=0;
            this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);

        }
        // console.log(prevProps);
        if ( prevProps.Students ) {
          let studentsData;
            this.setState({Students:prevProps.Students});
            if (prevProps.Students.Status === 200 )
            {
              
                try
                {
                    this.mainPageCount=prevProps.Students.Data.mockData[0].pagecount/this.maxitemsperpage;
                    
                }
                catch(error)
                {
                    this.mainPageCount=1;
                }

                /*
                let cohortid = localStorage.getItem('selectedCohort');
                if(cohortid!==null && cohortid!==undefined && cohortid!=="")                
                {
                    //console.log(cohortid+"dd");      
                    this.Cohorts.length > 0
                    && this.Cohorts.map((item, i) => {
                        if(item.value==cohortid)
                        {
                            this.cohortselectindex=i;
                            this.setState({displayCohort: [{label: item.label, value: item.value}]});
                        }                
                    
                    }, this);
                }
                else
                {
                    if(this.Cohorts.length > 0)
                    {
                        console.log(this.Cohorts[0]["value"]) ;                        
                        localStorage.setItem('selectedCohort', this.Cohorts[0]["value"]);
                        this.selectedCohort=this.Cohorts[0]["value"]; 
                        this.setState({displayCohort: [{label: this.Cohorts[0].label, value: this.Cohorts[0].value}]});                       
                    }
                }    
                */
                     
                /// this.setState({Cohorts:prevProps.Students.Data.cohortData});
                if( prevProps.Students.step = 'all' )
                    { 
                    studentsData = TreeState.create(prevProps.Students.Data.mockData);
                    if(studentsData.data.length>0) studentsData = TreeState.expandAll(studentsData);
                    this.initCheckStatsinprogress(prevProps.Students.Data.mockData);
                    }
            } else {
                studentsData = [];
                this.setState({alertData:{status:'Success',className: 'warning', message: prevProps.Students.message}})
            }
            


            this.setState({StudentsData:studentsData});
            this.setState({con:false});
            /*
            if (this.state.loaded) {
                this.setState({loaded: false});
            } else {
                this.setState({con:false});
                this.setState({loaded:true});
            }
            */
        }        
        if ( prevProps.deleteStudent ) {
            this.setState({con:false});
            this.setState({copyemailaspkstatus: prevProps.copyemailaspkstatus});
            let alertData = {};
            alertData.status = prevProps.deleteStudent.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Success';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = 'Oops, Went something wrong'
            }
            this.setState({alertData: alertData});
        }   
        if ( prevProps.copyemailaspkstatus ) {

            this.setState({con:false});
            this.setState({copyemailaspkstatus: prevProps.copyemailaspkstatus});
            let alertData = {};
            alertData.status = prevProps.copyemailaspkstatus.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Email is updated successfully as PK';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = 'Oops, Went something wrong'
            }
            this.setState({alertData: alertData});
        }
        if ( prevProps.reducerupdatestudentinformation) {
            
            this.setState({con:false}); 
            let alertData = {};
            alertData.status = prevProps.reducerupdatestudentinformation.Status;
            if ( alertData.status === 200 ) {
                alertData.className = 'success';
                alertData.message = 'Student Informations are updated successfully';
                this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
            } else {
                alertData.className = 'danger';
                alertData.message = prevProps.reducerupdatestudentinformation.message;
            }
            this.setState({alertData: alertData});
           
        }

        if ( prevProps.InvitationStatus ) {

            this.setState({con:false});
            this.setState({InvitationStatus: prevProps.InvitationStatus});

            let alertData = {};
            if ( prevProps.InvitationStatus.flag == 'invite' )
            {

                alertData.status = prevProps.InvitationStatus.Status;

                if ( alertData.status === 200 ) {
                    alertData.className = 'success';
                    alertData.message = 'Sent invitation to selected students successfully';
                    
                    this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
                    
                } else {
                    alertData.className = 'danger';
                    alertData.message = 'Oops, Went something wrong'
                }
                this.setState({alertData: alertData});
            } else if ( prevProps.InvitationStatus.flag == 'preview') {
              let emailList = prevProps.InvitationStatus.PreviewMail;
              this.pageCount = emailList.length;
              this.setState({showModal:true, ModalContents: emailList });
            }
            else
            {
                alertData.status = prevProps.InvitationStatus.Status;
                alertData.className = 'danger';
                alertData.message = prevProps.InvitationStatus.message;
                this.setState({alertData: alertData});
                
            }
        }

        if ( prevProps.UploadStudents ) {
            this.setState({con:false});
            let alertData = {};
            alertData.status = prevProps.UploadStudents.Status;
            if ( prevProps.UploadStudents.Status === 200 ) {
                alertData.className = 'success';
                alertData.message = prevProps.UploadStudents.message;//'Upload students data successfully';
                //this.GetStudents(this.selectedCohort,0,this.state.searchValue,this.selectedCertType);
                this.GetProfile();
            } else {
                alertData.className = 'danger';
                //alertData.message = 'Oops, Went something wrong'
                alertData.message = prevProps.UploadStudents.message;
            }
            this.setState({alertData: alertData});
        }

        if ( prevProps.PreviewStudentPhotoStatus ) {
            
            if(prevProps.PreviewStudentPhotoStatus.Status===200)
            {
                this.setState({con:false});                
                let photoContent = prevProps.PreviewStudentPhotoStatus.photoContent;
                let photoTitle = prevProps.PreviewStudentPhotoStatus.photoTitle;
                // this.pageCount = certList.length;
                this.setState({showModal:true, selectedphotoContent: photoContent, selectedPhotoTitle: photoTitle});
            }
            else
            {
                if(prevProps.PreviewStudentPhotoStatus.message=="Cannot read property 'displayHtml' of null" || prevProps.PreviewStudentPhotoStatus.message=="Unexpected end of JSON input")
                { 
                    this.setState({alertData:{status:'No available certificates',className: 'warning', message: 'There is no generated certificates'}}) ;
                }
                else
                {
                    this.setState({alertData:{status:'No available certificates',className: 'warning', message: prevProps.PreviewStudentPhotoStatus.message}}) ;
                }
                window.scrollTo(0,0); 
                this.setState({con:false});
                
            }
            
        }
        if (prevProps.Profile) {
            console.log("prevProps.Profile=",prevProps.Profile);
            if (prevProps.Profile.Status === 200) {                
                
                if(prevProps.Profile.Data.isapimode==false)
                {                    
                    this.setState({isapimode:false});              
                }
                else
                {                          
                    this.setState({isapimode:true});    
                }

                //this.setState({cohortsearchmode:prevProps.Profile.Data.cohortsearchmode, cohortsplitlength:prevProps.Profile.Data.cohortsplitlength}); 
                this.GetCohorts();
            }
            
        }

        if ( prevProps.Cohorts ) {          
            this.setState({con:false});
            if (prevProps.Cohorts.Status === 200)
            {              
                try {
                    this.Cohorts = prevProps.Cohorts.Data; 
                    //this.Cohorts = prevProps.Students.Data.cohortData; 
                    this.dropdownCohortArray = prevProps.Cohorts.cohortdropwdownarray; 
                    console.log(this.dropdownCohortArray);
                    
                    let cohortid = localStorage.getItem('selectedCohort');        
                    if(cohortid==null)
                    {
                        if(this.Cohorts.length > 0)
                        {
                            console.log(this.Cohorts[0]["value"]) ;                        
                            localStorage.setItem('selectedCohort', this.Cohorts[0]["value"]);
                            this.selectedCohort=this.Cohorts[0]["value"]; 
                            this.setState({displayCohort: [{label: this.Cohorts[0].label, value: this.Cohorts[0].value}]});                       
                        }
                    }
                    else
                    {
                        //console.log(cohortid+"dd");      
                        this.Cohorts.length > 0
                        && this.Cohorts.map((item, i) => {
                            if(item.value==cohortid)
                            {
                                this.cohortselectindex=i;
                                this.selectedCohort=cohortid; 
                                this.setState({displayCohort: [{label: item.label, value: item.value}]});
                            }                
                        
                        }, this);
                    }
                            
                    localStorage.setItem('cohortsearchmode', prevProps.Cohorts.cohortsearchmode)
                    this.setState({isRenderedCohort:true}); 
                    
                } catch (error) {                        
                    this.setState({alertData:{status:'warning',className: 'warning', message: "There are no added cohorts"}});
                    
                }
               
            }
            else
            {
                this.enabledbt=false;
                this.enablenextstep=false;
                this.setState({alertData:{status:'warning',className: 'warning', message: prevProps.Cohorts.message}});
            }
        }  

    }

    onFileChange(e)
    {
      this.files=e.target.files;
      if(this.files.length > 0) {
          let file = this.files[0];
          let name = file.name;
          this.setState({placeholder: name });
      }

    }

    onPhotoFileChange(e)
    {
        this.photofiles=e.target.files;
        if (this.selectedCohort == "" || this.selectedCohort == undefined){
            this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
        }
        else if(this.photofiles.length > 0) {        
            this.setState({con:true});
            const data = new FormData();
            for (let index=0; index < this.photofiles.length; index++){
                data.append('file' + index, this.photofiles[index]);
            }
            data.append('certtype', this.selectedCertType);
            data.append('cohortid', this.selectedCohort);
            // data.append('files', this.photofiles);
            var Token = 'bearer ' +localStorage.getItem('Token');
            const headers = {
            'Authorization':  Token,                
            }
            axios.post(UPLOADMULTIPHOTOS, data, { credentials: "same-origin", headers: headers
            }).then(res => { 
                console.log(res.data.message);
                this.setState({con:false});
                if(res.data.Status===200)
                {
                    this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                }
                else
                {
                    this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                }
            }).catch((err) => {
                console.log(err);
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file upload failed.'}});
            });                      
        }
    }

    checkenterkeyforsearch = (e) => {
        console.log(e);
        if (e.key == "Enter") {
            this.searchAction();
        }
    } 

    searchAction()
    {   
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;         
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
    }
    readFile()
    {
       

        const reader = new FileReader();
        if(this.files.length>0)
        {
            this.setState({con:true});
            let file=this.files[0];
            let name=file.name;
            if(name.indexOf(".zip")!==-1 || name.toString().toLowerCase().indexOf(".pdf")!==-1)
            {
            //   alert("this is zip file");
                if (name.indexOf(" ") != -1){
                    this.setState({con:false});
                    this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file name has space string. Please remove space string from file name.'}});
                }
                else {
                    if (this.selectedCohort == "" || this.selectedCohort == undefined || this.selectedCohort == null){
                        this.setState({con:false});
                        this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
                    }
                    else{
                        var selectedCohortJson = this.getSelectedCohortDropdownValue();
                        const selectedCohortJsonString = JSON.stringify(selectedCohortJson);
                        const data = new FormData();
                        data.append('file', file);
                        data.append('certtype', localStorage.getItem("selectedCertType"));
                        data.append('cohortid', localStorage.getItem("selectedCohort"));
                        data.append('selectedCohortJson', selectedCohortJsonString);
                        var Token = 'bearer ' +localStorage.getItem('Token');
                        const headers = {
                            'Authorization':  Token,                
                            }
                        axios.post(UPLOADPHOTOS, data, { credentials: "same-origin",headers: headers                    
                            }).then(res => { 
                                // console.log(res.data.message);
                                this.setState({con:false});
                                if(res.data.Status===200)
                                {
                                this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                                }
                                else
                                {
                                this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                                }
                            }).catch((err) => {
                                console.log(err);
                                this.setState({con:false});
                                this.setState({alertData:{status:'empty',className: 'warning', message: 'Photo file upload failed.'}});
                            });
                    }
                }              
            }
            else if(name.indexOf(".xlsx")!==-1)
            {
                if (name.toString().toLowerCase().indexOf("ljkuteacher") != -1){
                    if (name.indexOf(" ") != -1){
                        this.setState({con:false});
                        this.setState({alertData:{status:'empty',className: 'warning', message: 'Excel file name has space string. Please remove space string from file name.'}});
                    }
                    else {
                        if (this.selectedCohort == "" || this.selectedCohort == undefined || this.selectedCohort == null){
                            this.setState({con:false});
                            this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected cohort. Please retry after upload student excel sheet.'}});
                        }
                        else{
                            var selectedCohortJson = this.getSelectedCohortDropdownValue();
                            const selectedCohortJsonString = JSON.stringify(selectedCohortJson);
                            const data = new FormData();
                            data.append('file', file);
                            data.append('certtype', localStorage.getItem("selectedCertType"));
                            data.append('cohortid', localStorage.getItem("selectedCohort"));
                            data.append('selectedCohortJson', selectedCohortJsonString);
                            var Token = 'bearer ' +localStorage.getItem('Token');
                            const headers = {
                                'Authorization':  Token,                
                                }
                            axios.post(UPLOADPHOTOS, data, { credentials: "same-origin",headers: headers                    
                                }).then(res => { 
                                    // console.log(res.data.message);
                                    this.setState({con:false});
                                    if(res.data.Status===200)
                                    {
                                    this.setState({alertData:{status:'empty',className: 'success', message: res.data.message}});
                                    }
                                    else
                                    {
                                    this.setState({alertData:{status:'empty',className: 'warning', message: res.data.message}});  
                                    }
                                }).catch((err) => {
                                    console.log(err);
                                    this.setState({con:false});
                                    this.setState({alertData:{status:'empty',className: 'warning', message: 'Excel file upload failed.'}});
                                });
                        }
                    }           
                }
                else reader.readAsBinaryString(file);
              //alert("this is xlsx file");
            }
            else
            {
                this.setState({con:false});
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select correct file format.'}});
            }

        } else {
            this.setState({con:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select the file'}});
        }

        reader.onload = (evt) => {

        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary',cellDates: true,cellNF: false,cellText: false});

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(ws);
        var json_object = JSON.stringify(XL_row_object);
        const data = XLSX.utils.sheet_to_csv(ws, {header:1});


        let selectedcerttypeid = localStorage.getItem('selectedCertType');        
        if(selectedcerttypeid==null || selectedcerttypeid==undefined)
        {
            selectedcerttypeid="degree";
        }
        else
        {
            this.CertType.length > 0
            && this.CertType.map((item, i) => {
                if(item.value==selectedcerttypeid)
                {
                this.certtypeselectindex=i;                        
                }                
            
            }, this);
        }
        this.selectedCertType=selectedcerttypeid;


        let payload = {  
            selectedcerttypeid: selectedcerttypeid,          
            value: json_object,
            ipaddress: this.ipAddress            
        };
       // this.props.dispatch(UploadStudentsFetchData(UPLOADSTUDENTS, data));
        this.props.dispatch(UploadStudentsFetchData(UPLOADSTUDENTS, JSON.stringify(payload)));
        };

    }

    //Send invitation to selected students
    sendInvitation() {
        
        try {
          let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {
                this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students'}})
                this.setState({con:false});
            } else {
                this.setState({alertData:{status:'empty',className: 'success', message: 'Please wait a few minutes.'}})
                this.props.dispatch(SendInvitation(sendInvitation, selectedItems,"invite",this.selectedCertType));
            }    
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
        
    }
    //Preview Invitation Email
    previewEmail() {
        try {
            this.setState({con:true});
            let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {            
                this.setState({alertData:{status:'empty',className: 'warning', message: 'There is no selected students'}})
                this.setState({con:false});
            }
            else
            {
                this.closeAlert();
                this.props.dispatch(SendInvitation(sendInvitation, selectedItems,"preview",this.selectedCertType));
            }    
        } catch (error) {
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
    }

    previewStudentPhoto(coid,emid) {        
        this.setState({con:true});
        this.closeAlert();    
        let payload = {
            cohortid: coid,
            studentid: emid,
            certtype:localStorage.getItem("selectedCertType"),
        };        
        this.props.dispatch(PreviewStudentPhoto(previewstudentphoto, payload));        
    }    

    closeModal() {
      this.setState({showModal:false});
    }
    closeAlert() {
      let alertData = {
          status: false,
          className: '',
          message: ''
      }
      this.setState({alertData:alertData})
    }

    handlePageClick = data => {
      let selected = data.selected;
      this.setState({currentOffset:selected});
    }
    handleMainPageClick(data){
      let selected = data.selected;      
      localStorage.setItem('selectedpageindex', selected*this.maxitemsperpage);
      this.mainPageOffset=selected*this.maxitemsperpage;
      this.initialMainPageIndex=selected;      
      this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);      
    }
    downloadTemplate = e =>
    {
        e.preventDefault(); 
        window.open(server_ip+"/uploadtemplate/template.xlsx");
    }
    closeUpdateInformation() {
        this.setState({showUpdateInformation:false});        
    }
    updateInformation()
    {
        this.setState({con:true});
        this.closeAlert();
        this.closeUpdateInformation();
        let payload = {
            selectedCertType: localStorage.getItem("selectedCertType"),
            selectedcoid : this.state.selectedcoid,
            selectedstdid : this.state.selectedstdid,
            newemail : this.state.newemail,
            firstname : this.state.newfirstname,
            middlename : this.state.newmiddlename,
            lastname : this.state.newlastname,
        };
        this.props.dispatch(reduxUpdateInformation(updateinformationurl, JSON.stringify(payload)));
    }
    openEditModal(coid,emid,emailaddress,firstname,middlename,lastname) {
        
        //alert(coid);
        //alert(emid);
        this.publicnewmeail=emailaddress;
        this.newfirstname=firstname;
        this.newmiddlename=middlename;
        this.newlastname=lastname;
        this.setState({showUpdateInformation:true,newemail:emailaddress,newfirstname:firstname,newmiddlename:middlename,newlastname:lastname,selectedcoid:coid,selectedstdid:emid});        
    }
    onCloseRenderAPIDialog() {
        console.log("hihihihih");
        this.setState({dialogOpen: false});

    }
    renderApiDialog()
    {
        
        return(                
            <ApiDialog certtype={this.selectedCertType} open={this.state.dialogOpen} onClose={()=>{ this.onCloseRenderAPIDialog() }}/>
        );
    }
    renderSelectForPageSize(){
        try {
            let selectedItems = 0;            
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {
                        const idarr=this.checkStats["checkStats"][i].id.split("=");
                        if(idarr[1]!=="")
                        {
                            selectedItems++;
                        }
                    }
                }
            }
            let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'},{'label' : '150', 'value' : '150'},{'label' : '200', 'value' : '200'},{'label' : '250', 'value' : '250'},{'label' : '300', 'value' : '300'},{'label' : '350', 'value' : '350'}];
            //let Actions = [{'label' : '50', 'value' : '50'},{'label' : '100', 'value' : '100'}];
            let options = Actions.length > 0
                && Actions.map((item, i) => {                    
                    if(item.value==this.maxitemsperpage)
                    {
                    return (
                            <option selected={"selected"} key={i} value={item.value}>{item.label}</option>
                        );
                    }
                    else
                    {
                    return (
                            <option key={i} value={item.value}>{item.label}</option>
                        );
                    }
                    
                }, this);
            
           
            return(                
                <div className="row" style={{"marginLeft":"0px","marginTop":"10px"}}>
                    <div >
                        <select  style={{"height":"30px","width":"65px"}} onChange={this.selectPageSize} >
                            {options}
                        </select>
                    </div>
                    <div  style={{"marginLeft":"10px","marginTop":"3px"}}>
                        Selected <b>{selectedItems}</b> items
                    </div>  
                </div>
            );
        } 
        catch (error)
        {            
            
        }
        
    }

    delete_student() {        
        this.setState({deleteStudentConfirmModal:true});
        window.scrollTo(0,0);
    }

    closeDeleteStudentConfirmModal() {   
        this.setState({deleteStudentConfirmModal:false});        
    }
    
    deleteStudentConfirm()
    {
        try {
            this.setState({con:true});
            let selectedItems = [];
            if ( this.checkStats["checkStats"].length > 0 ) {
                for ( let i = 0 ; i < this.checkStats["checkStats"].length ; i++ ) {
                    if ( this.checkStats["checkStats"][i].checked ) {                        
                        selectedItems.push(this.checkStats["checkStats"][i].id);
                    }
                }
            }
            if ( selectedItems.length === 0 ) {            
                this.setState({alertData:{status:'empty',className: 'warning', message: 'Please select correct items'}})
                this.setState({con:false});
            } 
            else
            {                
                let payload = {
                    selectedItems : selectedItems,
                    // cohortid: localStorage.getItem("selectedCohort"),
                    certtype: this.selectedCertType
                };
                this.setState({deleteStudentConfirmModal:false});
                this.props.dispatch(DeleteStudentsData(deletestudent, payload)); 
            }    
        } catch (error) {
            this.setState({deleteStudentConfirmModal:false});
            this.setState({alertData:{status:'empty',className: 'warning', message: error}})
            this.setState({con:false});
        }
        // let payload = {
        // cohortid: localStorage.getItem("selectedCohort"),
        // certtype: this.selectedCertType
        // };
        // this.setState({deleteStudentConfirmModal:false});
        // this.props.dispatch(DeleteStudentsData(deletestudent, payload)); 
    }
    checkCohortValid()
    { 
        var cohortList=[];
        for(let index=0; index<this.Cohorts.length; index++)
        {  
            cohortList.push(this.Cohorts[index].value);            
        }
        let cohortid = localStorage.getItem('selectedCohort');   
        if(cohortid==null || cohortid==undefined)
        {
            if(this.Cohorts[0]==null || this.Cohorts[0]==undefined) return;
            cohortid=this.Cohorts[0].value;
        }
        if(cohortid==null || cohortid == undefined) return false;        
        console.log(cohortid); 
        if(cohortList.indexOf(cohortid)==-1) return false;        
        else return cohortid;     

    }
    cohortSearchAction = () => {
        var collength=0;
        var cohortList=[];
        for(let index=0; index<this.Cohorts.length; index++)
        {            
            let cohortsplitarr = this.Cohorts[index].value.split('-');            
            collength = cohortsplitarr.length;
            cohortList.push(this.Cohorts[index].value);            
        }
        let combinedOptions = '';
        for (let i = 0; i <collength; i++) {
            const dropdown = document.getElementById(`dropdown-${i}`);
            if (dropdown) {
                combinedOptions += dropdown.value;
                if (i < collength - 1) {
                    combinedOptions += '-';
                }
            }
        }
        console.log(combinedOptions); 
        // if(cohortList.indexOf(combinedOptions)==-1)
        // {
        //     this.setState({alertData:{status:'empty',className: 'warning', message: 'The selected batch does not exist'}})            
        // }
        // else
        {
            
            this.selectedCohort = combinedOptions;
            this.setState({size: 1});
            localStorage.setItem('selectedCohort', this.selectedCohort);
            localStorage.setItem('selectedpageindex',0);  
            this.mainPageOffset=0;
            this.initialMainPageIndex=0;       
            this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
        }
        
    }

    getSelectedCohortDropdownValue()
    {
        var glDropdownCohortArray=this.dropdownCohortArray;
        var result={};
       
        Object.keys(glDropdownCohortArray).forEach(function(key, value){
            
            if (glDropdownCohortArray[key] == undefined || glDropdownCohortArray[key] == null) glDropdownCohortArray[key] = "";
            if (glDropdownCohortArray == ""){
              
            }
            else
            {
                const dropdown = document.getElementById(key);
                if(dropdown!=null) result[key]=dropdown.value;
            }
          });
          return result;
    }
    changeHandleMultipleCohort =(e)=>
    {
        let idx = e.target.selectedIndex;        
        let value = e.target.options[idx].value;	
        let selectElementId = e.target.id; 
        this.selectedCustomDropdown[`${selectElementId}`] = value;
        localStorage.setItem('selectedCustomDropdown', JSON.stringify(this.selectedCustomDropdown));
        this.setState({size: 1});   
        localStorage.setItem('selectedpageindex',0);  
        this.mainPageOffset=0;
        this.initialMainPageIndex=0;       
        this.GetStudents(this.selectedCohort,this.mainPageOffset,this.state.searchValue,this.selectedCertType);
       
        
    }    
    renderMultiCohort(){
        
        
        var glDropdownCohortArray=this.dropdownCohortArray;
        
        if(Object.keys(glDropdownCohortArray).length === 0 && glDropdownCohortArray.constructor === Object) {
            console.log("JSON Object is empty");
            return;
        } else {
            console.log("JSON Object is not empty");
        }       
        
        const selectStyle = {           
            borderColor:'rgb(206, 212, 218)',
            height:'35px',
            borderRadius: '0.25rem',
            padding:'0px 2px',
            width:'70px'
        };
       
       
        var selectedCustomDropdown= localStorage.getItem('selectedCustomDropdown');
        try {
            selectedCustomDropdown = JSON.parse(selectedCustomDropdown);
        } catch (error) {
            selectedCustomDropdown={};
        }
        console.log("selectedCustomDropdown=", selectedCustomDropdown);
        const dropdownarray = Object.keys(glDropdownCohortArray).map((key, value) => {
            if (glDropdownCohortArray[key] == undefined || glDropdownCohortArray[key] == null) glDropdownCohortArray[key] = "";
            if (glDropdownCohortArray == ""){
                // Your logic here
            }
            else
            {
                let dropdownOptions = glDropdownCohortArray[key].map((cohortsplitarr, index) =>{
                    if(selectedCustomDropdown ==null || selectedCustomDropdown[`${key}`] == undefined || selectedCustomDropdown[`${key}`] == null)
                    {
                        if(index==0)
                        {
                            return (
                                <option selected key={index}>{cohortsplitarr.label}</option>
                                );
                        }
                        else
                        {
                            return (
                                <option key={index}>{cohortsplitarr.label}</option>
                                );
                        }
                        
                    }
                    else
                    {
                        if(selectedCustomDropdown[`${key}`]==cohortsplitarr.label)
                        {
                            return (
                                <option selected key={index}>{cohortsplitarr.label}</option>
                                );
                        }
                        else
                        {
                            return (
                                <option key={index}>{cohortsplitarr.label}</option>
                                );
                        }
                    }
                });
                return (
                    <select
                        style={selectStyle}
                        key={key}
                        id={key}
                        onChange={(e)=>this.changeHandleMultipleCohort(e)}
                    >
                        {dropdownOptions}
                    </select>
                );
            }
        });
        
       
        
        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        };

        return (
            <div style={containerStyle}>
                {dropdownarray}
                {/* <button
                    style={{ borderRadius: "0.25rem", height: "36px", width:'60px' }}
                    className="input-group-text"
                    onClick={this.cohortSearchAction}
                >
                    <i className="fa fa-search" style={{marginLeft:'auto',marginRight:'auto'}}></i>
                </button> */}
            </div>
        );
        
    }

    render() {
        
        let cohortsearchmodelstrg = localStorage.getItem('cohortsearchmode');
        if(cohortsearchmodelstrg == undefined || cohortsearchmodelstrg== null || cohortsearchmodelstrg=="") cohortsearchmodelstrg=0;
        
        let selectedpageindex = localStorage.getItem('selectedpageindex');        
        if(selectedpageindex==null || selectedpageindex==undefined) selectedpageindex=0;        
        this.mainPageOffset=selectedpageindex;           
        this.initialMainPageIndex=parseInt(selectedpageindex)/parseInt(this.maxitemsperpage);
        
        
        const {placeholder} = this.state;
        var alertData = this.state.alertData;
        var currentOffset = this.state.currentOffset;
        var previewContents = this.state.ModalContents;
        var currentPreview = (previewContents[currentOffset]) ? previewContents[currentOffset].mailitem : '';
        
        let cohortList = this.Cohorts.length > 0
    	&& this.Cohorts.map((item, i) => {
            if(this.cohortselectindex==i)
                {
                    
                    return (
                        <option title={item.label} key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option title={item.label} key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this);
        

        let certtypeList = this.CertType.length > 0
    	&& this.CertType.map((item, i) => {
            if(this.certtypeselectindex==i)
                {
                    
                    return (
                        <option key={i} value={item.value} selected={"selected"}>{item.label}</option>
                    )
                }
                else
                {
                    return (
                        <option key={i} value={item.value}>{item.label}</option>
                    )
                }
        }, this);



        return (
            <section style={{"padding":"10% 25px","width":"100%"}} className="project-tab">
            {this.state.con && (<Spinner></Spinner>)}
            { (alertData.status) ? (<Alert variant={alertData.className} onClose={()=>this.closeAlert()} dismissible>{alertData.message}</Alert>) : ('')}
            
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div style={{"marginTop":"10px", "display": "flex", "columnGap": "10px"}}>
                                <label style={{"marginTop":"8px","fontSize":"14px"}} >Batch</label>
                                {
                                    cohortsearchmodelstrg==0?(
                                    <Select className="cohortDropdown" style={{'borderColor': 'rgb(206, 212, 218)', 'borderRadius': '4px', 'width': '400px'}} values={this.state.displayCohort} options={this.Cohorts} clearable={true} placeholder=""  onChange={values => this.setCohort(values)}/>):this.renderMultiCohort()
                                }
                    
                                
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div style={{"marginTop":"10px", "display": "flex", "columnGap": "10px"}}>
                                <label style={{"marginTop":"7px","fontSize":"14px"}} >Certificate-Type</label>
                                {/*<Select className="col-md-6 cert-row-column"  options={this.Cohorts} onChange={(values) => this.changeCohorts(values)} />*/}
                                <select  style={{"padding":"7px 10px","borderColor":"rgb(206, 212, 218)","marginTop":"1px","borderRadius":"4px","display":"inline","marginLeft":"8px","fontSize":"12px"}} onChange={this.changeCertType} >
                                        {certtypeList}
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12">
                            <div style={{"marginTop":"10px"}}>
                                {this.state.isapimode==false?(
                                <table border="0" > 
                                    <tbody>
                                        <tr>
                                            <td style={{"textAlign":"right"}}>
                                                <div className="upload-btn-wrapper" style={{"width":"77px"}}>
                                                    {/* <button style={{"width":"77px","borderBottomLeftRadius": ".25rem","borderTopLeftRadius": ".25rem","borderTopRightRadius": "0rem","borderBottomRightRadius": "0rem"}} className="input-group-text" onClick={()=>this.readFile()}>Browse</button> */}
                                                    <button style={{"width":"77px","borderBottomLeftRadius": ".25rem","borderTopLeftRadius": ".25rem","borderTopRightRadius": "0rem","borderBottomRightRadius": "0rem"}} className="input-group-text" >Browse</button>
                                                    <input style={{"width":"77px","position":"absolute","opacity": "0","left":"0","top":"0"}} type="file" name="myfile" onChange={(e)=>this.onFileChange(e)} accept=".xlsx,.zip,.pdf" />                            
                                                </div>
                                            </td>
                                            <td style={{"textAlign":"left"}}>
                                                <div style={{"fontSize":"12px","border": "1px solid #ced4da","height":"38px","padding": "10px 18px","borderLeft":"0px","borderRight":"0px","textOverflow": "ellipsis","whiteSpace": "nowrap","overflow": "hidden","maxWidth": "145px",width:'145px'}} >{placeholder}</div>
                                            </td>
                                            <td style={{"textAlign":"left"}}><button style={{"borderBottomLeftRadius": "0rem","borderTopLeftRadius": "0rem"}} className="input-group-text" onClick={()=>this.readFile()}>Upload</button>
                                            </td>
                                        </tr>
                                    </tbody>             
                                </table>):(<button className="btn btn-info" onClick={()=>this.fetchApiData()}>Fetch Student Data</button>)}
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-12">
                            <div style={{"marginTop":"10px"}}>
                                <table border="0" >           
                                <tbody>
                                    <tr> 
                                    <td >
                                    <input style={{"marginTop": "-1px","background":"white","height":"38px","border": "1px solid rgb(206, 212, 218)","borderBottomLeftRadius": ".25rem","borderTopLeftRadius": ".25rem","borderTopRightRadius": "0rem","borderBottomRightRadius": "0rem","textAlign":"left","paddingLeft":"5px","width":"100%"}} type="text" name="searchinput" id="searchinput" 
                                    onChange={e=>this.setState({searchValue:e.target.value})} onKeyDown={e=>this.checkenterkeyforsearch(e)} defaultValue={this.state.searchValue} />
                                    </td>
                                    <td style={{"textAlign":"left"}}>
                                    <button style={{"marginTop": "-6px","borderBottomLeftRadius": "0rem","borderTopLeftRadius": "0rem","height":"38px"}} className="input-group-text" onClick={()=>this.searchAction()}><i className="fa fa-search"></i></button>
                                    </td>
                                    
                                    </tr>
                                    </tbody>   
                                </table>
                            </div>
                        </div>                        
                    </div>
                </div>                
                {/* <div className="col-xl-1 col-lg-12 col-md-12" style={{"textAlign":"right"}}>
                    <div style={{"marginTop":"10px"}}>
                        <a style={{"color":"#0275d8","fontWeight":"1px"}} href="#" onClick={this.downloadTemplate}>Template file</a>
                    </div>
                </div> */}
            </div>
            
            
            { (this.state.StudentsData.data && this.state.StudentsData.data.length >0 ) ?
                        (<TreeTable style={{"margin-top":"10px"}} className="demo-tree-table1"
                                height="360"
                                headerHeight="40"

                                value={this.state.StudentsData}
                                onChange={this.handleOnChange}
                                >
                                <TreeTable.Column renderCell={this.renderIndexCellinprogress} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Name</span>} basis="350px" grow="0"/>
                                <TreeTable.Column renderCell={this.renderColumninprogress1} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>eMail</span>} />                                
                                <TreeTable.Column renderCell={this.renderColumninprogress3} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>PublicKey</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress6} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>SecondEmail</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress4} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>OneTimeCode</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress5} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Status</span>}/>
                                <TreeTable.Column renderCell={this.renderColumninprogress7} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Edit</span>} />
                                <TreeTable.Column renderCell={this.renderColumninprogress8} renderHeaderCell={() => <span style={{"fontSize":"14px"}}>Photo</span>} />
                                </TreeTable>) : (<div style={{"marginLeft":"25px"}} className="row"><p>There is no data</p></div>)}

            
            {this.renderSelectForPageSize()}   
            <center>                 
                <div style={{"marginTop":"10px"}}>
                {/*}
                {this.selectedCertType!="openbadges"?(<button className="btn btn-info" onClick={()=>this.sendInvitation()}>Send Invitation</button>):""}
                {"     "}
                {this.selectedCertType!="openbadges"?(<button className="btn btn-info" onClick={()=>this.previewEmail()}>Preview Invitation email</button>):""}
                {"     "}
                {*/}
                {<button className="btn btn-info" onClick={()=>this.copyemailaspk()}>copy email as PK</button>}
                {"     "}                    
                <input style={{display: 'none'}} multiple type="file" id="photofile" onChange={(e)=>this.onPhotoFileChange(e)} accept=".png, .jpg, .jpeg" />
                <label className="btn btn-info" for="photofile" style={{marginTop: '7px'}}>Upload Photo</label>
                {"     "}
                {<button className="btn btn-info" onClick={()=>this.delete_student()}>Delete Students</button>}
                <div  style={{"float":"right"}}>
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.mainPageCount}
                        // initialPage={this.initialMainPageIndex}
                        forcePage={this.initialMainPageIndex}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={(e)=>this.handleMainPageClick(e)}
                        disableInitialCallback={true}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        />
                </div>
                </div>
            </center>
            <Modal size='lt' dialogClassName="deleteStudentConfirm" show={this.state.deleteStudentConfirmModal} onHide={this.closeDeleteStudentConfirmModal} centered scrollable>
                <Modal.Header closeButton>
                <Modal.Title><p style={{"color":"#0275d8","fontSize":"24px"}}>Alert</p></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>
                <p id="deleteStudentConfirmContent" style={{"color":"black","fontSize":"16px"}}>
                The cohort has Issued certificates, Only generated certificates can be deleted. Proceed?

                </p>                    
                </Modal.Body>                        
                <Modal.Footer>
                <span><Button onClick={this.deleteStudentConfirm}>&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                <Button onClick={this.closeDeleteStudentConfirmModal}>No</Button>
                {/* <span><Button onClick={this.issueConfirm}>&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                <Button onClick={this.closeIssueConfirmModal}>No</Button> */}
                </Modal.Footer>
            </Modal>

            <Modal size='lt' dialogClassName="previewstudentphoto" show={this.state.showModal} onHide={this.closeModal} centered scrollable>
                <Modal.Header closeButton>
                <Modal.Title>{this.state.selectedPhotoTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>
                <Markup content={this.state.selectedphotoContent} />
                </Modal.Body>
                <Modal.Footer>                
                <Button onClick={this.closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal size='lt' dialogClassName="updateinformation" show={this.state.showUpdateInformation} onHide={this.closeUpdateInformation} centered scrollable>
                <Modal.Header closeButton>
                <Modal.Title><p style={{"color":"#0275d8","fontSize":"20px"}}>Recipient Information</p></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{'overflowY':'auto', 'maxHeight':'calc(100vh - 210px)'}}>                        
                    <table border="0" style={{"width":"100%","margin": "auto"}}>
                    <tbody>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Email:</p>
                            </td>
                            <td >
                                <input type="text" onChange={e=>this.setNewEmail(e.target.value)} style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="newemailid" value={this.state.newemail} />
                            </td>
                        </tr> 
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>First Name:</p>
                            </td>
                            <td >
                                <input type="text" onChange={e=>this.setFirstName(e.target.value)} style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="newfirstname" value={this.state.newfirstname} />
                            </td>                          
                        </tr>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Middle Name:</p>
                            </td>
                            <td >
                                <input type="text" onChange={e=>this.setMiddleName(e.target.value)} style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="newmiddlename" value={this.state.newmiddlename} />
                            </td>            
                        </tr>
                        <tr>
                            <td >
                                <p style={{"color":"black","fontSize":"16px"}}>Last Name:</p>
                            </td>
                            <td >
                                <input type="text" onChange={e=>this.setLastName(e.target.value)} style={{"border": "1px solid #ccc","width":"100%","height":"35px","textAlign":"left","padding":"10px"}}  id="newlastname" value={this.state.newlastname} />
                            </td>
                        </tr>
                                              
                    </tbody>
                    </table>
                </Modal.Body>                        
                <Modal.Footer>
                <span><Button onClick={this.updateInformation}>&nbsp;&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;&nbsp;</Button></span>                        
                <Button onClick={this.closeUpdateInformation}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            {this.renderApiDialog()}
        </section>
    )}
     handleOnChange = (newValue) => {
           this.setState({ StudentsData: newValue });

        }
    renderIndexCellinprogress = (row) => {

      let checked = false;
            let idval=row.data.cohortid+"="+row.data.studentid;
            //console.log(idval);
            let parentidval=row.data.cohortid+"=";
            let parentchecked=false;
            if ( this.checkStats["checkStats"])
            {
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                    const id = this.checkStats["checkStats"][i].id;

                    if(id==parentidval)
                    {
                        if(this.checkStats["checkStats"][i].checked==true)
                        {
                            parentchecked = true;

                        }
                        break;
                    }
                }
            }
            if(parentchecked==false && this.checkStats["checkStats"])
            {
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                    const childid = this.checkStats["checkStats"][i].id;
                    if(childid==idval)
                    {
                        if(this.checkStats["checkStats"][i].checked==true)
                        {
                            checked = true;
                        }
                        break;
                    }
                }
            }
            else
            {
               checked = true;
            }
            return (
                    <div style={{ paddingLeft: (row.metadata.depth * 15) + 'px',"height":"40px"}} >
                        <span className="toggle-button-wrapper" >
                        {(row.metadata.hasChildren)
                            ? (
                                <span style={{"fontSize":"13px","height":"19px"}} className="toggle-button" onClick={row.toggleChildren}></span>
                            )
                            : ''
                        }
                        </span>
                        {/* {(row.data.is_child==true)?(<span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>):(<span><span style={{"verticalAlign":"top","lineHeight":"36px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e,row.data.cohortid,row.data.studentid)} /></span><span style={{"fontSize":"10px","color":"#040402"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>)} */}
                        {(row.data.is_child==true)
                        ? (
                            (row.data.field11=="krutidev010")
                                ? (
                                    <span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all", "fontFamily": "krutidev010regular"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>
                                )
                                : (
                                    <span><span style={{"verticalAlign":"top","lineHeight":"31px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeChildren(e,row.data.cohortid,row.data.studentid)}/></span><span style={{"fontSize":"14px","color":"#040402","verticalAlign":"super","lineHeight":"26px","wordBreak":"break-all"}}><span>{row.data.field1} {row.data.field2} {row.data.field3}</span></span></span>
                                )
                        )
                        : (
                            <span><span style={{"verticalAlign":"top","lineHeight":"36px"}}><input type="checkbox" style={{"width":"30px","height":"17px"}} checked={checked} onChange={(e) => this.toggleCheckboxChangeParent(e,row.data.cohortid,row.data.studentid)} /></span><span style={{"fontSize":"10px","color":"#040402"}}><span>{row.data.field1}</span></span></span>
                        )}

                    </div>
                    );       

       }

        renderColumninprogress1 = (row) => {
            return (
                <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field4}</span>
            );
          }
          renderColumninprogress2 = (row) => {
            return (

                   <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field5}</span>
            );
          }
          renderColumninprogress3 = (row) => {
            return (
                <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field6}</span>
            );
          }
          renderColumninprogress4 = (row) => {
            return (
                <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field7}</span>
            );
          }
          renderColumninprogress5 = (row) => {
            return (
                <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.status}</span>
            );
          }
          renderColumninprogress6 = (row) => {
            return (
                <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.secondemailaddress}</span>
            );
          }
          renderColumninprogress7 = (row) => {
            if(row.data.status!=="")
            { 
               return (
                   <div style={{"marginBottom":"18px"}}>
                       <span style={{"verticalAlign": "text-top"}} onClick={(e) => this.openEditModal(row.data.cohortid,row.data.studentid,row.data.field4,row.data.field1,row.data.field2,row.data.field3)}>
                        <img src="https://www.certonce.com/images/edit.png" style={{"height":"20px"}} ></img>
                       </span>
                   </div>
               );
            }
            
          }
          renderColumninprogress8 = (row) => {
            if (row.data.field10 == "Yes")
                return (
                    <div style={{"marginBottom":"18px"}}>
                        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field10}</span>{" "}
                        <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} onClick={(e) => this.previewStudentPhoto(row.data.cohortid,row.data.studentid)}>
                        <img src="https://www.certonce.com/images/preview.png" style={{"height":"20px"}} ></img></span>
                    </div>
                );
            else if(row.data.field10 == "No")
                return (
                    <span style={{"fontSize":"14px","wordBreak":"break-all","height":"40px"}} >{row.data.field10}</span>
                );
          }
          toggleCheckboxChangeChildren = (e,cohortid,studentid) => {
                let idval=cohortid+"="+studentid;
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                const id = this.checkStats["checkStats"][i].id;

                if(id==idval)
                {
                    if(e.target.checked==true)
                    {
                        this.checkStats["checkStats"][i].checked = true;
                    }
                    else
                    {
                        this.checkStats["checkStats"][i].checked = false;
                        let parentidval=cohortid+"=";
                        for (let ii = 0; ii < this.checkStats["checkStats"].length; ii++) {
                            const id = this.checkStats["checkStats"][ii].id;

                            if(id==parentidval)
                            {
                                this.checkStats["checkStats"][ii].checked = false;
                            }
                        }
                    }
                    break;
                }
             }
             let checkchageval=this.state.checkchange;
                this.setState({checkchange:!checkchageval});
          }
          toggleCheckboxChangeParent = (e,cohortid,studentid) => {
                let idval=cohortid+"="+studentid;
                for (let i = 0; i < this.checkStats["checkStats"].length; i++) {
                const id = this.checkStats["checkStats"][i].id;
                const idarr=id.split("=");
                //if(idarr[0]==cohortid)
                {
                    if(e.target.checked==true)
                    {
                        this.checkStats["checkStats"][i].checked = true;
                    }
                    else
                    {
                        this.checkStats["checkStats"][i].checked = false;
                    }
                }
             }
                let checkchageval=this.state.checkchange;
                this.setState({checkchange:!checkchageval});
          }
}
function mapStateToProps(state, actions) {
    // console.log(state);
    if (state.fetchStudentsData && state.fetchStudentsData.Data && state.fetchStudentsData.Data.Status) {
        return { Students: state.fetchStudentsData.Data}
    } 
    else if (state.DeleteStudentsData && state.DeleteStudentsData.Data && state.DeleteStudentsData.Data.Status) {
        return { deleteStudent: state.DeleteStudentsData.Data }
    } 
    else if(state.fetchSendInvitation && state.fetchSendInvitation.Data && state.fetchSendInvitation.Data.Status) {
        return { InvitationStatus: state.fetchSendInvitation.Data}
    }
    else if(state.copyemailaspk && state.copyemailaspk.Data && state.copyemailaspk.Data.Status) {
        return { copyemailaspkstatus: state.copyemailaspk.Data}
    }
    else if(state.reducerupdatestudentinformation && state.reducerupdatestudentinformation.Data && state.reducerupdatestudentinformation.Data.Status) {
        return { reducerupdatestudentinformation: state.reducerupdatestudentinformation.Data}
    }
    else if(state.fetchUploadStudents && state.fetchUploadStudents.Data && state.fetchUploadStudents.Data.Status) {
        return { UploadStudents: state.fetchUploadStudents.Data}
    }
    else if (state.PreviewStudentPhoto && state.PreviewStudentPhoto.Data && state.PreviewStudentPhoto.Data.Status) {
        return { PreviewStudentPhotoStatus: state.PreviewStudentPhoto.Data }
    }
    else if (state.fetchProfileData && state.fetchProfileData.Data && state.fetchProfileData.Data.Status) {
        return { Profile: state.fetchProfileData.Data }
    }
    else if (state.fetchCohortsData && state.fetchCohortsData.Data && state.fetchCohortsData.Data.Status) {
        return { Cohorts: state.fetchCohortsData.Data }
    }  
    else {
        return {}

    }
}
export default connect(mapStateToProps)(withRouter(ManageStudentsComponent));

// export default MyProfileView;