import { PLAN_GET_FAILURE,PLAN_GET_LOADING,PLAN_GET_SUCCESS,PLAN_GET_COMPLETE ,
    BUY_FAILURE,BUY_LOADING,BUY_SUCCESS,BUY_COMPLETE
} from './types';
require('es6-promise').polyfill();
require('isomorphic-fetch');

//User Price
export function fetchUserPriceFailed(msg) {
    return {
        type: PLAN_GET_FAILURE,
        hasErrored: msg
    };
}
export function fetchUserPriceLoading(bool) {
    return {
        type: PLAN_GET_LOADING,
        isLoading: bool
    };
}
export function fetchUserPriceSuccess(items) {
    return {
        type: PLAN_GET_SUCCESS,
        Data:items
    };
}

export function fetchUserPriceComplete(status) {
    return {
        type: PLAN_GET_COMPLETE,
        isCompleted: status
    };
}

export function UserPriceFetchData(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchUserPriceLoading(true));
        fetch(url, {
            method: "GET",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchUserPriceLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchUserPriceSuccess(eventData));
                dispatch(fetchUserPriceComplete(true));
            })
            .catch((err) => {
                dispatch(fetchUserPriceFailed(err.message))
            }
            );
    };
  
}


//User buy plan
export function fetchbuyFailed(msg) {
    return {
        type: BUY_FAILURE,
        hasErrored: msg
    };
}
export function fetchbuyLoading(bool) {
    return {
        type: BUY_LOADING,
        isLoading: bool
    };
}
export function fetchbuySuccess(items) {
    return {
        type: BUY_SUCCESS,
        Data:items
    };
}

export function fetchbuyComplete(status) {
    return {
        type: BUY_COMPLETE,
        isCompleted: status
    };
}



export function buyFetchData(url, payload) {
    var Token = 'bearer ' +localStorage.getItem('Token');
        return (dispatch) => {
        dispatch(fetchbuyLoading(true));
        fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            credentials: "same-origin",
            headers: {
                'Authorization':  Token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(fetchbuyLoading(false));
                return response;
            })
            .then(response => {
                return response.json();
            })
            .then((eventData) => {
                dispatch(fetchbuySuccess(eventData));
                dispatch(fetchbuyComplete(true));
            })
            .catch((err) => {
                dispatch(fetchbuyFailed(err.message))
            }
            );
    };
  
}