import { GET_STUDENTS_FAILURE,GET_STUDENTS_LOADING,GET_STUDENTS_SUCCESS,GET_STUDENTS_COMPLETE,
    SEND_COPYEMAILASPK_FAILURE,SEND_COPYEMAILASPK_LOADING,SEND_COPYEMAILASPK_SUCCESS,SEND_COPYEMAILASPK_COMPLETE,
    FAILURE,LOADING,SUCCESS,COMPLETE,GET_COHORTS_FAILURE,GET_COHORTS_LOADING,GET_COHORTS_SUCCESS,GET_COHORTS_COMPLETE,
    SEND_INVITATION_SUCCESS,SEND_INVITATION_LOADING,SEND_INVITATION_FAILURE,SEND_INVITATION_COMPLETE,    
    UPLOAD_STUDENTS_FAILURE,UPLOAD_STUDENTS_LOADING,UPLOAD_STUDENTS_SUCCESS,UPLOAD_STUDENTS_COMPLETE,        
    UPDATE_STUDENTS_FAILURE,UPDATE_STUDENTS_LOADING,UPDATE_STUDENTS_SUCCESS,UPDATE_STUDENTS_COMPLETE,
    DELETE_STUDENTS_FAILURE,DELETE_STUDENTS_LOADING,DELETE_STUDENTS_SUCCESS,DELETE_STUDENTS_COMPLETE
} from './types';
const INITIAL_STATE  = {
    Data:{},
    loading: false,
    error: null,
    isCompleted:false};


//update student information
export const reducerupdatestudentinformation = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case UPDATE_STUDENTS_FAILURE:
            
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
    case UPDATE_STUDENTS_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case UPDATE_STUDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case UPDATE_STUDENTS_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}
//Copy email as pk
export const copyemailaspk = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case SEND_COPYEMAILASPK_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
    case SEND_COPYEMAILASPK_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case SEND_COPYEMAILASPK_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case SEND_COPYEMAILASPK_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}
//Upload students
export const fetchUploadStudents = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPLOAD_STUDENTS_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
    case UPLOAD_STUDENTS_LOADING:
            return {
                ...state,
                loading: true,
            isCompleted:false,
            error: null
    };
    case UPLOAD_STUDENTS_SUCCESS:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
    };
    case UPLOAD_STUDENTS_COMPLETE:
            return {
                ...state,
                loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
    };
    default:
        return state;

    }
}

//Get students data
export const fetchStudentsData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_STUDENTS_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case GET_STUDENTS_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case GET_STUDENTS_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case GET_STUDENTS_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}

//Get cohorts data
export const fetchCohortsData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COHORTS_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
        };
        case GET_COHORTS_LOADING:
                return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case GET_COHORTS_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case GET_COHORTS_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}

//Send invitation
export const fetchSendInvitation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_INVITATION_FAILURE:
            return {
                ...state,
                loading: false,
            isCompleted:false,
            error: action.hasErrored,
    };
case SEND_INVITATION_LOADING:
        return {
            ...state,
            loading: true,
        isCompleted:false,
        error: null
};
case SEND_INVITATION_SUCCESS:
        return {
            ...state,
            loading: false,
        isCompleted:false,
        error: null,
        Data: action.Data
};
case SEND_INVITATION_COMPLETE:
        return {
            ...state,
            loading: false,
        isCompleted:action.isCompleted,
        error: null,
        Data: {}
};
default:
    return state;

}
}

//Delete students data
export const DeleteStudentsData = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_STUDENTS_FAILURE:
        return {
            ...state,
            loading: false,
            isCompleted:false,
            error: action.hasErrored,
          }; 
          case DELETE_STUDENTS_LOADING:
          return {
            ...state,
            loading: true,
            isCompleted:false,
            error: null
          }; 
          case DELETE_STUDENTS_SUCCESS:
          return {
            ...state,
            loading: false,
            isCompleted:false,
            error: null,
            Data: action.Data
          };
          case DELETE_STUDENTS_COMPLETE:
          return {
            ...state,
            loading: false,
            isCompleted:action.isCompleted,
            error: null,
            Data: {}
          };
          default:
          return state;
        
    }
}



