export const GET_PROFILE_SUCCESS= 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const GET_PROFILE_COMPLETE = 'GET_PROFILE_COMPLETE';

export const UPDATE_PROFILE_SUCCESS= 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_COMPLETE = 'UPDATE_PROFILE_COMPLETE';

export const GET_TRANSACTION_SUCCESS= 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_LOADING = 'GET_TRANSACTION_LOADING';
export const GET_TRANSACTION_FAILURE = 'GET_TRANSACTION_FAILURE';
export const GET_TRANSACTION_COMPLETE = 'GET_TRANSACTION_COMPLETE';

export const GET_GENERATEADDRESS_SUCCESS= 'GET_GENERATEADDRESS_SUCCESS';
export const GET_GENERATEADDRESS_LOADING = 'GET_GENERATEADDRESS_LOADING';
export const GET_GENERATEADDRESS_FAILURE = 'GET_GENERATEADDRESS_FAILURE';
export const GET_GENERATEADDRESS_COMPLETE = 'GET_GENERATEADDRESS_COMPLETE';

export const UPLOAD_LOGO_SUCCESS= 'UPLOAD_LOGO_SUCCESS';
export const UPLOAD_LOGO_LOADING = 'UPLOAD_LOGO_LOADING';
export const UPLOAD_LOGO_FAILURE = 'UPLOAD_LOGO_FAILURE';
export const UPLOAD_LOGO_COMPLETE = 'UPLOAD_LOGO_COMPLETE';

export const REGISTER_TEAM_USER_SUCCESS= 'REGISTER_TEAM_USER_SUCCESS';
export const REGISTER_TEAM_USER_LOADING = 'REGISTER_TEAM_USER_LOADING';
export const REGISTER_TEAM_USER_FAILURE = 'REGISTER_TEAM_USER_FAILURE';
export const REGISTER_TEAM_USER_COMPLETE = 'REGISTER_TEAM_USER_COMPLETE';

export const SUCCESS = 'SUCCESS';
export const LOADING = 'LOADING';
export const FAILURE = 'FAILURE';
export const COMPLETE = 'COMPLETE';