import React from 'react';
import HeaderandFooter from "../../components/HeaderandFooter";
import ManageCertificateComponent from "../../components/ManageCertificateComponent"

const ManageCertificate = (prop) => {

    return (
        <HeaderandFooter >
            <ManageCertificateComponent />
        </HeaderandFooter>

    );
};

export default ManageCertificate;